import { Body, BodySizeEnum, Box, SecondaryButton } from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import { analytics } from '@/core/analytics/events'
import { useAuthService } from '@/core/auth/auth-service'
import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import {
  OnboardingApplicationStatus as OnboardingApplicationStatusEnum,
  useApplicationStatusScreenQuery,
} from '@/generated/graphql'
import useBusinessType from '@/hooks/useBusinessType'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { useIsBusinessProfileValid } from '@/routes/business-details/hooks'
import { Pathname } from '@/routes/constants'
import {
  useOwnerProfileListIsValid,
  useOwnerProfileValidation,
} from '@/routes/ownership/owner-profiles/utils/owner-validation-hooks'

interface LogoutButtonProps {
  testID: string
  onPress: () => void
}

const LogoutButton = ({ testID, onPress }: LogoutButtonProps) => {
  const t = useOnboardingTranslations()
  return (
    <SecondaryButton testID={testID} onPress={onPress}>
      {t('button.logout')}
    </SecondaryButton>
  )
}

const TimedOut = ({ email }: { email: string }) => {
  const { isMobileSize } = useMobileScreenSize()
  analytics.application.reviewStatusLoaderTimedOut()
  const t = useOnboardingTranslations()
  const authService = useAuthService()

  // TODO: add bolded email styling after FRED-286 in component library approved
  const subTitle =
    t('applicationStatus.onboardingPaused.emailPromise') + email + t('applicationStatus.onboardingPaused.timePromise')
  analytics.application.reviewStatusLoaderTimedOut()
  return (
    <div
      style={{
        flex: 1,
        minHeight: isMobileSize ? '92vh' : 0,
        paddingLeft: isMobileSize ? 16 : 0,
        paddingRight: isMobileSize ? 16 : 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        alignSelf: 'center',
        justifyContent: 'center',
        gap: isMobileSize ? 24 : 32,
      }}
    >
      <ResponsiveOnboardingHeader>{t('applicationStatus.onboardingPaused.title')}</ResponsiveOnboardingHeader>
      <Body size={BodySizeEnum.MD}>{subTitle}</Body>
      <Box
        sx={{
          flex: 1,
          px: isMobileSize ? '$4' : '$0',
          minWidth: isMobileSize ? '100%' : 0,
          position: isMobileSize ? 'absolute' : 'relative',
          bottom: '$0',
        }}
      >
        <LogoutButton testID="onboarding-paused-logout-button" onPress={authService.logout} />
      </Box>
    </div>
  )
}

export const OnboardingApplicationStatus = () => {
  const t = useOnboardingTranslations()
  const navigate = useNavigate()
  const [status, setStatus] = useState<OnboardingApplicationStatusEnum | undefined>(undefined)
  const [timerIsFinished, setTimerIsFinished] = useState(false)
  const businessId = useAppSelector((state) => state.application.businessId)
  const { isSoleProp } = useBusinessType()
  const { valid: areOwnerProfilesValid, checks: ownerProfileListChecks } = useOwnerProfileListIsValid()
  const { hasError: solePropOwnerHasError, checks: solepropOwnerProfileChecks } = useOwnerProfileValidation({
    ownerId: PRIMARY_OWNER_ID,
  })
  const { valid: isBusinessProfileValid, checks: businessProfileValidationChecks } = useIsBusinessProfileValid()

  const { data, startPolling, stopPolling } = useApplicationStatusScreenQuery({ variables: { businessId } })

  const loadingScreenTitle = t('accountUsage.loadingScreenTitle')

  useEffect(() => {
    // Set timer to 4 minutes. >98.5% of Middesk reports return in this time span
    const applicationStatusTimer = setTimeout(() => setTimerIsFinished(true), 4 * 60 * 1000)
    return () => clearTimeout(applicationStatusTimer)
  }, [])

  useEffect(() => {
    if (!data?.business?.onboarding?.application?.status) return
    setStatus(data.business.onboarding.application.status)
  }, [data])

  useEffect(() => {
    if (status === OnboardingApplicationStatusEnum.SUBMITTED || (data && !status)) {
      startPolling(2_000)
    } else {
      stopPolling()
    }
  }, [data, startPolling, status, stopPolling])

  useEffect(() => {
    if (!status) return
    switch (status) {
      case OnboardingApplicationStatusEnum.REVIEWING:
        navigate(Pathname.FINISH_UP, { replace: true })
        break
      case OnboardingApplicationStatusEnum.REJECTED:
        navigate(Pathname.REJECTED, { replace: true })
        break
      case OnboardingApplicationStatusEnum.APPROVED: {
        navigate(Pathname.FINISH_UP, { replace: true })
        break
      }
    }
  }, [status, navigate])

  // Navigate back to continue application if it hasn't been submitted (i.e., there is no application status)
  if (data && !data.business.onboarding?.application?.status && !isBusinessProfileValid) {
    analytics.application.submitPrevented({
      message: 'Application submit prevented due to invalid business profile',
      checks: businessProfileValidationChecks,
    })
    navigate(Pathname.BUSINESS_DETAILS_ABOUT_YOUR_BUSINESS, { replace: true })
    return
  }

  if (
    data &&
    !data.business.onboarding?.application?.status &&
    Boolean((isSoleProp && solePropOwnerHasError) || (!isSoleProp && !areOwnerProfilesValid))
  ) {
    analytics.application.submitPrevented({
      message: 'Application submit prevented due to invalid owner profile(s)',
      checks: isSoleProp ? solepropOwnerProfileChecks : ownerProfileListChecks,
    })
    navigate(Pathname.OWNERSHIP_DETAILS_OWNER_PROFILES, { replace: true })
    return
  }

  if (timerIsFinished) {
    analytics.application.reviewStatusLoaderTimedOut()
    return <TimedOut email={data?.me?.email ?? ''} />
  }

  return <LoadingScreen title={loadingScreenTitle} />
}
