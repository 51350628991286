import { isProduction } from './environment'

const cookieDomain = isProduction() ? 'northone.com' : 'northoneapps.com'

export const setAnonymousId = (): string => {
  const anonymousId = crypto.randomUUID()
  document.cookie = `n1_anonymous_id=${anonymousId};domain=${cookieDomain};path=/;samesite=lax;max-age=31536000;secure`
  return anonymousId
}

export const getN1AnonymousId = (): string => {
  const cookies = document.cookie.split('; ')
  const n1AnonymousIdCookie = cookies.find((cookie) => cookie.startsWith('n1_anonymous_id='))
  const n1AnonymousId = n1AnonymousIdCookie?.split('=')[1]

  if (n1AnonymousId) return n1AnonymousId
  console.log('N1 Anonymous ID not found. Setting cookie')
  const newAnonmyousId = setAnonymousId()
  return newAnonmyousId
}
