import { Body, BodySizeEnum, Box, SecondaryButton } from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import { useAuthService } from '@/core/auth/auth-service'
import { useEmailQuery } from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'

export const DocumentUploadThankYou = () => {
  const t = useOnboardingTranslations()
  const { isMobileSize } = useMobileScreenSize()

  const { data } = useEmailQuery()
  const email = data?.me?.email

  const authService = useAuthService()

  return (
    <BaseContentLayout
      primaryButton={
        <SecondaryButton
          fullWidth={isMobileSize}
          onPress={authService.logout}
          testID="document-upload-thank-you-logout-modal"
        >
          {t('button.logout')}
        </SecondaryButton>
      }
    >
      <Box
        sx={{
          gap: '$8',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <ResponsiveOnboardingHeader>{t('inAppDocumentCapture.thankYou.title')}</ResponsiveOnboardingHeader>
        {email && (
          <Body size={BodySizeEnum.MD} textAlign="center">
            {t('inAppDocumentCapture.thankYou.subTitle1')}
            <Body size={BodySizeEnum.MD} color={'$charcoal7'}>
              {email}
            </Body>
            {t('inAppDocumentCapture.thankYou.subTitle2')}
          </Body>
        )}
      </Box>
    </BaseContentLayout>
  )
}
