import { IAddressState } from '../../core/redux/application-redux/application-state'
import { Location, LocationStrictInput } from '../../generated/graphql'
import { config } from '../../utils/environment'

export const gqlLocationToAddressState = (gqlLocation?: Location | null): IAddressState => ({
  city: gqlLocation?.city ?? '',
  state: gqlLocation?.provinceState ?? '',
  streetAddress: gqlLocation?.streetAddressLine1 ?? '',
  suite: gqlLocation?.streetAddressLine2 ?? '',
  zipCode: gqlLocation?.postalCode ?? '',
  description: '',
  coordinates: null,
})

export const gqlLocationToLocationInput = ({
  city,
  streetAddressLine1,
  streetAddressLine2,
  provinceState,
  postalCode,
}: Location): LocationStrictInput => ({
  streetAddressLine1: streetAddressLine1 ?? '',
  streetAddressLine2: streetAddressLine2 ?? '',
  city: city ?? '',
  country: 'US',
  postalCode: postalCode ?? '',
  provinceState: provinceState ?? '',
})

export const navigateToWebBanking = (): void => {
  window.location.replace(config.webBankingUrl)
}
