import { DateTime } from 'luxon'
import { useState } from 'react'

import { CONTROL_PERSON_CANDIDATE_ID, PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { DATE_INPUT_FORMAT } from '@/utils'
import { errorMessages } from '@/utils/error-messages'
import { validate } from '@/utils/validate'

import { OwnerProfileInputIDs } from '../constants'
import { useOwnerHasDuplicateEmail } from './use-owner-has-duplicate-email'
import { useTotalOwnershipPercentageIsValid } from './use-ownership-percentage-validation'

type OwnerValidationChecks = {
  firstNameIsValid: boolean
  lastNameIsValid: boolean
  jobTitleIsValid: boolean
  birthdateIsValid: boolean
  emailIsValid: boolean
  phoneNumberIsValid: boolean
  ssnIsValid: boolean
  addressIsValid: boolean
  ownershipPercentageIsValid: boolean
  totalOwnershipPercentageIsValid?: boolean
}

interface OwnerProfileValidationArgs {
  ownerId: string
}

type FieldErrors = Partial<Record<keyof typeof OwnerProfileInputIDs, string | undefined>>

/**
 * Returns an element closest to the top of the document
 */
const getFirstElementById = (ids: string[]) => {
  const elements = ids.map((id) => document.getElementById(id))
  const highestElement = elements.sort((a, b) => (a && b ? a.offsetTop - b.offsetTop : 0))[0]
  return highestElement ?? null
}

/**
 * Scrolls to an input element, ensuring the label is visible
 */
const scrollToInput = (input: HTMLElement) => {
  // Scroll to the element that wraps the input and the label
  input?.parentElement?.parentElement?.parentElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

export const useOwnerProfileValidation = ({ ownerId }: OwnerProfileValidationArgs) => {
  const t = useOnboardingTranslations()
  const owner = useAppSelector((state) => state.application.owners[ownerId])
  if (!owner) throw new Error(`Owner Profile Validation error, owner with ID ${ownerId} not found`)
  const {
    email,
    firstName,
    lastName,
    birthdate,
    phoneNumber,
    ownershipPercentage,
    jobTitle,
    address: { streetAddress, suite, city, state, zipCode, description, coordinates },
  } = owner
  const ssn = useAppSelector((state) => state.unpersisted.ssns[ownerId])
  const [showErrors, setShowErrors] = useState(false)
  const hasDuplicateEmail = useOwnerHasDuplicateEmail(email ?? '', ownerId)
  const isPrimaryOwner = ownerId === PRIMARY_OWNER_ID
  const addressAutocompleteRequiredErrorText = t('inputs.addressAutocompleteRequiredText')

  const fieldErrors: FieldErrors = {
    firstName: errorMessages.firstName(firstName ?? ''),
    lastName: errorMessages.lastName(lastName ?? ''),
    birthdate: errorMessages.birthdate(DateTime.fromFormat(birthdate, DATE_INPUT_FORMAT)),
    phone: errorMessages.phoneNumber(phoneNumber ?? ''),
    ownershipPercentage: errorMessages.percentage(ownershipPercentage ?? ''),
    ssn: errorMessages.ssn(ssn ?? ''),
    streetAddress: errorMessages.streetAddress(streetAddress ?? ''),
    suite: errorMessages.suite(suite ?? ''),
    city: errorMessages.city(city ?? ''),
    state: errorMessages.state(state),
    zip: errorMessages.zipCode(zipCode ?? ''),
    jobTitle: errorMessages.jobTitle(jobTitle ?? ''),
    ...(!isPrimaryOwner && {
      email: errorMessages.email(email ?? '', hasDuplicateEmail),
    }),
    addressAutocomplete:
      !streetAddress || !city || !state || !zipCode ? addressAutocompleteRequiredErrorText : undefined,
  } as const

  const hasError = Object.values(fieldErrors).some((value) => Boolean(value))

  const checks: OwnerValidationChecks = {
    firstNameIsValid: validate.firstName(firstName),
    lastNameIsValid: validate.lastName(lastName),
    jobTitleIsValid: validate.jobTitle(jobTitle),
    birthdateIsValid: validate.birthdate(DateTime.fromFormat(birthdate, DATE_INPUT_FORMAT)),
    emailIsValid: validate.email(email),
    phoneNumberIsValid: validate.phoneNumber(phoneNumber),
    ssnIsValid: validate.ssn(ssn),
    addressIsValid: validate.address({ streetAddress, suite, city, state, zipCode, description, coordinates }),
    ownershipPercentageIsValid: validate.percentage(ownershipPercentage),
  }

  const scrollToFirstInputWithError = () => {
    const inputIDsWithErrors = Object.keys(fieldErrors).filter(
      (key) => fieldErrors[key as keyof typeof OwnerProfileInputIDs],
    )
    const highestInput = getFirstElementById(inputIDsWithErrors)
    highestInput && scrollToInput(highestInput)
  }

  return {
    fieldErrors: showErrors ? fieldErrors : {},
    hasError,
    showErrors,
    setShowErrors,
    checks,
    scrollToFirstInputWithError,
  } as const
}

export const useOwnerProfileListIsValid = () => {
  const ownerProfileMap = useAppSelector((state) => state.application.owners)
  const ssnMap = useAppSelector((state) => state.unpersisted.ssns)
  const ownerProfiles = Object.keys(ownerProfileMap).map((ownerId) => {
    const owner = ownerProfileMap[ownerId]
    if (!owner) throw new Error(`Owner with ID ${ownerId} not found.`)
    return { ownerId, ...owner }
  })
  const totalOwnershipPercentageIsValid = useTotalOwnershipPercentageIsValid()

  const ownerValidationChecks: OwnerValidationChecks = {
    totalOwnershipPercentageIsValid: totalOwnershipPercentageIsValid,
    firstNameIsValid: true,
    lastNameIsValid: true,
    jobTitleIsValid: true,
    birthdateIsValid: true,
    emailIsValid: true,
    phoneNumberIsValid: true,
    ssnIsValid: true,
    addressIsValid: true,
    ownershipPercentageIsValid: true,
  }

  const profilesAreValid = ownerProfiles
    .filter((owner) => owner.ownerId !== CONTROL_PERSON_CANDIDATE_ID)
    .every((owner) => {
      const checks: OwnerValidationChecks = {
        firstNameIsValid: validate.firstName(owner.firstName),
        lastNameIsValid: validate.lastName(owner.lastName),
        jobTitleIsValid: validate.jobTitle(owner.jobTitle),
        birthdateIsValid: validate.birthdate(DateTime.fromFormat(owner.birthdate, DATE_INPUT_FORMAT)),
        emailIsValid: validate.email(owner.email),
        phoneNumberIsValid: validate.phoneNumber(owner.phoneNumber),
        ssnIsValid: validate.ssn(ssnMap[owner.ownerId]),
        addressIsValid: validate.address(owner.address),
        ownershipPercentageIsValid: validate.percentage(owner.ownershipPercentage),
      }

      Object.entries(checks).forEach(([name, isValid]) => {
        if (!isValid) {
          ownerValidationChecks[name as keyof OwnerValidationChecks] = isValid
        }
      })

      return totalOwnershipPercentageIsValid && Object.values(checks).every((check) => check)
    })

  return {
    valid: profilesAreValid,
    checks: ownerValidationChecks,
  } as const
}
