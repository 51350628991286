import { createContext, useContext } from 'react'

export type UserContextData = {
  userId: string
  businessId: string
  email: string
  emailVerified: boolean
}
export const UserContext = createContext<UserContextData | null>(null)

export const useUserContext = (): UserContextData => {
  const user = useContext(UserContext)
  if (!user) {
    throw new Error('User context missing from user context provider')
  }
  return user
}
