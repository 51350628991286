import { useFeatureFlag } from '@northone-onboarding/frontend-new/src/core/feature-flags/use-feature-flag'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import { analytics } from '@/core/analytics/events'
import { displayIntercomWidget } from '@/core/intercom/intercom-settings'
import { unpersistedActions } from '@/core/redux/unpersisted-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import {
  OnboardingApplicationStatus,
  useOnboardingApplicationAddressesQuery,
  useOnboardingMailingAddressUpdateMutation,
} from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { Pathname } from '@/routes/constants'
import { format } from '@/utils'
import { gqlLocationToAddressState, gqlLocationToLocationInput } from '../utils'
import { MailingAddressLayout } from './mailing-address-layout'

export const MailingAddressScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const [hasError, setHasError] = useState(false)
  const [showLoading, setShouldShowLoading] = useState(false)
  const isManualReviewDocUploadEnabled = useFeatureFlag('joindot-custom-document-upload-ui-enabled')

  const loadingTitle = t('finishUp.debitCardAddress.submittingYourSelection')

  displayIntercomWidget(true)
  const mailingAddress = useAppSelector((state) => state.unpersisted.mailingAddress)
  const businessId = useAppSelector((state) => state.application.businessId)

  const { data, loading } = useOnboardingApplicationAddressesQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const applicationData = data.me?.ownerBusinesses[0]?.onboarding?.application
      dispatch(unpersistedActions.setAddress('HOME', gqlLocationToAddressState(applicationData?.homeAddress)))
      dispatch(unpersistedActions.setAddress('BUSINESS', gqlLocationToAddressState(applicationData?.businessAddress)))
    },
  })

  const businessAddress = data?.me?.ownerBusinesses[0]?.onboarding?.application?.businessAddress
  const homeAddress = data?.me?.ownerBusinesses[0]?.onboarding?.application?.homeAddress
  const applicationStatus = data?.me?.ownerBusinesses[0]?.onboarding?.application?.status

  const isButtonDisabled = !businessAddress || !homeAddress || mailingAddress === undefined || !businessId

  const [updateMailingAddress, { loading: updateLoading }] = useOnboardingMailingAddressUpdateMutation({
    onCompleted: () => {
      // Route MR applicants to manual review flow, otherwise route to welcome screen
      if (!applicationStatus) return navigate(Pathname.APPLICATION_STATUS, { replace: true })
      // Navigate to the document upload form using window to ensure that Intercom recognizes the updated URL
      if (applicationStatus === OnboardingApplicationStatus.REVIEWING) {
        if (isManualReviewDocUploadEnabled) return navigate(Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT)
        window.location.href = `${window.location.origin}${Pathname.DOCUMENT_UPLOAD_FORM}`
        return navigate(Pathname.DOCUMENT_UPLOAD_FORM)
      }
      if (applicationStatus === OnboardingApplicationStatus.REJECTED)
        return navigate(Pathname.REJECTED, { replace: true })
      navigate(Pathname.FINISH_UP_WELCOME_SCREEN)
    },
    onError: () => setHasError(true),
  })

  const formattedBusinessAddress = format.address.gql(businessAddress) ?? ''
  const formattedHomeAddress = format.address.gql(homeAddress) ?? ''

  const onBackPressed = () => navigate(Pathname.FINISH_UP)

  const onSelectedAddressChanged = (addressType: string) => {
    if (addressType === 'BUSINESS') {
      analytics.cardShipping.mailingAddress({ addressType: 'business' })
      dispatch(unpersistedActions.setMailingAddress('BUSINESS'))
    }
    if (addressType === 'HOME') {
      analytics.cardShipping.mailingAddress({ addressType: 'personal' })
      dispatch(unpersistedActions.setMailingAddress('HOME'))
    }
  }

  const updateMailingAddressAction = () => {
    if (!businessId || !data?.me?.ownerBusinesses[0]?.onboarding?.application) return
    const { businessAddress, homeAddress } = data.me.ownerBusinesses[0].onboarding.application
    if (homeAddress && businessAddress) {
      setShouldShowLoading(true)
      updateMailingAddress({
        variables: {
          data: {
            businessId,
            address:
              mailingAddress === 'HOME' && homeAddress
                ? gqlLocationToLocationInput(homeAddress)
                : gqlLocationToLocationInput(businessAddress),
          },
        },
      })
    }
  }

  if (showLoading || isManualReviewDocUploadEnabled === undefined) return <LoadingScreen title={loadingTitle} />

  return (
    <MailingAddressLayout
      hasError={hasError}
      loading={loading}
      updateLoading={updateLoading}
      updateAddressAction={updateMailingAddressAction}
      isButtonDisabled={isButtonDisabled}
      homeAddress={formattedHomeAddress}
      businessAddress={formattedBusinessAddress}
      selectedAddress={mailingAddress}
      onSelectedAddressChanged={onSelectedAddressChanged}
      onBackPressed={onBackPressed}
    />
  )
}
