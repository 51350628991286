import { AlertTypeEnum, ArrowIcon, Body, Box, FlatButton, InlineAlert, SecondaryButton } from '@northone/ui-components'
import { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { analytics } from '@/core/analytics/events'
import { useAuthService } from '@/core/auth/auth-service'
import { useUserContext } from '@/core/auth/user-context'
import { Intercom } from '@/core/intercom'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import { useEmailVerifiedQuery } from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { config } from '@/utils/environment'

import { Pathname } from '../constants'

const ErrorBanner = () => {
  const t = useOnboardingTranslations()
  return <InlineAlert type={AlertTypeEnum.Error}>{t('emailVerify.error')}</InlineAlert>
}

interface VerifyDetailsProps {
  email: string
}

const VerifyDetails = ({ email }: VerifyDetailsProps) => {
  const t = useOnboardingTranslations()

  return (
    <Box>
      <Body>{t('emailVerify.details')}</Body>
      <Body>{email}</Body>
    </Box>
  )
}

interface ResendButtonProps {
  email: string
}
const ResendButton = ({ email }: ResendButtonProps) => {
  const [hasSent, setHasSent] = useState(false)
  const t = useOnboardingTranslations()
  const recaptcha = useRef<ReCAPTCHA>(null)
  const authService = useAuthService()
  const { resendVerificationEmail, resendVerificationEmailError, resendVerificationEmailLoading } =
    authService.useResendVerificationEmail({ email })

  const reCaptchaToken = recaptcha.current?.getValue()
  const isButtonDisabled = !reCaptchaToken

  const onPress = () => {
    if (!reCaptchaToken) return
    analytics.emailVerify.resend()
    resendVerificationEmail({ reCaptchaToken })
      .then(() => {
        setHasSent(true)
      })
      .catch(() => {
        recaptcha.current?.reset()
      })
  }

  return (
    <>
      {resendVerificationEmailError && <ErrorBanner />}
      {hasSent ? (
        <InlineAlert type={AlertTypeEnum.Information}>{t('emailVerify.sent')}</InlineAlert>
      ) : (
        <>
          <ReCAPTCHA ref={recaptcha} sitekey={config.reCAPTCHASiteKey} />
          <SecondaryButton
            testID={'resend-email-verification-button'}
            fullWidth={true}
            onPress={onPress}
            loading={resendVerificationEmailLoading}
            disabled={isButtonDisabled}
          >
            {t('emailVerify.resend')}
          </SecondaryButton>
        </>
      )}
    </>
  )
}

const customerCareLayoutStyles = {
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',
  justifyContent: 'center',
}

const ContactCustomerCare = () => {
  const t = useOnboardingTranslations()

  const onPress = () => {
    Intercom.show()
    analytics.emailVerify.support()
  }

  return (
    <Box sx={customerCareLayoutStyles}>
      <Body>{t('emailVerify.csHelp')}</Body>
      <FlatButton fullWidth={false} testID={'contact-customer-care-button'} icon={ArrowIcon} onPress={onPress}>
        {t('emailVerify.cs')}
      </FlatButton>
    </Box>
  )
}

export const EmailVerify = () => {
  const t = useOnboardingTranslations()
  // Throw an error if owner or owner email are undefined
  const email = useAppSelector((state) => {
    const owner = state.application.owners[PRIMARY_OWNER_ID]
    if (!owner) {
      throw new Error(`Owner with ID ${PRIMARY_OWNER_ID} not found`)
    }
    if (!owner.email) {
      throw new Error('Email is undefined for the primary owner')
    }
    return owner.email
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useUserContext()
  const authService = useAuthService()

  const { stopPolling, startPolling } = useEmailVerifiedQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 2_000,
    notifyOnNetworkStatusChange: true,
    skip: user?.emailVerified,
    onCompleted: (data) => {
      if (!data.me?.emailVerified) {
        void authService.getTokenSilently({ skipCache: true })
        return
      }

      // Email verified true is handled in the useEffect below.
      // This is to ensure that the user is redirected to the welcome getting started page
      // when the user context already has emailVerified as true and then this query is skipped.
    },
    onError: (error) => {
      // reduce polling if the error is a 429 Too Many Requests
      if (error.graphQLErrors.some((e) => e?.message.includes('429'))) {
        startPolling(10_000)
      }
    },
  })

  useEffect(() => {
    if (user?.emailVerified) {
      // This dispatch is currently required for the useApplyPromoCode hook
      // Now that the email verified screen redirects to JoinDot,
      // this means there can be two JoinDot browser sessions open when the email verified link is clicked.
      // This dispatch ensures that the promo code can be applied
      // without refresh when two browser tabs are open
      dispatch(applicationActions.setEmailVerified(true))
      stopPolling()
      navigate(Pathname.WELCOME_GETTING_STARTED)
    }
  }, [user, navigate, dispatch, stopPolling])

  return (
    <BaseContentLayout subHeadingText={<VerifyDetails email={email} />} headingText={t('emailVerify.title')}>
      <ResendButton email={email} />
      <ContactCustomerCare />
    </BaseContentLayout>
  )
}
