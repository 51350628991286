import { analytics as segmentAnalytics } from '@northone/segment-js'
import type { AnalyticsJS } from '@northone/segment-js/dist/src/types'
import { LDFlagValue } from 'launchdarkly-react-client-sdk'

import { getN1AnonymousId } from '@/utils/anonymous-id'

import pjson from '../../../../../package.json'
import { FeatureFlagKey, FeatureFlags } from '../feature-flags/use-feature-flag'
import { IAddressState } from '../redux/application-redux/application-state'
import { addSourceToAnalytics } from './middleware'

addSourceToAnalytics({ key: 'versionNumber', value: pjson.version }, 'context')
addSourceToAnalytics({ key: 'n1AnonymousId', value: getN1AnonymousId() }, 'properties')

// analytics track() factory to create type safe analytics calls
type TrackProps = Record<string, unknown> | undefined
const t =
  <P extends TrackProps = undefined>(name: string) =>
  (...props: P extends undefined ? [] : [P]) => {
    const properties = props.length ? props[0] : undefined
    return segmentAnalytics().track(name, properties)
  }

type ProfileProps = {
  multipleOwners: boolean
  firstName: string
  lastName: string
  title: string
  dateOfBirth: string
  address: IAddressState
  percentage: string
}

export const analytics = {
  page: (): ReturnType<AnalyticsJS['page']> => segmentAnalytics().page(),
  featureFlagEvaluated: t<{ featureFlag: FeatureFlagKey; result: FeatureFlags[FeatureFlagKey] }>(
    'Feature Flag Evaluated',
  ),
  session: {
    navigatePage: t<{ url: string; deviceType: 'mobile' | 'desktop' }>('Join Pageview'),
    start: t<{ url: string; deviceType: 'mobile' | 'desktop' }>('Join Session Started'),
    end: t<{ deviceType: 'mobile' | 'desktop' }>('Join Session Ended'),
    onboardingStatusRestored: t<Record<string, unknown>>('Join Onboarding Status Restored'),
  },
  dropdown: {
    expand: t('Join PersistentDropdown ExpandClick'),
    collapse: t('Join PersistentDropdown CollapseClick'),
    faq: t('Join PersistentDropdown FAQClick'),
    support: t('Join PersistentDropdown SupportClick'),
    logout: t('Join PersistentDropdown LogoutClick'),
    logoutContinue: t('Join LogoutPrompt ContinueClick'),
    logoutCancel: t('Join LogoutPrompt CancelClick'),
  },
  funnel: {
    phone: t<{ phoneNumber: string }>('Join Funnel Phone Submit'),
    bizType: t<{ businessType: string }>('Join Funnel BusinessType Submit'),
    bizName: t<{ businessName: string }>('Join Funnel BusinessName Submit'),
    bizNameRegistered: t<{ hasRegisteredBusinessName: boolean }>('Join Funnel BusinessNameRegistered Submit'),
    freelancePromptContinue: t<{ businessName: string }>('Join Funnel FreelancerPrompt ContinueClick'),
    freelancePromptDismiss: t<{ businessName: string }>('Join Funnel FreelancerPrompt DismissClick'),
    revenue: t<{ revenue: string }>('Join Funnel BusinessRevenue Submit'),
    selectIndustry: t<{ industry: string; category: string; naicsCode: number }>('Join Funnel BusinessIndustry Submit'),
    prohibitedIndustry: t('Join Funnel ProhibitedIndustry Submit'),
    ownerCitizenship: t<{ USCitizen: boolean }>('Join Funnel OwnerCitizenship Submit'),
    termsConditions: t('Join Funnel Terms&Conditions Submit'),
    applicationLoadingStart: t('Join Funnel SubmitLoader StartSequence'),
    applicationLoadingFinish: t('Join Funnel SubmitLoader CompleteSequence'),
  },
  application: {
    beginButton: t('Join Form LetsGo Click'),
    continueButton: t('Join Form LetsContinue Click'),
    sectionContinueButton: t<{ section: string }>('Join Form FormSection ContinueClick'),
    sidebarSection: t<{ section: string }>('Join Form SideMenu ClickToScroll'),
    incompleteSection: t<{ section: string }>('Join Form IncompleteSection ClickToScroll'),
    errorsRevealed: t<{ errors: string[]; count: number }>('Join Form ErrorThrown'),
    ownerProfileSave: t<Partial<ProfileProps> & { isPrimaryOwner: boolean }>('Join Form OwnerProfile SaveClick'),
    coOwnerAdd: t('Join Form CoOwnerProfile AddClick'),
    coOwnerSave: t('Join Form CoOwnerProfile SaveClick'),
    coOwnerDelete: t('Join Form CoOwnerProfile DeleteClick'),
    coOwnerEdit: t('Join Form CoOwnerProfile EditClick'),
    percentagePrompt: t('Join Form PercentagePrompt ContinueClick'),
    controlPersonAdd: t('Join Form ControlPerson AddClick'),
    controlPersonEdit: t('Join Form ControlPerson EditClick'),
    controlPersonSave: t('Join Form ControlPerson SaveClick'),
    controlPersonDelete: t('Join Form ControlPerson DeleteClick'),
    controlPersonSelect: t('Join Form ControlPerson Select'),
    completeSection: t<{ section: string }>('Join Form SectionComplete'),
    submit: t<{ n1AnonymousId: string }>('Join Form Application Submit'),
    submitError: t<{ error: string }>('Join Form Application Submit Error'),
    submitErrorSubmit: t('Join Form SubmitError SubmitClick'),
    submitErrorBack: t('Join Form SubmitError BackClick'),
    submitPrevented: t<{ message: string; checks?: Record<string, boolean | string> }>(
      'Join Application Submit Prevented',
    ),
    submitLoadStart: t('Join Checkout SubmitLoader StartSequence'),
    submitLoadComplete: t('Join Checkout SubmitLoader CompleteSequence'),
    reviewStatusLoaderTimedOut: t('Join Application Review Status Loader Timed Out'),
    fillBusinessName: t('Join Form Fill Business Name'),
    fillDBAName: t<{ dba: string }>('Join Form Fill DBA Name'),
    fillBusinessDescription: t<{ description: string; length: number }>('Join Form Fill Business Description'),
    fillBusinessPhoneNumber: t<{ phoneNumber: string }>('Join Form Fill Phone Number'),
    fillSameAddress: t<{ sameAddress: boolean }>('Join Form Fill Same Address'),
    fillBusinessAddress: t<{ address: IAddressState }>('Join Form Fill Business Address'),
    fillYearsAtAddress: t<{ years: string }>('Join Form Fill Years At Address'),
    fillBusinessYearsInOperation: t<{ years: number }>('Join Form Fill Years In Operation'),
    fillCorporationType: t('Join Form Select Corporation Type'),
    fillWebsite: t<{ url: string }>('Join Business Details Fill Website'),
    fillPrimarySocialMediaAccount: t<{ url: string }>('Join Business Details Fill Primary Social Media Account'),
    fillRevenueSources: t<{
      Amazon: boolean
      CashApp: boolean
      Etsy: boolean
      Square: boolean
      Shopify: boolean
      PayPal: boolean
      Stripe: boolean
      Other?: string
    }>('Join Business Details Fill Revenue Sources'),
    fillTwitter: t('Join Form Fill Twitter'),
    fillInstagram: t('Join Form Fill Instagram'),
    fillLinkedIn: t('Join Form Fill LinkedIn'),
    fillOtherWebsite: t('Join Form Fill Other Website'),
    fillNumberOfEmployees: t<{ employees: string }>('Join Form Fill Number of Employees'),
    fillEIN: t<{ n1AnonymousId: string }>('Join Form Fill EIN'),
    fillOwnerSSN: t('Join Form Fill Owner SSN'),
    fillOwnerSelection: t('Join Form Owner Selection'),
    fillCoOwnerFirstName: t('Join Form Fill Co-Owner First Name'),
    fillCoOwnerLastName: t('Join Form Fill Co-Owner Last Name'),
    fillCoOwnerDateOfBirth: t('Join Form Fill Co-Owner Date of Birth'),
    fillCoOwnerSSN: t('Join Form Fill Co-Owner SSN'),
    fillCoOwnerHomeAddress: t('Join Form Fill Co-Owner Home Address'),
    fillCoOwnerJobTitle: t('Join Form Fill Co-Owner Job Title'),
    updateBusinessType: t('Join Update Business Type'),
    editBusinessName: t('Join Edit Business Name'),
    editTradeName: t('Join Edit Trade Name'),
  },
  status: {
    approvedStart: t('Join StatusPage Approved StartClick'),
    pendingLogout: t('Join StatusPage Pending LogoutClick'),
    rejectedLogout: t('Join StatusPage Rejected LogoutClick'),
  },
  cardShipping: {
    mailingAddress: t<{ addressType: 'business' | 'personal' }>('Join Checkout MailingAddress Submit'),
  },
  accountUsage: {
    start: t('Join AccountSetup LetsGo Click'),
    debitCardAmount: t<{ value: number }>('Join AccountSetup DebitSpend Submit'),
    averageDepositAmount: t<{ value: number }>('Join AccountSetup DepositAverage Submit'),
    numberOfCheckDeposits: t<{ value: number }>('Join AccountSetup InboundCheckCount Submit'),
    averageCheckDepositAmount: t<{ value: number }>('Join AccountSetup InboundCheckAmount Submit'),
    numberOfACHs: t<{ value: number }>('Join AccountSetup OutboundACHCount Submit'),
    averageACHAmount: t<{ value: number }>('Join AccountSetup OutboundACHAmount Submit'),
    numberOfWires: t<{ value: number }>('Join AccountSetup OutboundWireCount Submit'),
    averageWireAmount: t<{ value: number }>('Join AccountSetup OutboundWireAmount Submit'),
    numberOfCheckPayments: t<{ value: number }>('Join AccountSetup OutboundCheckCount Submit'),
    averageCheckPaymentAmount: t<{ value: number }>('Join AccountSetup OutboundCheckAmount Submit'),
    hasRevenueOutsideUS: t<{ value: boolean }>('Join AccountSetup USRevenue Submit'),
    percentRevenueOutsideUS: t<{ value: number }>('Join AccountSetup USRevenuePercent Submit'),
    submit: t('Join GetStarted Submit'),
    submitError: t('Join GetStarted SubmitError Submit'),
  },
  emailVerify: {
    resend: t('Join AccountSetup EmailVerify ResendClick'),
    support: t('Join AccountSetup EmailVerify SupportClick'),
  },
  inAppDocumentUpload: {
    uploadScreenLoaded: t<{ businessId: string }>('Join InAppDocumentUpload UploadDocumentsScreen PageLoaded'),
    uploadScreenContinue: t<{ businessId: string }>('Join InAppDocumentUpload UploadDocumentsScreen ContinueClick'),
    uploadScreenLearnMore: t<{ businessId: string }>('Join InAppDocumentUpload UploadDocumentsScreen LearnMoreClick'),
    intercomScreenLoaded: t<{ businessId: string }>('Join InAppDocumentUpload IntercomScreen PageLoaded'),
    intercomScreenDocumentListClick: t<{ businessId: string }>(
      'Join InAppDocumentUpload IntercomScreen DocumentListClick',
    ),
    intercomScreenDoneUploadingClick: t<{ businessId: string }>(
      'Join InAppDocumentUpload IntercomScreen DoneUploadingClick',
    ),
    thankYouScreenLoaded: t<{ businessId: string }>('Join InAppDocumentUpload ThankYouScreen PageLoaded'),
    thankYouScreenReviewDocumentsClick: t<{ businessId: string }>(
      'Join InAppDocumentUpload ThankYouScreen ReviewDocumentsClick',
    ),
    thankYouScreenCustomerCareClick: t<{ businessId: string }>(
      'Join InAppDocumentUpload ThankYouScreen CustomerCareClick',
    ),
    thankYouScreenBackButtonClick: t<{ businessId: string }>('Join InAppDocumentUpload ThankYouScreen BackButtonClick'),
    thankYouScreenLogOutClick: t<{ businessId: string }>('Join InAppDocumentUpload ThankYouScreen LogOutClick'),
    uploadDocumentsScreenPageError: t<{ businessId: string; screen: string }>(
      'Join InAppDocumentUpload UploadDocumentsScreen PageError',
    ),
    intercomScreenPageError: t<{ businessId: string }>('Join InAppDocumentUpload IntercomScreen PageError'),
    documentUploadSuccess: t<{ documentType: string; fileSize: number }>('Join Document Upload Success'),
    documentUploadFailed: t<{ documentType: string; reason: string }>('Join Document Upload Failed'),
    documentUploadSkipped: t<{ documentType: string }>('Join Document Upload Skipped'),
  },
  partnershipsAPI: {
    papiReferralDataHydrated: t<{ partnerId?: string; partnerName?: string; otp?: string }>(
      'Partnership Referral Data Successfully Hydrated',
    ),
    papiReferralDataError: t<{ partnerId?: string; partnerName?: string; otp?: string; errorMessage?: string }>(
      'Error Retrieving Partnership Data',
    ),
  },
  eligibilityCheck: {
    eligibilityCheckError: t('Join Eligibility Check Error'),
  },
  featureFlags: {
    identify: t<{ userId: string; businessId: string }>('LaunchDarkly Client User Context Identified'),
    flagEvaluated: t<{ featureFlag: string; result: LDFlagValue }>('Feature Flag Evaluated'),
  },
}
