export enum Pathname {
  ROOT = '/',
  OAUTH = '/oauth',

  WELCOME_GETTING_STARTED = '/welcome',
  WELCOME_EMAIL_VERIFY = '/welcome/verify-email',
  WELCOME_BUSINESS_TYPE = '/welcome/business-type',
  WELCOME_QUALIFIERS = '/welcome/qualifiers',
  WELCOME_TERMS_AND_CONDITIONS = '/welcome/terms-and-conditions',

  BUSINESS_DETAILS = '/business-details',
  BUSINESS_DETAILS_ABOUT_YOUR_BUSINESS = '/business-details/details',
  BUSINESS_DETAILS_DESCRIPTION_AND_INDUSTRY = '/business-details/description-and-industry',
  BUSINESS_DETAILS_CONTACT_INFO = '/business-details/contact-information',
  BUSINESS_DETAILS_EXPECTED_REVENUE = '/business-details/expected-revenue',
  BUSINESS_DETAILS_ONLINE_PRESENCE = '/business-details/online-presence',

  OWNERSHIP_DETAILS = '/ownership-details',
  OWNERSHIP_DETAILS_OWNER_PROFILES = '/ownership-details/owner-profiles',
  OWNERSHIP_DETAILS_CONTROLLER = '/ownership-details/controller',

  OWNERSHIP_DETAILS_PRIMARY_OWNER = '/ownership-details/primary-owner',
  OWNERSHIP_DETAILS_REVIEW_OWNERS = '/ownership-details/review',
  OWNERSHIP_DETAILS_ADD_OWNER = '/ownership-details/add-owner/:ownerId',
  OWNERSHIP_DETAILS_EDIT_OWNER = '/ownership-details/edit-owner/:ownerId',

  APPLICATION_SUBMIT = '/submit',

  DOCUMENT_UPLOAD = '/document-upload',
  DOCUMENT_UPLOAD_FORM = '/document-upload/form',
  DOCUMENT_UPLOAD_THANK_YOU = '/document-upload/form/thank-you',

  JOIN_TEAM = '/join-team',
  JOIN_TEAM_SHIPPING_ADDRESS = '/join-team/shipping-address',
  JOIN_TEAM_TERMS_AND_CONDITIONS = '/join-team/terms-and-conditions',
  JOIN_TEAM_CELEBRATION = '/join-team/welcome-to-north-one',

  USAGE_QUESTIONS = '/account-usage',
  USAGE_QUESTION_DEBIT_CARD_AMOUNT = '/account-usage/debit-card-spending',
  USAGE_QUESTION_MONTHLY_DEPOSIT_AMOUNT = '/account-usage/monthly-deposit-amount',
  USAGE_QUESTION_CHECK_DEPOSITS = '/account-usage/monthly-check-deposits',
  USAGE_QUESTION_ACH_PAYMENTS = '/account-usage/monthly-ach-payments',
  USAGE_QUESTION_WIRE_PAYMENTS = '/account-usage/monthly-wire-payments',

  APPLICATION_STATUS = '/application-status',

  REJECTED = '/check-email-for-update',

  FINISH_UP = '/finish-up',
  DEBIT_CARD_DELIVERY_ADDRESS = '/finish-up/debit-card-delivery-address',
  FINISH_UP_WELCOME_SCREEN = '/finish-up/welcome-to-north-one',

  FINISH_UP_FILE_UPLOAD = '/finish-up/file-upload',
  FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT = '/finish-up/file-upload/proof-of-identity-front',
  FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_BACK = '/finish-up/file-upload/proof-of-identity-back',
  FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP = '/finish-up/file-upload/proof-of-ownership',
  FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE = '/finish-up/file-upload/business-license',
  FINISH_UP_FILE_UPLOAD_THANK_YOU = '/finish-up/file-upload/thank-you',
}
