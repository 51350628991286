import { AlertInformationIcon, Body, Box, SizeEnum } from '@northone/ui-components'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'

export interface whyWeCollectThisProps {
  triggerString?: string
}

export default function WhyDoWeCollectThisInfo({ triggerString }: whyWeCollectThisProps) {
  const t = useOnboardingTranslations()
  const tooltipTrigger = t('tooltip.whyDoWeCollectThisInformation')
  return (
    <Box sx={{ flexDirection: 'row', alignItems: 'center', gap: 2, zIndex: 1 }}>
      <Body>{triggerString || tooltipTrigger}</Body>
      <AlertInformationIcon color={'$charcoal5'} size={SizeEnum.SM} />
    </Box>
  )
}
