export const OwnerProfileInputIDs = {
  firstName: 'firstName',
  lastName: 'lastName',
  jobTitle: 'jobTitle',
  birthdate: 'birthdate',
  email: 'email',
  phone: 'phone',
  ssn: 'ssn',
  addressAutocomplete: 'addressAutocomplete',
  streetAddress: 'streetAddress',
  suite: 'suite',
  city: 'city',
  state: 'state',
  zip: 'zip',
  ownershipPercentage: 'ownershipPercentage',
} as const
