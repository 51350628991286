import { AlertTypeEnum, InlineAlertProps } from '@northone/ui-components'
import { IOwner } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { validate } from '@/utils/validate'

type ErrorState = 'INCOMPLETE_PROFILES' | 'OWNERSHIP_PERCENTAGE_EXCEEDS_100' | 'MISSING_SSN' | 'DUPLICATE_EMAILS'

type OwnerId = string
export const useOwnershipReviewErrors = (): {
  alertProps?: InlineAlertProps
  ownerActionItemErrorTexts: Record<OwnerId, string>
} => {
  const t = useOnboardingTranslations()
  const getOwnerActionItemErrorText = useGetOwnerActionItemErrorText()
  const ownersState = useAppSelector((state) => state.application.owners)
  const owners = Object.values(ownersState)
  const ownerIds = Object.keys(ownersState)

  const totalOwnershipPercentage = owners.reduce((acc, owner) => acc + Number(owner.ownershipPercentage), 0)

  const ssnMap = useAppSelector((state) => state.unpersisted.ssns)

  const ownersHaveIncompleteProfiles = owners.some((owner) => isOwnerProfileIncomplete(owner))
  const ownersHaveMissingSSN = ownerIds.some((ownerId) => !ssnMap[ownerId])
  const ownersHaveDuplicateEmails = ownerIds.some((ownerId) => !validate.emailUnique(ownerId, ownersState))
  const ownershipPercentageExceeds100 = totalOwnershipPercentage > 100

  const errorState: ErrorState | undefined = ownersHaveIncompleteProfiles
    ? 'INCOMPLETE_PROFILES'
    : ownersHaveMissingSSN
    ? 'MISSING_SSN'
    : ownersHaveDuplicateEmails
    ? 'DUPLICATE_EMAILS'
    : ownershipPercentageExceeds100
    ? 'OWNERSHIP_PERCENTAGE_EXCEEDS_100'
    : undefined

  const alertTextMap: Record<ErrorState, string> = {
    INCOMPLETE_PROFILES: t('ownership.review.errors.incompleteProfiles'),
    DUPLICATE_EMAILS: t('ownership.review.errors.duplicateEmail'),
    MISSING_SSN: t('ownership.review.errors.missingSSN'),
    OWNERSHIP_PERCENTAGE_EXCEEDS_100: t('ownership.review.errors.ownershipPercentageExceeds100'),
  }

  const alertProps = errorState
    ? {
        children: alertTextMap[errorState],
        type: AlertTypeEnum.Error,
        showIcon: true,
      }
    : undefined

  const ownerActionItemErrorTexts = Object.entries(ownersState).reduce(
    (acc, [ownerId, owner]) => ({
      ...acc,
      [ownerId]: getOwnerActionItemErrorText({ ownerId, owner, errorState }),
    }),
    {},
  )
  return {
    alertProps,
    ownerActionItemErrorTexts,
  }
}

const isOwnerProfileIncomplete = (owner: IOwner) => {
  // Missing SSN is handled separately
  const {
    firstName,
    lastName,
    birthdate,
    email,
    jobTitle,
    ownershipPercentage,
    phoneNumber,
    address: { city, streetAddress, state, zipCode },
  } = owner
  const hasEmptyField =
    [firstName, lastName, birthdate, email, jobTitle, phoneNumber, city, streetAddress, state, zipCode].some(
      (field) => !field,
    ) || ownershipPercentage === '0'
  return hasEmptyField
}

const useGetOwnerActionItemErrorText = () => {
  const t = useOnboardingTranslations()
  const ssnMap = useAppSelector((state) => state.unpersisted.ssns)
  const ownersState = useAppSelector((state) => state.application.owners)

  return ({ ownerId, owner, errorState }: { ownerId: string; owner: IOwner; errorState?: ErrorState }) => {
    switch (errorState) {
      case 'INCOMPLETE_PROFILES': {
        return isOwnerProfileIncomplete(owner)
          ? t('ownership.review.errors.ownerError.incompleteOwnerProfile')
          : undefined
      }
      case 'DUPLICATE_EMAILS': {
        return !validate.emailUnique(ownerId, ownersState) ? owner.email : undefined
      }
      case 'MISSING_SSN': {
        return !ssnMap[ownerId] ? t('ownership.review.errors.ownerError.reEnterSSN') : undefined
      }
      case 'OWNERSHIP_PERCENTAGE_EXCEEDS_100': {
        return t('ownership.review.errors.ownerError.ownershipPercentage', {
          ownershipPercentage: owner.ownershipPercentage,
        })
      }
      default: {
        return
      }
    }
  }
}
