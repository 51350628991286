import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import LoadingScreen from '@/components/LoadingScreen'
import { analytics } from '@/core/analytics/events'
import { useFeatureFlag } from '@/core/feature-flags/use-feature-flag'
import { unpersistedActions } from '@/core/redux/unpersisted-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import {
  OnboardingApplicationStatus,
  useOnboardingApplicationAddressesQuery,
  useOnboardingMailingAddressUpdateMutation,
} from '@/generated/graphql'
import { format } from '@/utils'

import { Pathname } from '../constants'
import { useInitialManualReviewScreen } from '../document-upload/use-file-upload-navigation'
import FinishUpFundingLandingScreen from './FinishUpFundingLandingScreen'
import FinishUpLandingMRScreen from './FinishUpLandingMRScreen'
import FinishUpLandingScreen from './FinishUpLandingScreen'
import { gqlLocationToLocationInput } from './utils'

export default function FinishUpLanding() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nextPathMR = useInitialManualReviewScreen()
  const isFundingUrgencyEnabled = useFeatureFlag('joindot-funding-urgency-enabled')

  const businessId = useAppSelector((state) => state.application.businessId)

  const { data, loading } = useOnboardingApplicationAddressesQuery({ fetchPolicy: 'network-only' })

  const homeAddress = data?.me?.ownerBusinesses[0]?.onboarding?.application?.homeAddress
  const businessAddress = data?.me?.ownerBusinesses[0]?.onboarding?.application?.businessAddress
  const formattedBusinessAddress = format.address.gql(businessAddress)
  const formattedHomeAddress = format.address.gql(homeAddress)
  const applicationStatus = data?.me?.ownerBusinesses[0]?.onboarding?.application?.status

  const addressesAreSame = Boolean(
    formattedHomeAddress && formattedBusinessAddress && formattedHomeAddress === formattedBusinessAddress,
  )

  const navigateToNextPage = () => {
    switch (applicationStatus) {
      case null:
        navigate(Pathname.APPLICATION_STATUS, { replace: true })
        break
      case OnboardingApplicationStatus.REVIEWING:
        navigate(nextPathMR)
        break
      case OnboardingApplicationStatus.REJECTED:
        navigate(Pathname.REJECTED, { replace: true })
        break
      default:
        navigate(Pathname.FINISH_UP_WELCOME_SCREEN)
    }
  }

  const [updateMailingAddress] = useOnboardingMailingAddressUpdateMutation({
    onCompleted: () => {
      navigateToNextPage()
    },
    onError: () => {
      throw new Error('Error updating mailing address')
    },
  })

  const onContinue = async () => {
    if (!addressesAreSame) {
      navigate(Pathname.DEBIT_CARD_DELIVERY_ADDRESS)
      return
    }
    if (addressesAreSame) {
      if (!homeAddress || !businessId || !data?.me?.ownerBusinesses[0]?.onboarding?.application) {
        throw new Error('Missing required data to update mailing address')
      }
      analytics.cardShipping.mailingAddress({ addressType: 'personal' })
      dispatch(unpersistedActions.setMailingAddress('HOME'))
      void updateMailingAddress({
        variables: {
          data: {
            businessId,
            address: gqlLocationToLocationInput(homeAddress),
          },
        },
      })
      // handleApplicationStatus called in onCompleted
    }
  }

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <>
      {applicationStatus === OnboardingApplicationStatus.REVIEWING ? (
        <FinishUpLandingMRScreen continueButtonPath={onContinue} />
      ) : isFundingUrgencyEnabled ? (
        <FinishUpFundingLandingScreen
          formattedHomeAddress={formattedHomeAddress}
          addressesAreSame={addressesAreSame}
          continueButtonPath={onContinue}
        />
      ) : (
        <FinishUpLandingScreen
          formattedHomeAddress={formattedHomeAddress}
          addressesAreSame={addressesAreSame}
          onContinue={onContinue}
        />
      )}
    </>
  )
}
