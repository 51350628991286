import { Navigate, useParams } from 'react-router-dom'
import { useAppSelector } from '@/core/redux/utils'
import { Pathname } from '../constants'

/**
 * Ensures that owner ID from path parameter exists in owner list state
 */
export const RequireExistingOwnerId = ({ children }: { children: JSX.Element }) => {
  const ownerIdFromPathParams = useParams().ownerId

  const owners = useAppSelector((state) => state.application.owners)
  const ownerIds = Object.keys(owners)

  if (!ownerIdFromPathParams || !ownerIds.includes(ownerIdFromPathParams))
    return <Navigate to={Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS} replace />

  return children
}
