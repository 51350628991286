import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  GraphQLDate: { input: any; output: any; }
  GraphQLDateTime: { input: any; output: any; }
  GraphQLTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AcceptedAgreement = {
  __typename?: 'AcceptedAgreement';
  acceptedAt: Scalars['String']['output'];
  agreementType: Scalars['String']['output'];
  documentReference: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type AccountStatement = {
  __typename?: 'AccountStatement';
  month?: Maybe<Scalars['String']['output']>;
  statement?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type AccountStatementPdf = {
  __typename?: 'AccountStatementPdf';
  month?: Maybe<Scalars['String']['output']>;
  pdf?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type AccountUsageData = {
  averageACHAmount: Scalars['Float']['input'];
  averageCheckDepositAmount: Scalars['Float']['input'];
  averageCheckPaymentAmount: Scalars['Float']['input'];
  averageDepositAmount: Scalars['Float']['input'];
  averageWireAmount: Scalars['Float']['input'];
  businessId: Scalars['ID']['input'];
  debitCardAmount: Scalars['Float']['input'];
  numberOfACHs: Scalars['Int']['input'];
  numberOfCheckDeposits: Scalars['Int']['input'];
  numberOfCheckPayment: Scalars['Int']['input'];
  numberOfWires: Scalars['Int']['input'];
  percentRevenueOutsideUS: Scalars['Int']['input'];
};

export type AccountUsageSubmitResponse = MutationResponse & {
  __typename?: 'AccountUsageSubmitResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AchCapability = {
  __typename?: 'AchCapability';
  limits: TransferLimits;
};

export type AchCutoffTimesResponse = {
  __typename?: 'AchCutoffTimesResponse';
  cutoffTimes: Array<Scalars['String']['output']>;
  isSameDayAvailable: Scalars['Boolean']['output'];
  nextAvailableTime?: Maybe<Scalars['String']['output']>;
  nextCutoff: Scalars['String']['output'];
  secondsToNextAvailable?: Maybe<Scalars['Float']['output']>;
  secondsToNextCutoff: Scalars['Float']['output'];
  secondsToNextWireCutoff: Scalars['Float']['output'];
  wireCutoffTime: Scalars['String']['output'];
};

export type AchPullCapability = {
  __typename?: 'AchPullCapability';
  limits: TransferLimits;
};

export type AddVirtualCardResponse = MutationResponse & {
  __typename?: 'AddVirtualCardResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AdditionalOwnerOnboardingStatus = {
  __typename?: 'AdditionalOwnerOnboardingStatus';
  onboardingCompleted: Scalars['Boolean']['output'];
  userId: Scalars['String']['output'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type Agreement = {
  __typename?: 'Agreement';
  documentReference: Scalars['String']['output'];
  html: Scalars['String']['output'];
};

export enum AgreementType {
  ACCOUNT_AGREEMENT = 'ACCOUNT_AGREEMENT',
  ESIGN_AGREEMENT = 'ESIGN_AGREEMENT',
  ISSUER_AGREEMENT = 'ISSUER_AGREEMENT',
  MOBILE_PAYMENT_SERVICES_AGREEMENT = 'MOBILE_PAYMENT_SERVICES_AGREEMENT',
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
  WIRE_TRANSFER_AGREEMENT = 'WIRE_TRANSFER_AGREEMENT'
}

export type AgreementsPdf = {
  __typename?: 'AgreementsPDF';
  pdfURL: Scalars['String']['output'];
};

export type ApplicantBusiness = {
  __typename?: 'ApplicantBusiness';
  address?: Maybe<Address>;
  annualRevenue?: Maybe<Scalars['Int']['output']>;
  businessType?: Maybe<BusinessType>;
  dba?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ein?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  naicsCode?: Maybe<Scalars['String']['output']>;
  numberOfEmployees?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  typeOfProductsOrServices?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  yearsAtLocation?: Maybe<Scalars['Int']['output']>;
};

export type ApplySubscriptionPromoCodeInput = {
  businessId: Scalars['ID']['input'];
  idempotencyKey: Scalars['String']['input'];
  promoCode: Scalars['String']['input'];
  shouldOverrideExisting: Scalars['Boolean']['input'];
};

export type ApplySubscriptionPromoCodeMutationResponse = MutationResponse & {
  __typename?: 'ApplySubscriptionPromoCodeMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription: Subscription;
  success: Scalars['Boolean']['output'];
};

export enum AuthTokenTypeEnum {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN'
}

export type BankAccount = {
  __typename?: 'BankAccount';
  accountNumber?: Maybe<Scalars['String']['output']>;
  accountStatementPdf?: Maybe<AccountStatementPdf>;
  accountStatements: Array<AccountStatement>;
  accountStatementsByYear: Array<YearlyAccountStatements>;
  availableBalance?: Maybe<Scalars['Float']['output']>;
  bankAddress?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['ID']['output']>;
  capabilities?: Maybe<BankAccountCapabilities>;
  card?: Maybe<Card>;
  cards: Array<Card>;
  id: Scalars['ID']['output'];
  lowBalanceInfo: LowBalanceInfo;
  routingNumber?: Maybe<Scalars['String']['output']>;
  subAccount?: Maybe<SubAccount>;
  subAccounts: Array<SubAccount>;
  topUpsSettings?: Maybe<GetTopUpsSettingsQueryResponse>;
};


export type BankAccountAccountStatementPdfArgs = {
  month: Scalars['String']['input'];
  year: Scalars['String']['input'];
};


export type BankAccountCardArgs = {
  cardId: Scalars['ID']['input'];
};


export type BankAccountSubAccountArgs = {
  subAccountId: Scalars['ID']['input'];
};

export type BankAccountCapabilities = {
  __typename?: 'BankAccountCapabilities';
  ach: BankAccountCapabilitiesAch;
  billPay: BankAccountCapabilitiesBillPay;
  checkDeposit: BankAccountCapabilitiesCheckDeposit;
  debitCardDeposit: BankAccountCapabilitiesDebitCardDeposit;
  domesticWire: BankAccountCapabilitiesDomesticWire;
  mailedCheck: BankAccountCapabilitiesMailedCheck;
  sameDayAch: BankAccountCapabilitiesSameDayAch;
};

export type BankAccountCapabilitiesAch = {
  __typename?: 'BankAccountCapabilitiesACH';
  dailyLimit: Scalars['Float']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  limit: Scalars['Float']['output'];
  thirtyDayRollingLimit: Scalars['Float']['output'];
};

export type BankAccountCapabilitiesBillPay = {
  __typename?: 'BankAccountCapabilitiesBillPay';
  fee: Fee;
  limit: Scalars['Float']['output'];
};

export type BankAccountCapabilitiesCheckDeposit = {
  __typename?: 'BankAccountCapabilitiesCheckDeposit';
  dailyLimit: Scalars['Float']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  limit: Scalars['Float']['output'];
  thirtyDayRollingLimit: Scalars['Float']['output'];
};

export type BankAccountCapabilitiesDebitCardDeposit = {
  __typename?: 'BankAccountCapabilitiesDebitCardDeposit';
  enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type BankAccountCapabilitiesDomesticWire = {
  __typename?: 'BankAccountCapabilitiesDomesticWire';
  dailyLimit: Scalars['Float']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  fee: Fee;
  limit: Scalars['Float']['output'];
  thirtyDayRollingLimit: Scalars['Float']['output'];
};

export type BankAccountCapabilitiesMailedCheck = {
  __typename?: 'BankAccountCapabilitiesMailedCheck';
  fee: Fee;
  limit: Scalars['Float']['output'];
};

export type BankAccountCapabilitiesSameDayAch = {
  __typename?: 'BankAccountCapabilitiesSameDayACH';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  fee: Fee;
};

export type BasicUser = {
  __typename?: 'BasicUser';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPrimaryOwner: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  signUpDate: Scalars['String']['output'];
};

export type BillPayCapability = {
  __typename?: 'BillPayCapability';
  fee: Fee;
  limits: BillPayLimits;
};

export type BillPayLimits = {
  __typename?: 'BillPayLimits';
  MAX_PER_TRANSACTION: Scalars['Float']['output'];
};

export type BillPayPaymentDetailsInput = {
  accountNumber: Scalars['String']['input'];
};

export type Biller = {
  __typename?: 'Biller';
  billerAccountNumberPatterns?: Maybe<Array<Scalars['String']['output']>>;
  billerName: Scalars['String']['output'];
  rppsBillerId: Scalars['String']['output'];
};

export type Business = {
  __typename?: 'Business';
  address?: Maybe<Address>;
  bankAccount?: Maybe<BankAccount>;
  bankAccounts: Array<BankAccount>;
  contact: Contact;
  contacts: Array<Contact>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceForwardingEmail?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onboarding?: Maybe<BusinessOnboarding>;
  onboardingMailingAddress?: Maybe<Location>;
  ownerCandidates: Array<OwnerCandidate>;
  shareAndEarn?: Maybe<ShareAndEarn>;
  shippingAddress?: Maybe<Address>;
  subscription?: Maybe<Subscription>;
  subscriptionPlans?: Maybe<SubscriptionPlans>;
  teamMembers: Array<TeamMember>;
  type?: Maybe<Scalars['String']['output']>;
  users: Array<BasicUser>;
};


export type BusinessBankAccountArgs = {
  bankAccountId?: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessContactArgs = {
  contactId: Scalars['ID']['input'];
};


export type BusinessContactsArgs = {
  contactPaymentType?: InputMaybe<ContactPaymentType>;
};

export type BusinessAccessTokenMutationResponse = MutationResponse & {
  __typename?: 'BusinessAccessTokenMutationResponse';
  businessAccessToken: Scalars['String']['output'];
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type BusinessOnboarding = {
  __typename?: 'BusinessOnboarding';
  accountStatus?: Maybe<OnboardingAccountStatus>;
  application?: Maybe<OnboardingApplication>;
  businessId: Scalars['ID']['output'];
  /** @deprecated Not in use. Account funding service used for this query is deprecated and no longer in use. */
  initialFundingStatus?: Maybe<CardFundingTransactionStatusEnum>;
};

export enum BusinessType {
  CORP = 'CORP',
  FREELANCER = 'FREELANCER',
  LLC = 'LLC',
  PARTNERSHIP = 'PARTNERSHIP',
  SOLEPROP = 'SOLEPROP'
}

export type CancelScheduledPaymentResponse = MutationResponse & {
  __typename?: 'CancelScheduledPaymentResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CaptureInitialFundingAccountTypeMutationResponse = MutationResponse & {
  __typename?: 'CaptureInitialFundingAccountTypeMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CaptureInitialFundingAmountMutationResponse = MutationResponse & {
  __typename?: 'CaptureInitialFundingAmountMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Card = {
  __typename?: 'Card';
  /** ** deprecated ** use activation instead */
  activated?: Maybe<Scalars['Boolean']['output']>;
  activation?: Maybe<CardActivation>;
  businessId: Scalars['ID']['output'];
  canceled?: Maybe<Scalars['Boolean']['output']>;
  cardNumber?: Maybe<CardNumber>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  embossLine1?: Maybe<Scalars['String']['output']>;
  embossLine2?: Maybe<Scalars['String']['output']>;
  freezeInfo?: Maybe<CardFreezeInfo>;
  id: Scalars['ID']['output'];
  productType?: Maybe<CardProductType>;
  shippedDate?: Maybe<Scalars['String']['output']>;
  user?: Maybe<BasicUser>;
};

export type CardActivate = {
  __typename?: 'CardActivate';
  accountId: Scalars['ID']['output'];
  businessId: Scalars['ID']['output'];
  cardId: Scalars['ID']['output'];
  expirationDate: Scalars['String']['output'];
  securityCode: Scalars['String']['output'];
};

export type CardActivateResponse = MutationResponse & {
  __typename?: 'CardActivateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CardActivation = {
  __typename?: 'CardActivation';
  activated?: Maybe<Scalars['Boolean']['output']>;
  cardId: Scalars['ID']['output'];
  pinSet?: Maybe<CardPinSet>;
};

export type CardFreezeInfo = {
  __typename?: 'CardFreezeInfo';
  endDate?: Maybe<Scalars['String']['output']>;
  frozen: Scalars['Boolean']['output'];
};

export type CardFreezeResponse = MutationResponse & {
  __typename?: 'CardFreezeResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** Deprecated. Temporarily moved enum here since account-funding feature has been removed and BusinessOnboarding.initialFundingStatus references this enum */
export enum CardFundingTransactionStatusEnum {
  TRANSACTION_FAILED = 'TRANSACTION_FAILED',
  TRANSACTION_INITIATED = 'TRANSACTION_INITIATED',
  TRANSACTION_SUCCESSFUL = 'TRANSACTION_SUCCESSFUL',
  TRANSACTION_UNRESOLVED = 'TRANSACTION_UNRESOLVED'
}

export type CardNumber = {
  __typename?: 'CardNumber';
  last4?: Maybe<Scalars['String']['output']>;
};

export type CardPinChangeForm = {
  __typename?: 'CardPinChangeForm';
  cardId: Scalars['ID']['output'];
  expiresAt: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type CardPinSet = {
  __typename?: 'CardPinSet';
  cardId: Scalars['ID']['output'];
  status?: Maybe<CardPinSetStatusEnum>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum CardPinSetStatusEnum {
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  READY = 'READY',
  SET = 'SET'
}

export enum CardProductType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL'
}

export type CardUnfreezeResponse = MutationResponse & {
  __typename?: 'CardUnfreezeResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CheckDepositCreateInput = {
  accountId: Scalars['ID']['input'];
  amountInCents: Scalars['Float']['input'];
  frontImage: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  rearImage: Scalars['String']['input'];
};

export type CheckDepositCreateMutationResponse = MutationResponse & {
  __typename?: 'CheckDepositCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ConcreteMutationResponse = {
  __typename?: 'ConcreteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ConfirmAdditionalOwnersOnboardingStatusMutationResponse = MutationResponse & {
  __typename?: 'ConfirmAdditionalOwnersOnboardingStatusMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status?: Maybe<AdditionalOwnerOnboardingStatus>;
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export type Contact = {
  __typename?: 'Contact';
  achPaymentDetails?: Maybe<ContactAchPaymentDetails>;
  address?: Maybe<Location>;
  billPayPaymentDetails?: Maybe<ContactBillPayPaymentDetails>;
  businessId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mailedCheckPaymentDetails?: Maybe<ContactMailedCheckPaymentDetails>;
  name: Scalars['String']['output'];
  relationship?: Maybe<Scalars['String']['output']>;
  /**
   * RPPS stands for Mastercard Remote Payment and Presentment Service
   * rppsBillerDetails simply indicate that the Contact exists in the RPPS directory.
   * To make bill payments, Bill Pay payment method details must still exist on the Contact
   */
  rppsBillerDetails?: Maybe<RppsBillerDetails>;
  wirePaymentDetails?: Maybe<ContactWirePaymentDetails>;
};

export type ContactAchPaymentDetails = {
  __typename?: 'ContactAchPaymentDetails';
  accountNumber: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type ContactAchPaymentDetailsCreateInput = {
  accountNumber: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
};

export type ContactAchPaymentDetailsUpdateInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ContactBillPayPaymentDetails = {
  __typename?: 'ContactBillPayPaymentDetails';
  accountNumber: Scalars['String']['output'];
};

export type ContactBillPayPaymentDetailsCreateInput = {
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  paymentDetails: BillPayPaymentDetailsInput;
};

export type ContactBillPayPaymentDetailsCreateMutationResponse = MutationResponse & {
  __typename?: 'ContactBillPayPaymentDetailsCreateMutationResponse';
  code: Scalars['String']['output'];
  contact: Contact;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactBillPayPaymentDetailsDeleteInput = {
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
};

export type ContactBillPayPaymentDetailsDeleteMutationResponse = MutationResponse & {
  __typename?: 'ContactBillPayPaymentDetailsDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactBillPayPaymentDetailsUpdateInput = {
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  paymentDetails: BillPayPaymentDetailsInput;
};

export type ContactBillPayPaymentDetailsUpdateMutationResponse = MutationResponse & {
  __typename?: 'ContactBillPayPaymentDetailsUpdateMutationResponse';
  code: Scalars['String']['output'];
  contact: Contact;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactCreateDataInput = {
  address?: InputMaybe<LocationInput>;
  businessId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  relationship?: InputMaybe<Scalars['String']['input']>;
  rppsBillerDetails?: InputMaybe<ContactRppsBillerDetailsInput>;
};

export type ContactCreateMutationResponse = MutationResponse & {
  __typename?: 'ContactCreateMutationResponse';
  code: Scalars['String']['output'];
  contact: Contact;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactDeleteInput = {
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
};

export type ContactDeleteMutationResponse = MutationResponse & {
  __typename?: 'ContactDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactDomesticAchCreateDataInput = {
  achPaymentDetails: ContactAchPaymentDetailsCreateInput;
  businessId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ContactDomesticAchUpdateDataInput = {
  achPaymentDetails?: InputMaybe<ContactAchPaymentDetailsUpdateInput>;
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ContactDomesticWireCreateDataInput = {
  address: LocationInput;
  businessId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  relationship: Scalars['String']['input'];
  wirePaymentDetails: ContactWirePaymentDetailsCreateInput;
};

export type ContactDomesticWireUpdateDataInput = {
  address?: InputMaybe<LocationInput>;
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
  wirePaymentDetails?: InputMaybe<ContactWirePaymentDetailsUpdateInput>;
};

export type ContactMailedCheckCreateDataInput = {
  address: LocationInput;
  businessId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ContactMailedCheckPaymentDetails = {
  __typename?: 'ContactMailedCheckPaymentDetails';
  enabled: Scalars['Boolean']['output'];
};

export type ContactMailedCheckUpdateDataInput = {
  address: LocationInput;
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export enum ContactPaymentType {
  ACH = 'ach',
  BILLPAY = 'billPay',
  MAILEDCHECK = 'mailedCheck',
  WIRE = 'wire'
}

export type ContactRppsBillerDetailsInput = {
  billerName: Scalars['String']['input'];
  rppsBillerId: Scalars['String']['input'];
};

export type ContactUpdateDataInput = {
  address?: InputMaybe<LocationInput>;
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
};

export type ContactUpdateMutationResponse = MutationResponse & {
  __typename?: 'ContactUpdateMutationResponse';
  code: Scalars['String']['output'];
  contact: Contact;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactWirePaymentDetails = {
  __typename?: 'ContactWirePaymentDetails';
  accountNumber: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type ContactWirePaymentDetailsCreateInput = {
  accountNumber: Scalars['String']['input'];
  bankName: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
};

export type ContactWirePaymentDetailsUpdateInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type CreateInvoiceAccountsPayablePaymentDataInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  idempotencyKey: Scalars['String']['input'];
  paymentMethodDetails: InvoiceAccountPayablePaymentMethodDetails;
  subAccountId: Scalars['ID']['input'];
};

export type CreateInvoiceAccountsPayablePaymentMutationResponse = MutationResponse & {
  __typename?: 'CreateInvoiceAccountsPayablePaymentMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  transactionId: Scalars['String']['output'];
};

export type CreateInvoiceAccountsPayableScheduledPaymentDataInput = {
  accountId: Scalars['ID']['input'];
  amount: Scalars['Float']['input'];
  /** Format: ISO date. eg YYYY-MM-DD */
  endDate?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
  paymentMethodDetails: InvoiceAccountPayablePaymentMethodDetails;
  /** Values could be: 'once' */
  period: Scalars['String']['input'];
  /** Format: ISO date. eg YYYY-MM-DD */
  startDate: Scalars['String']['input'];
  subAccountId: Scalars['ID']['input'];
};

export type CreateInvoiceAccountsPayableScheduledPaymentMutationResponse = MutationResponse & {
  __typename?: 'CreateInvoiceAccountsPayableScheduledPaymentMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  scheduledPaymentId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateInvoiceAttachmentDataInput = {
  contentLength: Scalars['Float']['input'];
  contentType: Scalars['String']['input'];
  extract: Scalars['Boolean']['input'];
};

export type CreateInvoiceAttachmentMutationResponse = MutationResponse & {
  __typename?: 'CreateInvoiceAttachmentMutationResponse';
  attachmentId: Scalars['ID']['output'];
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: InvoiceAttachmentStatus;
  success: Scalars['Boolean']['output'];
  upload: InvoiceAttachmentUpload;
};

export type CreateLinkTokenMutationResponse = MutationResponse & {
  __typename?: 'CreateLinkTokenMutationResponse';
  code: Scalars['String']['output'];
  data: LinkTokenData;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export enum CsvFormatEnum {
  QBO = 'QBO',
  QBO_DEBIT_CREDIT = 'QBO_DEBIT_CREDIT',
  STANDARD = 'STANDARD'
}

export enum CurrencyCodeEnum {
  CAD = 'CAD',
  CHF = 'CHF',
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD'
}

export type CurrentPlan = {
  __typename?: 'CurrentPlan';
  planName: Scalars['String']['output'];
};

export type DatetimeRangeInput = {
  /** datetime in ISO 8601 format */
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  /** datetime in ISO 8601 format */
  startDatetime?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteMutationResponse = MutationPayload & {
  __typename?: 'DeleteMutationResponse';
  success: Scalars['Boolean']['output'];
  userErrors: Array<UserError>;
};

export type DeviceInfoInput = {
  os: OperatingSystemEnum;
  /** i.e) for ios 12.1.2 major version would be '12' */
  osMajorVersion: Scalars['String']['input'];
};

export type DigitalCardAssetUrl = {
  __typename?: 'DigitalCardAssetUrl';
  cardId: Scalars['ID']['output'];
  expiresAt: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DisableTopUpsDataInput = {
  idempotencyKey: Scalars['String']['input'];
};

export type DisableTopUpsResponse = MutationResponse & {
  __typename?: 'DisableTopUpsResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DiscountFeeDetails = FlatFeeDetails | PercentFeeDetails;

export type DocumentRequestEmailResendData = {
  applicationId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentRequestEmailResendResponse = MutationResponse & {
  __typename?: 'DocumentRequestEmailResendResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DomesticWireCreateInput = {
  amount: Scalars['Float']['input'];
  contactId: Scalars['ID']['input'];
  contactNotificationOptions?: InputMaybe<TransactionContactNotificationOptionsInput>;
  idempotencyKey: Scalars['String']['input'];
  purpose: Scalars['String']['input'];
  subAccountId: Scalars['ID']['input'];
};

export type DomesticWireCreateMutationResponse = MutationResponse & {
  __typename?: 'DomesticWireCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum ErrorCodeEnum {
  ACCOUNT_FUNDING_ADDRESS_VALIDATION_FAILED = 'ACCOUNT_FUNDING_ADDRESS_VALIDATION_FAILED',
  /** Account funding validation errors */
  ACCOUNT_FUNDING_PULL_NOT_ENABLED = 'ACCOUNT_FUNDING_PULL_NOT_ENABLED',
  ACCOUNT_FUNDING_UNSUPPORTED_CARD_CREDIT = 'ACCOUNT_FUNDING_UNSUPPORTED_CARD_CREDIT',
  ACCOUNT_FUNDING_UNSUPPORTED_CARD_INTERNATIONAL = 'ACCOUNT_FUNDING_UNSUPPORTED_CARD_INTERNATIONAL',
  ACCOUNT_FUNDING_UNSUPPORTED_CARD_PREPAID = 'ACCOUNT_FUNDING_UNSUPPORTED_CARD_PREPAID',
  /** Not authorized to access resource */
  AUTH_UNAUTHORIZED = 'AUTH_UNAUTHORIZED',
  /** registerUser mutation error */
  AUTH_USER_ALREADY_EXISTS = 'AUTH_USER_ALREADY_EXISTS',
  AVAILABLE_BALANCE_MINUS_PENDING_ERROR = 'AVAILABLE_BALANCE_MINUS_PENDING_ERROR',
  BILL_PAYMENT_RECIPIENT_CREATE_ERROR = 'BILL_PAYMENT_RECIPIENT_CREATE_ERROR',
  /** Card activation errors */
  CARD_ACTIVATION_INVALID_INPUT = 'CARD_ACTIVATION_INVALID_INPUT',
  CHECK_DEPOSIT_AMOUNT_NOT_MATCHING = 'CHECK_DEPOSIT_AMOUNT_NOT_MATCHING',
  CHECK_DEPOSIT_AMOUNT_TOO_HIGH = 'CHECK_DEPOSIT_AMOUNT_TOO_HIGH',
  CHECK_DEPOSIT_AMOUNT_TOO_LOW = 'CHECK_DEPOSIT_AMOUNT_TOO_LOW',
  CHECK_DEPOSIT_BANK_ACCOUNT_NOT_FOUND = 'CHECK_DEPOSIT_BANK_ACCOUNT_NOT_FOUND',
  CHECK_DEPOSIT_CHECK_BLENDED_WITH_BACKGROUND = 'CHECK_DEPOSIT_CHECK_BLENDED_WITH_BACKGROUND',
  CHECK_DEPOSIT_CORNERS_NOT_VISIBLE = 'CHECK_DEPOSIT_CORNERS_NOT_VISIBLE',
  CHECK_DEPOSIT_DAILY_LIMIT_EXCEEDED = 'CHECK_DEPOSIT_DAILY_LIMIT_EXCEEDED',
  CHECK_DEPOSIT_IMAGE_TOO_SMALL = 'CHECK_DEPOSIT_IMAGE_TOO_SMALL',
  CHECK_DEPOSIT_MISSING_BACK_PHOTO = 'CHECK_DEPOSIT_MISSING_BACK_PHOTO',
  CHECK_DEPOSIT_MONTHLY_LIMIT_EXCEEDED = 'CHECK_DEPOSIT_MONTHLY_LIMIT_EXCEEDED',
  CHECK_DEPOSIT_PHOTO_NOT_CLEAR = 'CHECK_DEPOSIT_PHOTO_NOT_CLEAR',
  CHECK_DEPOSIT_SHADOW_IN_PHOTOS = 'CHECK_DEPOSIT_SHADOW_IN_PHOTOS',
  CHECK_DEPOSIT_SIGNATURE_NOT_FOUND = 'CHECK_DEPOSIT_SIGNATURE_NOT_FOUND',
  /** Check deposit errors */
  CHECK_DEPOSIT_SOMETHING_WENT_WRONG = 'CHECK_DEPOSIT_SOMETHING_WENT_WRONG',
  CONTACT_INVALID_BILLER_ACCOUNT_NUMBER = 'CONTACT_INVALID_BILLER_ACCOUNT_NUMBER',
  DEBIT_CARD_DEPOSIT_ADDRESS_VALIDATION_FAILED = 'DEBIT_CARD_DEPOSIT_ADDRESS_VALIDATION_FAILED',
  /** Debit card deposit validation errors */
  DEBIT_CARD_DEPOSIT_DAILY_LIMIT_EXCEEDED = 'DEBIT_CARD_DEPOSIT_DAILY_LIMIT_EXCEEDED',
  DEBIT_CARD_DEPOSIT_MONTHLY_LIMIT_EXCEEDED = 'DEBIT_CARD_DEPOSIT_MONTHLY_LIMIT_EXCEEDED',
  DEBIT_CARD_DEPOSIT_PULL_NOT_ENABLED = 'DEBIT_CARD_DEPOSIT_PULL_NOT_ENABLED',
  DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_CREDIT = 'DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_CREDIT',
  DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_INTERNATIONAL = 'DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_INTERNATIONAL',
  DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_PREPAID = 'DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_PREPAID',
  DOMESTIC_ACH_DAILY_LIMIT_EXCEEDED = 'DOMESTIC_ACH_DAILY_LIMIT_EXCEEDED',
  DOMESTIC_ACH_EXCEEDS_30D_LIMIT = 'DOMESTIC_ACH_EXCEEDS_30D_LIMIT',
  DOMESTIC_ACH_EXCEEDS_TRANSACTION_LIMIT = 'DOMESTIC_ACH_EXCEEDS_TRANSACTION_LIMIT',
  DOMESTIC_ACH_INSUFFICIENT_BALANCE = 'DOMESTIC_ACH_INSUFFICIENT_BALANCE',
  DOMESTIC_ACH_INVALID_DETAILS = 'DOMESTIC_ACH_INVALID_DETAILS',
  /** Domestic ACH errors */
  DOMESTIC_ACH_SOMETHING_WENT_WRONG = 'DOMESTIC_ACH_SOMETHING_WENT_WRONG',
  /** Domestic Wire errors */
  DOMESTIC_WIRE_CARD_NOT_ACTIVATED = 'DOMESTIC_WIRE_CARD_NOT_ACTIVATED',
  DOMESTIC_WIRE_EXCEEDS_30D_LIMIT = 'DOMESTIC_WIRE_EXCEEDS_30D_LIMIT',
  DOMESTIC_WIRE_EXCEEDS_DAILY_LIMIT = 'DOMESTIC_WIRE_EXCEEDS_DAILY_LIMIT',
  DOMESTIC_WIRE_EXCEEDS_TRANSACTION_LIMIT = 'DOMESTIC_WIRE_EXCEEDS_TRANSACTION_LIMIT',
  DOMESTIC_WIRE_INSUFFICIENT_BALANCE = 'DOMESTIC_WIRE_INSUFFICIENT_BALANCE',
  DOMESTIC_WIRE_SOMETHING_WENT_WRONG = 'DOMESTIC_WIRE_SOMETHING_WENT_WRONG',
  /** Contacts errors */
  DUPLICATE_ACH_CONTACT_NOT_ALLOWED = 'DUPLICATE_ACH_CONTACT_NOT_ALLOWED',
  /** Resource not found */
  GENERAL_NOT_FOUND = 'GENERAL_NOT_FOUND',
  /** Inputs not valid */
  GENERAL_VALIDATION_FAILED = 'GENERAL_VALIDATION_FAILED',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  INVALID_CARD = 'INVALID_CARD',
  INVALID_CARD_FUNDING = 'INVALID_CARD_FUNDING',
  INVALID_INVITATION_TOKEN = 'INVALID_INVITATION_TOKEN',
  INVALID_ROUTING_NUMBER = 'INVALID_ROUTING_NUMBER',
  INVOICE_ACCOUNTS_PAYABLE_CARD_NOT_ACTIVATED = 'INVOICE_ACCOUNTS_PAYABLE_CARD_NOT_ACTIVATED',
  INVOICE_ACCOUNTS_PAYABLE_EXCEEDS_LIMIT = 'INVOICE_ACCOUNTS_PAYABLE_EXCEEDS_LIMIT',
  /** Invoice Accounts Payable errors */
  INVOICE_ACCOUNTS_PAYABLE_INSUFFICIENT_BALANCE = 'INVOICE_ACCOUNTS_PAYABLE_INSUFFICIENT_BALANCE',
  INVOICE_ACCOUNTS_PAYABLE_INVALID_ACH_DETAILS = 'INVOICE_ACCOUNTS_PAYABLE_INVALID_ACH_DETAILS',
  INVOICE_ACCOUNTS_PAYABLE_INVALID_SCHEDULE_START_DATE = 'INVOICE_ACCOUNTS_PAYABLE_INVALID_SCHEDULE_START_DATE',
  INVOICE_ACCOUNTS_PAYABLE_INVALID_WIRE_DETAILS = 'INVOICE_ACCOUNTS_PAYABLE_INVALID_WIRE_DETAILS',
  INVOICE_ACCOUNTS_PAYABLE_MISSING_MAILED_CHECK_ADDRESS = 'INVOICE_ACCOUNTS_PAYABLE_MISSING_MAILED_CHECK_ADDRESS',
  INVOICE_ACCOUNTS_PAYABLE_MISSING_MAILED_CHECK_DETAILS = 'INVOICE_ACCOUNTS_PAYABLE_MISSING_MAILED_CHECK_DETAILS',
  INVOICE_ACCOUNTS_PAYABLE_SOMETHING_WENT_WRONG = 'INVOICE_ACCOUNTS_PAYABLE_SOMETHING_WENT_WRONG',
  PLAID_FUNDING_30_DAY_TRANSACTION_LIMIT_REACHED = 'PLAID_FUNDING_30_DAY_TRANSACTION_LIMIT_REACHED',
  PLAID_FUNDING_30_DAY_TRANSFER_AMOUNT_LIMIT_REACHED = 'PLAID_FUNDING_30_DAY_TRANSFER_AMOUNT_LIMIT_REACHED',
  /** plaid-funding errors */
  PLAID_FUNDING_IDENTITY_MATCH_FAILED = 'PLAID_FUNDING_IDENTITY_MATCH_FAILED',
  PLAID_FUNDING_SIGNAL_SCORE_EXCEEDS_ALLOWABLE_LIMITS = 'PLAID_FUNDING_SIGNAL_SCORE_EXCEEDS_ALLOWABLE_LIMITS',
  PLAID_FUNDING_SOMETHING_WENT_WRONG = 'PLAID_FUNDING_SOMETHING_WENT_WRONG',
  PLAID_FUNDING_TRANSACTION_ALREADY_IN_PROGRESS = 'PLAID_FUNDING_TRANSACTION_ALREADY_IN_PROGRESS',
  RECAPTCHA_ERROR = 'RECAPTCHA_ERROR',
  /** subscriptions errors */
  SUBSCRIPTION_UPGRADE_FAILED_NSF = 'SUBSCRIPTION_UPGRADE_FAILED_NSF',
  SUPPORT_UNAVAILABLE_TIME_SLOT = 'SUPPORT_UNAVAILABLE_TIME_SLOT',
  /** Account does not have sufficient funds for transaction */
  TRANSACTIONS_INSUFFICIENT_FUNDS = 'TRANSACTIONS_INSUFFICIENT_FUNDS',
  /** Transaction schedule not valid */
  TRANSACTIONS_INVALID_SCHEDULE = 'TRANSACTIONS_INVALID_SCHEDULE',
  TRANSACTIONS_LIMIT_EXCEEDED = 'TRANSACTIONS_LIMIT_EXCEEDED',
  /** Mobile app version uses deprecated API */
  UNSUPPORTED_MOBILE_APP_VERSION = 'UNSUPPORTED_MOBILE_APP_VERSION'
}

export type ExchangePublicTokenData = {
  __typename?: 'ExchangePublicTokenData';
  itemId: Scalars['String']['output'];
};

export type ExchangePublicTokenMutationResponse = MutationResponse & {
  __typename?: 'ExchangePublicTokenMutationResponse';
  code: Scalars['String']['output'];
  data: ExchangePublicTokenData;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export type Fee = FlatFee | PercentFee;

export enum FeeType {
  FLAT = 'flat',
  PERCENT = 'percent'
}

export type FlatFee = {
  __typename?: 'FlatFee';
  amount: Scalars['Float']['output'];
  type: FeeType;
};

export type FlatFeeDetails = {
  __typename?: 'FlatFeeDetails';
  amount: Scalars['Float']['output'];
  feeType: FeeType;
};

export type FloatRangeInput = {
  maximum?: InputMaybe<Scalars['Float']['input']>;
  minimum?: InputMaybe<Scalars['Float']['input']>;
};

export type GetBalanceQueryResponse = {
  __typename?: 'GetBalanceQueryResponse';
  data: PlaidAccounts;
};

export type GetFundingAmountsQueryResponse = {
  __typename?: 'GetFundingAmountsQueryResponse';
  defaultAmount: Scalars['Int']['output'];
  maximumAmount: Scalars['Int']['output'];
  minimumAmount: Scalars['Int']['output'];
};

export type GetFundingStatusQueryResponse = {
  __typename?: 'GetFundingStatusQueryResponse';
  status: PlaidFundingStatusEnum;
};

export type GetIdentityQueryResponse = {
  __typename?: 'GetIdentityQueryResponse';
  data: PlaidAccounts;
};

export type GetInvoiceAccountsPayableQueryResponse = {
  __typename?: 'GetInvoiceAccountsPayableQueryResponse';
  invoice: InvoiceAccountsPayable;
};

export type GetInvoiceAttachmentResponse = {
  __typename?: 'GetInvoiceAttachmentResponse';
  attachmentData?: Maybe<InvoiceAttachmentData>;
  attachmentId: Scalars['ID']['output'];
  file?: Maybe<InvoiceAttachmentFile>;
  status: InvoiceAttachmentStatus;
};

export type GetTopUpsSettingsQueryResponse = {
  __typename?: 'GetTopUpsSettingsQueryResponse';
  error?: Maybe<TopUpsError>;
  isTopUpsEnabled: Scalars['Boolean']['output'];
  lowBalanceThreshold: Scalars['Float']['output'];
  plaidItem?: Maybe<PlaidItem>;
  topUpAmount: Scalars['Float']['output'];
};

export type GroupedIndustries = {
  __typename?: 'GroupedIndustries';
  industries: Array<KybIndustry>;
  vertical: Scalars['String']['output'];
};

export type IdentityCheckInputs = {
  businessName: Scalars['String']['input'];
  ownerNames: Array<Scalars['String']['input']>;
};

export type InitialFundingAmounts = {
  __typename?: 'InitialFundingAmounts';
  defaultAmount: Scalars['Float']['output'];
  maximumAmount: Scalars['Float']['output'];
  minimumAmount: Scalars['Float']['output'];
};

export type InitialFundingCapability = {
  __typename?: 'InitialFundingCapability';
  amounts: InitialFundingAmounts;
};

export type InitiatePlaidOneTimeTransferMutationResponse = MutationResponse & {
  __typename?: 'InitiatePlaidOneTimeTransferMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum IntercomApiCommandEnum {
  CONVERSATION_CREATE = 'CONVERSATION_CREATE',
  CONVERSATION_REPLY = 'CONVERSATION_REPLY',
  CONVERSATION_TAG = 'CONVERSATION_TAG'
}

export type InviteAdditionalOwnerMutationResponse = MutationResponse & {
  __typename?: 'InviteAdditionalOwnerMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export enum InvoiceAccountPayableCreatedVia {
  EMAIL_FORWARDING = 'email_forwarding',
  OTHER = 'other',
  USER = 'user'
}

export enum InvoiceAccountPayablePaymentMethod {
  ACH = 'ach',
  BILL_PAY = 'bill_pay',
  MAILED_CHECK = 'mailed_check',
  WIRE = 'wire'
}

export type InvoiceAccountPayablePaymentMethodDetails = {
  memo?: InputMaybe<Scalars['String']['input']>;
  purpose?: InputMaybe<Scalars['String']['input']>;
  sameDay?: InputMaybe<Scalars['Boolean']['input']>;
  type: InvoiceAccountPayablePaymentMethod;
};

export type InvoiceAccountsPayable = {
  __typename?: 'InvoiceAccountsPayable';
  amount?: Maybe<Scalars['Float']['output']>;
  attachmentId?: Maybe<Scalars['ID']['output']>;
  business: Business;
  /** Instead of this field, prefer to use business.id. Ideally, we can deprecate this field in the future. */
  businessId: Scalars['ID']['output'];
  contactId?: Maybe<Scalars['ID']['output']>;
  createdDate: Scalars['String']['output'];
  createdVia: InvoiceAccountPayableCreatedVia;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceDate?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isMarkedAsPaid?: Maybe<Scalars['Boolean']['output']>;
  markedAsPaidReason?: Maybe<Scalars['String']['output']>;
  paidAmount: Scalars['Float']['output'];
  paidDate?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<InvoicePaymentStatus>;
  pendingAmount: Scalars['Float']['output'];
  remainingAmount: Scalars['Float']['output'];
  scheduledAmount: Scalars['Float']['output'];
  status: InvoiceStatus;
  transactions?: Maybe<Array<Maybe<InvoiceAccountsPayableTransaction>>>;
  updatedDate?: Maybe<Scalars['String']['output']>;
  vendorName?: Maybe<Scalars['String']['output']>;
};

export type InvoiceAccountsPayableDataInput = {
  attachmentId?: InputMaybe<Scalars['ID']['input']>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
  invoiceDate?: InputMaybe<Scalars['String']['input']>;
  invoiceId?: InputMaybe<Scalars['ID']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  totalAmount?: InputMaybe<Scalars['Float']['input']>;
  vendorName?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceAccountsPayableListFiltersInput = {
  status?: InputMaybe<InvoiceStatus>;
};

export type InvoiceAccountsPayableListPaginationOptionsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceAccountsPayableListSortingInput = {
  sortDirection: SortDirection;
  sortKey: SortKey;
};

export type InvoiceAccountsPayablePagination = {
  __typename?: 'InvoiceAccountsPayablePagination';
  cursor?: Maybe<Scalars['String']['output']>;
};

export type InvoiceAccountsPayableTransaction = {
  __typename?: 'InvoiceAccountsPayableTransaction';
  accountId?: Maybe<Scalars['ID']['output']>;
  amount: Scalars['Float']['output'];
  business: Business;
  invoiceId: Scalars['ID']['output'];
  lastUpdated: Scalars['String']['output'];
  paymentMethod?: Maybe<InvoiceAccountPayablePaymentMethod>;
  scheduledDate?: Maybe<Scalars['String']['output']>;
  scheduledPaymentId?: Maybe<Scalars['ID']['output']>;
  status: InvoiceTransactionStatus;
  subAccount: SubAccount;
  transactionId?: Maybe<Scalars['ID']['output']>;
};

export type InvoiceAttachmentData = {
  __typename?: 'InvoiceAttachmentData';
  invoiceDate?: Maybe<Scalars['String']['output']>;
  invoiceDueDate?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoiceTotal?: Maybe<Scalars['String']['output']>;
  paymentDetails?: Maybe<InvoiceAttachmentDataPaymentDetails>;
  vendorName?: Maybe<Scalars['String']['output']>;
};

export type InvoiceAttachmentDataPaymentDetails = {
  __typename?: 'InvoiceAttachmentDataPaymentDetails';
  accountNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  paymentTerms?: Maybe<Scalars['String']['output']>;
  personalOrBusinessName?: Maybe<Scalars['String']['output']>;
  routingNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type InvoiceAttachmentFile = {
  __typename?: 'InvoiceAttachmentFile';
  contentType: Scalars['String']['output'];
  expires: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum InvoiceAttachmentStatus {
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export type InvoiceAttachmentUpload = {
  __typename?: 'InvoiceAttachmentUpload';
  expires: Scalars['String']['output'];
  fields: Array<InvoiceAttachmentUploadFields>;
  url: Scalars['String']['output'];
};

export type InvoiceAttachmentUploadFields = {
  __typename?: 'InvoiceAttachmentUploadFields';
  headerKey: Scalars['String']['output'];
  headerValue: Scalars['String']['output'];
};

export enum InvoicePaymentStatus {
  FAILED = 'failed',
  PROCESSING = 'processing',
  SCHEDULED = 'scheduled'
}

export enum InvoiceStatus {
  ARCHIVED = 'archived',
  PAID = 'paid',
  UNPAID = 'unpaid'
}

export enum InvoiceTransactionStatus {
  CANCELED = 'canceled',
  FAILED = 'failed',
  INITIATED = 'initiated',
  POSTED = 'posted',
  SCHEDULED = 'scheduled'
}

export type KybIndustry = {
  __typename?: 'KYBIndustry';
  keywords: Scalars['String']['output'];
  naicsCategory: Scalars['String']['output'];
  naicsCode: Scalars['Int']['output'];
  subCategory: Scalars['String']['output'];
  vertical: Scalars['String']['output'];
};

export type KanmonConnectTokenMutationResponse = MutationResponse & {
  __typename?: 'KanmonConnectTokenMutationResponse';
  code: Scalars['String']['output'];
  connectToken: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type KanmonLending = {
  __typename?: 'KanmonLending';
  business: Business;
  offers: Array<KanmonLendingOffer>;
  products: Array<KanmonLendingProduct>;
};

export type KanmonLendingOffer = {
  __typename?: 'KanmonLendingOffer';
  business: Business;
  createdAt: Scalars['String']['output'];
  expiredAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  loanType: KanmonLoanTypeEnum;
};

export type KanmonLendingProduct = {
  __typename?: 'KanmonLendingProduct';
  business: Business;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  loanStatus: KanmonLoanStatusEnum;
  loanType: KanmonLoanTypeEnum;
};

/**
 * This list of statuses may not be exhaustive, as new Kanmon statuses may be added.
 * If we are using this in our UI, we should consider whether we can handle unknown statuses gracefully.
 * Currently, an error is thrown if an unknown status is encountered.
 * We may want to consider a default enum value for unknown statuses, make this field nullable, or
 * have the FE handle partial operation errors gracefully
 */
export enum KanmonLoanStatusEnum {
  CLOSED = 'CLOSED',
  CURRENT = 'CURRENT',
  DEFAULTED = 'DEFAULTED',
  FULLY_PAID = 'FULLY_PAID',
  LATE = 'LATE',
  REFINANCED = 'REFINANCED'
}

export enum KanmonLoanTypeEnum {
  LINE_OF_CREDIT = 'LINE_OF_CREDIT',
  TERM_LOAN = 'TERM_LOAN'
}

export type LinkTokenData = {
  __typename?: 'LinkTokenData';
  expirationTimestamp: Scalars['String']['output'];
  linkToken: Scalars['String']['output'];
};

export type ListInvoiceAccountsPayableQueryResponse = {
  __typename?: 'ListInvoiceAccountsPayableQueryResponse';
  invoices: Array<InvoiceAccountsPayable>;
  pagination?: Maybe<InvoiceAccountsPayablePagination>;
};

export type ListOwnersQueryResponse = {
  __typename?: 'ListOwnersQueryResponse';
  owners: Array<TeamOwner>;
};

export type ListTeamMembersResponse = {
  __typename?: 'ListTeamMembersResponse';
  teamMembers: Array<TeamMember>;
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Coordinates>;
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  provinceState?: Maybe<Scalars['String']['output']>;
  streetAddressLine1?: Maybe<Scalars['String']['output']>;
  streetAddressLine2?: Maybe<Scalars['String']['output']>;
};

/** We need to use this input when creating or updating locations. */
export type LocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  provinceState?: InputMaybe<Scalars['String']['input']>;
  streetAddressLine1?: InputMaybe<Scalars['String']['input']>;
  streetAddressLine2?: InputMaybe<Scalars['String']['input']>;
};

export type LocationStrictInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  provinceState: Scalars['String']['input'];
  streetAddressLine1: Scalars['String']['input'];
  streetAddressLine2?: InputMaybe<Scalars['String']['input']>;
};

export type LowBalanceInfo = {
  __typename?: 'LowBalanceInfo';
  isBalanceLow: Scalars['Boolean']['output'];
  lowBalanceThreshold: Scalars['Float']['output'];
};

export type MailedCheckCapability = {
  __typename?: 'MailedCheckCapability';
  fee: Fee;
  isEligible: Scalars['Boolean']['output'];
  limits: MailedCheckLimits;
};

export type MailedCheckCreateInput = {
  amount: Scalars['Float']['input'];
  contactId: Scalars['ID']['input'];
  contactNotificationOptions?: InputMaybe<TransactionContactNotificationOptionsInput>;
  idempotencyKey: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
  subAccountId: Scalars['ID']['input'];
};

export type MailedCheckCreateMutationResponse = MutationResponse & {
  __typename?: 'MailedCheckCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MailedCheckLimits = {
  __typename?: 'MailedCheckLimits';
  MAX_PER_TRANSACTION: Scalars['Float']['output'];
};

export enum MailingAddressLocationEnum {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL'
}

export type MonthlyAccountStatements = {
  __typename?: 'MonthlyAccountStatements';
  closingBalance?: Maybe<Scalars['Float']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  openingBalance?: Maybe<Scalars['Float']['output']>;
  totalDeposits?: Maybe<Scalars['Float']['output']>;
  totalFees?: Maybe<Scalars['Float']['output']>;
  totalWithdrawals?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  accountUsageSubmit: AccountUsageSubmitResponse;
  addVirtualCard?: Maybe<AddVirtualCardResponse>;
  applySubscriptionPromoCode: ApplySubscriptionPromoCodeMutationResponse;
  /** @deprecated BATs are not used anymore */
  businessAccessTokenGenerate?: Maybe<BusinessAccessTokenMutationResponse>;
  cancelScheduledPayment?: Maybe<CancelScheduledPaymentResponse>;
  captureInitialFundingAccountType?: Maybe<CaptureInitialFundingAccountTypeMutationResponse>;
  captureInitialFundingAmount?: Maybe<CaptureInitialFundingAmountMutationResponse>;
  cardActivate?: Maybe<CardActivateResponse>;
  cardFreeze?: Maybe<CardFreezeResponse>;
  cardUnfreeze?: Maybe<CardUnfreezeResponse>;
  checkDepositCreate?: Maybe<CheckDepositCreateMutationResponse>;
  checkOnboardingApplicationEligibility: OnboardingApplicationEligibilityCheckResponse;
  confirmAdditionalOwnersOnboardingStatus?: Maybe<ConfirmAdditionalOwnersOnboardingStatusMutationResponse>;
  contactBillPayPaymentDetailsCreate?: Maybe<ContactBillPayPaymentDetailsCreateMutationResponse>;
  contactBillPayPaymentDetailsDelete?: Maybe<ContactBillPayPaymentDetailsDeleteMutationResponse>;
  contactBillPayPaymentDetailsUpdate?: Maybe<ContactBillPayPaymentDetailsUpdateMutationResponse>;
  contactCreate?: Maybe<ContactCreateMutationResponse>;
  contactDelete?: Maybe<ContactDeleteMutationResponse>;
  contactDomesticAchCreate?: Maybe<ContactCreateMutationResponse>;
  contactDomesticAchUpdate?: Maybe<ContactUpdateMutationResponse>;
  contactDomesticWireCreate?: Maybe<ContactCreateMutationResponse>;
  contactDomesticWireUpdate?: Maybe<ContactUpdateMutationResponse>;
  contactMailedCheckCreate?: Maybe<ContactCreateMutationResponse>;
  contactMailedCheckUpdate?: Maybe<ContactUpdateMutationResponse>;
  contactUpdate?: Maybe<ContactUpdateMutationResponse>;
  createInvoiceAccountsPayablePayment?: Maybe<CreateInvoiceAccountsPayablePaymentMutationResponse>;
  createInvoiceAccountsPayableScheduledPayment?: Maybe<CreateInvoiceAccountsPayableScheduledPaymentMutationResponse>;
  createInvoiceAttachment?: Maybe<CreateInvoiceAttachmentMutationResponse>;
  createKanmonConnectToken?: Maybe<KanmonConnectTokenMutationResponse>;
  createLinkToken?: Maybe<CreateLinkTokenMutationResponse>;
  disableTopUps?: Maybe<DisableTopUpsResponse>;
  documentRequestResendEmail: DocumentRequestEmailResendResponse;
  domesticWireCreate?: Maybe<DomesticWireCreateMutationResponse>;
  exchangePublicToken?: Maybe<ExchangePublicTokenMutationResponse>;
  initiatePlaidOneTimeTransfer?: Maybe<InitiatePlaidOneTimeTransferMutationResponse>;
  inviteAdditionalOwner?: Maybe<InviteAdditionalOwnerMutationResponse>;
  mailedCheckCreate?: Maybe<MailedCheckCreateMutationResponse>;
  markInvoiceAccountsPayableAsArchived?: Maybe<UpdateInvoiceAccountsPayableMutationResponse>;
  markInvoiceAccountsPayableAsPaid?: Maybe<UpdateInvoiceAccountsPayableMutationResponse>;
  markInvoiceAccountsPayableAsUnarchived?: Maybe<UpdateInvoiceAccountsPayableMutationResponse>;
  markInvoiceAccountsPayableAsUnpaid?: Maybe<UpdateInvoiceAccountsPayableMutationResponse>;
  onboardingAgreementsTermsAndConditionsAccept: OnboardingAgreementsTermsAndConditionsAcceptMutationResponse;
  onboardingApplicationSubmit: OnboardingApplicationSubmitResponse;
  onboardingMailingAddressUpdate: OnboardingMailingAddressUpdateSubmitResponse;
  performIntercomAPICommand: PerformIntercomApiCommandResponse;
  preconfigureTeamMemberInvitations?: Maybe<PreconfigureTeamMemberInvitationsResponse>;
  preconfigureTopUpsSettings?: Maybe<PreconfigureTopUpsSettingsResponse>;
  pushProvisionCard?: Maybe<PushProvisionCardResponse>;
  redeemReferralOTP?: Maybe<PartnershipReferral>;
  registerPerson?: Maybe<RegisterPersonResponse>;
  registerUser?: Maybe<RegisterUserResponse>;
  registerUserAndTermsAccept?: Maybe<RegisterUserResponse>;
  removePlaidItem?: Maybe<RemovePlaidItemResponse>;
  /** userEmail must be same email that the user signed up with */
  resendVerificationEmail?: Maybe<ConcreteMutationResponse>;
  resetPassword?: Maybe<ConcreteMutationResponse>;
  retryFundingTransaction?: Maybe<RetryFundingTransactionMutationResponse>;
  revenueHubConnectionDelete?: Maybe<RevenueHubConnectionDeleteMutationResponse>;
  revenueHubConnectionSave?: Maybe<RevenueHubConnectionSaveMutationResponse>;
  saveInvoiceAccountsPayable?: Maybe<SaveInvoiceAccountsPayableMutationResponse>;
  shareAndEarnRedeemedOfferMarkAsSeen?: Maybe<ShareAndEarnRedeemedOfferMarkAsSeenMutationResponse>;
  shareAndEarnResendBlockedCustomerEmail?: Maybe<ShareAndEarnResendBlockedCustomerEmailMutationResponse>;
  shareAndEarnUserRegisterAndOfferAccept?: Maybe<RegisterUserResponse>;
  subAccountBatchCreate?: Maybe<SubAccountBatchCreateResponse>;
  subAccountBatchUpdate?: Maybe<SubAccountBatchUpdateResponse>;
  subAccountCreate?: Maybe<SubAccountCreateResponse>;
  subAccountDelete?: Maybe<SubAccountDeleteResponse>;
  subAccountRulePercentCreate?: Maybe<SubAccountRulePercentCreateMutationResponse>;
  subAccountRulePercentDelete?: Maybe<SubAccountRulePercentDeleteMutationResponse>;
  subAccountRulePercentUpdate?: Maybe<SubAccountRulePercentUpdateMutationResponse>;
  subAccountTransferCreate?: Maybe<SubAccountTransferCreateMutationResponse>;
  subAccountUpdate?: Maybe<SubAccountUpdateResponse>;
  subAccountsReconcile?: Maybe<SubAccountsReconcileResponse>;
  subscriptionCancel?: Maybe<SubscriptionCancelMutationResponse>;
  subscriptionReactivate?: Maybe<SubscriptionReactivateMutationResponse>;
  subscriptionUpgrade?: Maybe<SubscriptionUpgradeMutationResponse>;
  supportPhoneCallBookingCreate?: Maybe<SupportPhoneCallBookingMutationResponse>;
  transactionNoteUpdate?: Maybe<TransactionNoteUpdateResponse>;
  transactionsACHCreate?: Maybe<TransactionsAchCreateMutationResponse>;
  updateScheduledPayment?: Maybe<UpdateScheduledPaymentResponse>;
  updateTopUpsSettings?: Maybe<UpdateTopUpsSettingsResponse>;
  updateUser?: Maybe<UpdateUserMutationResponse>;
};


export type MutationAccountUsageSubmitArgs = {
  data: AccountUsageData;
};


export type MutationAddVirtualCardArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
};


export type MutationApplySubscriptionPromoCodeArgs = {
  input: ApplySubscriptionPromoCodeInput;
};


export type MutationBusinessAccessTokenGenerateArgs = {
  businessId: Scalars['ID']['input'];
  clientId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  tokenType: AuthTokenTypeEnum;
};


export type MutationCancelScheduledPaymentArgs = {
  businessId: Scalars['ID']['input'];
  scheduledPaymentId: Scalars['ID']['input'];
};


export type MutationCaptureInitialFundingAccountTypeArgs = {
  accountType: Scalars['String']['input'];
  businessId: Scalars['ID']['input'];
  idempotencyKey: Scalars['String']['input'];
};


export type MutationCaptureInitialFundingAmountArgs = {
  amountInCents: Scalars['Int']['input'];
  businessId: Scalars['ID']['input'];
  businessType?: InputMaybe<BusinessType>;
};


export type MutationCardActivateArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
  expirationDate: Scalars['String']['input'];
  securityCode: Scalars['String']['input'];
};


export type MutationCardFreezeArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCardUnfreezeArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
};


export type MutationCheckDepositCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: CheckDepositCreateInput;
};


export type MutationCheckOnboardingApplicationEligibilityArgs = {
  data: PartialOnboardingApplicationDataWithoutSsn;
};


export type MutationConfirmAdditionalOwnersOnboardingStatusArgs = {
  businessId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationContactBillPayPaymentDetailsCreateArgs = {
  data: ContactBillPayPaymentDetailsCreateInput;
};


export type MutationContactBillPayPaymentDetailsDeleteArgs = {
  data: ContactBillPayPaymentDetailsDeleteInput;
};


export type MutationContactBillPayPaymentDetailsUpdateArgs = {
  data: ContactBillPayPaymentDetailsUpdateInput;
};


export type MutationContactCreateArgs = {
  input: ContactCreateDataInput;
};


export type MutationContactDeleteArgs = {
  input: ContactDeleteInput;
};


export type MutationContactDomesticAchCreateArgs = {
  data: ContactDomesticAchCreateDataInput;
};


export type MutationContactDomesticAchUpdateArgs = {
  data: ContactDomesticAchUpdateDataInput;
};


export type MutationContactDomesticWireCreateArgs = {
  data: ContactDomesticWireCreateDataInput;
};


export type MutationContactDomesticWireUpdateArgs = {
  data: ContactDomesticWireUpdateDataInput;
};


export type MutationContactMailedCheckCreateArgs = {
  data: ContactMailedCheckCreateDataInput;
};


export type MutationContactMailedCheckUpdateArgs = {
  data: ContactMailedCheckUpdateDataInput;
};


export type MutationContactUpdateArgs = {
  input: ContactUpdateDataInput;
};


export type MutationCreateInvoiceAccountsPayablePaymentArgs = {
  businessId: Scalars['ID']['input'];
  data: CreateInvoiceAccountsPayablePaymentDataInput;
  invoiceId: Scalars['ID']['input'];
};


export type MutationCreateInvoiceAccountsPayableScheduledPaymentArgs = {
  businessId: Scalars['ID']['input'];
  data: CreateInvoiceAccountsPayableScheduledPaymentDataInput;
  invoiceId: Scalars['ID']['input'];
};


export type MutationCreateInvoiceAttachmentArgs = {
  attachmentData: CreateInvoiceAttachmentDataInput;
  businessId: Scalars['ID']['input'];
};


export type MutationCreateKanmonConnectTokenArgs = {
  businessId: Scalars['ID']['input'];
};


export type MutationCreateLinkTokenArgs = {
  androidPackageName?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['ID']['input'];
  itemId?: InputMaybe<Scalars['String']['input']>;
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDisableTopUpsArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  data: DisableTopUpsDataInput;
};


export type MutationDocumentRequestResendEmailArgs = {
  data: DocumentRequestEmailResendData;
};


export type MutationDomesticWireCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: DomesticWireCreateInput;
};


export type MutationExchangePublicTokenArgs = {
  accountType?: InputMaybe<PlaidAccountType>;
  businessId: Scalars['ID']['input'];
  identityCheckInputs?: InputMaybe<IdentityCheckInputs>;
  institutionName?: InputMaybe<Scalars['String']['input']>;
  plaidAccountId: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
};


export type MutationInitiatePlaidOneTimeTransferArgs = {
  accountId: Scalars['ID']['input'];
  amountInCents: Scalars['Int']['input'];
  businessId: Scalars['ID']['input'];
  idempotencyKey: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
};


export type MutationInviteAdditionalOwnerArgs = {
  businessId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationMailedCheckCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: MailedCheckCreateInput;
};


export type MutationMarkInvoiceAccountsPayableAsArchivedArgs = {
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
};


export type MutationMarkInvoiceAccountsPayableAsPaidArgs = {
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMarkInvoiceAccountsPayableAsUnarchivedArgs = {
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
};


export type MutationMarkInvoiceAccountsPayableAsUnpaidArgs = {
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
};


export type MutationOnboardingAgreementsTermsAndConditionsAcceptArgs = {
  businessId: Scalars['String']['input'];
  documentReference: Scalars['String']['input'];
};


export type MutationOnboardingApplicationSubmitArgs = {
  data: OnboardingApplicationSubmitData;
};


export type MutationOnboardingMailingAddressUpdateArgs = {
  data: OnboardingMailingAddressUpdateData;
};


export type MutationPerformIntercomApiCommandArgs = {
  data: PerformIntercomApiCommandData;
};


export type MutationPreconfigureTeamMemberInvitationsArgs = {
  businessId: Scalars['ID']['input'];
  emails: Array<Scalars['String']['input']>;
};


export type MutationPreconfigureTopUpsSettingsArgs = {
  businessId: Scalars['ID']['input'];
  data: UpdateTopUpsSettingsDataInput;
};


export type MutationPushProvisionCardArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
  walletProviderDetails: WalletProviderDetailsInput;
};


export type MutationRedeemReferralOtpArgs = {
  otp?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterPersonArgs = {
  businessId: Scalars['String']['input'];
  personId: Scalars['String']['input'];
};


export type MutationRegisterUserArgs = {
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterUserAndTermsAcceptArgs = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  documentReference: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};


export type MutationRemovePlaidItemArgs = {
  businessId: Scalars['ID']['input'];
  itemId: Scalars['String']['input'];
};


export type MutationResendVerificationEmailArgs = {
  reCAPTCHAToken?: InputMaybe<Scalars['String']['input']>;
  userEmail: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationRetryFundingTransactionArgs = {
  amountInCents: Scalars['Int']['input'];
  businessId: Scalars['ID']['input'];
  itemId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRevenueHubConnectionDeleteArgs = {
  businessId: Scalars['ID']['input'];
  connectionId: Scalars['ID']['input'];
};


export type MutationRevenueHubConnectionSaveArgs = {
  input: RevenueHubConnectionSaveInput;
};


export type MutationSaveInvoiceAccountsPayableArgs = {
  businessId: Scalars['ID']['input'];
  invoiceData: InvoiceAccountsPayableDataInput;
};


export type MutationShareAndEarnRedeemedOfferMarkAsSeenArgs = {
  businessId: Scalars['ID']['input'];
  offerId: Scalars['ID']['input'];
  participantType: ShareAndEarnParticipantTypeEnum;
};


export type MutationShareAndEarnResendBlockedCustomerEmailArgs = {
  advocateId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
};


export type MutationShareAndEarnUserRegisterAndOfferAcceptArgs = {
  advocateId: Scalars['ID']['input'];
  advocateSource?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};


export type MutationSubAccountBatchCreateArgs = {
  bankAccountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  data: Array<SubAccountBatchCreateInput>;
  replaceExistingSubAccounts?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSubAccountBatchUpdateArgs = {
  bankAccountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  data: Array<SubAccountBatchUpdateInput>;
};


export type MutationSubAccountCreateArgs = {
  bankAccountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationSubAccountDeleteArgs = {
  businessId: Scalars['ID']['input'];
  subAccountId: Scalars['ID']['input'];
};


export type MutationSubAccountRulePercentCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: SubAccountRulePercentCreateData;
  subAccountId: Scalars['ID']['input'];
};


export type MutationSubAccountRulePercentDeleteArgs = {
  businessId: Scalars['ID']['input'];
  subAccountId: Scalars['ID']['input'];
};


export type MutationSubAccountRulePercentUpdateArgs = {
  businessId: Scalars['ID']['input'];
  data: SubAccountRulePercentUpdateData;
  subAccountId: Scalars['ID']['input'];
};


export type MutationSubAccountTransferCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: SubAccountTransferCreateInput;
};


export type MutationSubAccountUpdateArgs = {
  businessId: Scalars['ID']['input'];
  data: SubAccountUpdateDataInput;
  subAccountId: Scalars['ID']['input'];
};


export type MutationSubAccountsReconcileArgs = {
  businessId: Scalars['ID']['input'];
  data: Array<SubAccountReconcileDataInput>;
};


export type MutationSubscriptionCancelArgs = {
  input: SubscriptionCancelInput;
};


export type MutationSubscriptionReactivateArgs = {
  input: SubscriptionReactivateInput;
};


export type MutationSubscriptionUpgradeArgs = {
  input: SubscriptionUpgradeInput;
};


export type MutationSupportPhoneCallBookingCreateArgs = {
  data: SupportPhoneCallCreateInput;
};


export type MutationTransactionNoteUpdateArgs = {
  businessId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
  transactionId: Scalars['ID']['input'];
};


export type MutationTransactionsAchCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: TransactionsAchCreateInput;
};


export type MutationUpdateScheduledPaymentArgs = {
  businessId: Scalars['ID']['input'];
  scheduledPaymentId: Scalars['ID']['input'];
  updateScheduledPaymentInput: UpdateScheduledPaymentInput;
};


export type MutationUpdateTopUpsSettingsArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  data: UpdateTopUpsSettingsDataInput;
};


export type MutationUpdateUserArgs = {
  businessId: Scalars['ID']['input'];
  userData?: InputMaybe<UserDataInput>;
  userId: Scalars['ID']['input'];
};

export type MutationPayload = {
  userErrors: Array<UserError>;
};

export type MutationResponse = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type NaicsIndustries = {
  __typename?: 'NaicsIndustries';
  defaultIndustry: KybIndustry;
  groupedIndustries: Array<GroupedIndustries>;
};

export enum OnboardingAccountStatus {
  ACCOUNT_USAGE_SURVEY_SUBMITTED = 'ACCOUNT_USAGE_SURVEY_SUBMITTED',
  NEEDS_ACCOUNT_USAGE_SURVEY = 'NEEDS_ACCOUNT_USAGE_SURVEY',
  OPENED = 'OPENED'
}

export type OnboardingAgreements = {
  __typename?: 'OnboardingAgreements';
  termsAndConditions: Agreement;
};

export type OnboardingAgreementsTermsAndConditionsAcceptMutationResponse = MutationResponse & {
  __typename?: 'OnboardingAgreementsTermsAndConditionsAcceptMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type OnboardingApplication = {
  __typename?: 'OnboardingApplication';
  businessAddress?: Maybe<Location>;
  businessId: Scalars['ID']['output'];
  homeAddress?: Maybe<Location>;
  id: Scalars['ID']['output'];
  requiredDocumentUpload?: Maybe<OnboardingRequiredDocumentUpload>;
  status?: Maybe<OnboardingApplicationStatus>;
};

export type OnboardingApplicationBusinessInput = {
  address: LocationStrictInput;
  annualRevenue: Scalars['Float']['input'];
  businessType: BusinessType;
  dba?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  ein?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  legalName: Scalars['String']['input'];
  naicsCode: Scalars['String']['input'];
  numberOfEmployees: Scalars['Int']['input'];
  phone: Scalars['String']['input'];
  typeOfProductsOrServices: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
  yearsAtLocation: Scalars['Int']['input'];
};

export type OnboardingApplicationEligibility = {
  __typename?: 'OnboardingApplicationEligibility';
  isEligible: Scalars['Boolean']['output'];
};

export type OnboardingApplicationEligibilityCheckResponse = MutationResponse & {
  __typename?: 'OnboardingApplicationEligibilityCheckResponse';
  code: Scalars['String']['output'];
  data: OnboardingApplicationEligibility;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type OnboardingApplicationOwnerInput = {
  address: LocationStrictInput;
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  isControlPerson: Scalars['Boolean']['input'];
  isPrimaryOwner: Scalars['Boolean']['input'];
  jobTitle: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  ownershipPercentage: Scalars['Float']['input'];
  phone: Scalars['String']['input'];
  ssn: Scalars['String']['input'];
};

export enum OnboardingApplicationStatus {
  APPROVED = 'APPROVED',
  REFUSED = 'REFUSED',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
  SUBMITTED = 'SUBMITTED'
}

export type OnboardingApplicationSubmitData = {
  business: OnboardingApplicationBusinessInput;
  owners: Array<OnboardingApplicationOwnerInput>;
  socureDeviceSessionId?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingApplicationSubmitResponse = MutationResponse & {
  __typename?: 'OnboardingApplicationSubmitResponse';
  application: OnboardingApplication;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type OnboardingMailingAddressUpdateData = {
  address: LocationStrictInput;
  businessId: Scalars['ID']['input'];
};

export type OnboardingMailingAddressUpdateSubmitResponse = MutationResponse & {
  __typename?: 'OnboardingMailingAddressUpdateSubmitResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type OnboardingRequiredDocumentUpload = {
  __typename?: 'OnboardingRequiredDocumentUpload';
  requiredDocuments: Array<RequiredDocumentEnum>;
};

export enum OperatingSystemEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export type OutgoingWireCapability = {
  __typename?: 'OutgoingWireCapability';
  fee: Fee;
  isEligible: Scalars['Boolean']['output'];
  limits: TransferLimits;
};

export type OwnerCandidate = {
  __typename?: 'OwnerCandidate';
  address?: Maybe<PersonAddress>;
  businessId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  ownershipPercentage: Scalars['Float']['output'];
  shippingAddress?: Maybe<PersonAddress>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type PartialOnboardingApplicationBusinessInput = {
  address?: InputMaybe<LocationStrictInput>;
  annualRevenue?: InputMaybe<Scalars['Float']['input']>;
  businessType?: InputMaybe<BusinessType>;
  dba?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  legalName?: InputMaybe<Scalars['String']['input']>;
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  typeOfProductsOrServices?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  yearsAtLocation?: InputMaybe<Scalars['Int']['input']>;
};

export type PartialOnboardingApplicationDataWithoutSsn = {
  business: PartialOnboardingApplicationBusinessInput;
  persons?: InputMaybe<Array<PartialOnboardingApplicationOwnerInputWithoutSsn>>;
  socureDeviceSessionId?: InputMaybe<Scalars['String']['input']>;
};

export type PartialOnboardingApplicationOwnerInputWithoutSsn = {
  address?: InputMaybe<LocationStrictInput>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isControlPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimaryOwner?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ownershipPercentage?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type PartnershipApplication = {
  __typename?: 'PartnershipApplication';
  business?: Maybe<ApplicantBusiness>;
  id?: Maybe<Scalars['String']['output']>;
  persons?: Maybe<Array<Maybe<Person>>>;
};

export type PartnershipReferral = {
  __typename?: 'PartnershipReferral';
  application?: Maybe<PartnershipApplication>;
};

export type PercentFee = {
  __typename?: 'PercentFee';
  maximum: Scalars['Float']['output'];
  minimum: Scalars['Float']['output'];
  percentage: Scalars['Float']['output'];
  type: FeeType;
};

export type PercentFeeDetails = {
  __typename?: 'PercentFeeDetails';
  feeType: FeeType;
  maximumAmount: Scalars['Float']['output'];
  minimumAmount: Scalars['Float']['output'];
  percent: Scalars['Float']['output'];
};

export type PerformIntercomApiCommandData = {
  body: Scalars['JSON']['input'];
  businessId: Scalars['ID']['input'];
  command: IntercomApiCommandEnum;
  conversationId?: InputMaybe<Scalars['String']['input']>;
};

export type PerformIntercomApiCommandResponse = MutationResponse & {
  __typename?: 'PerformIntercomAPICommandResponse';
  code: Scalars['String']['output'];
  data: Scalars['JSON']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Person = {
  __typename?: 'Person';
  address?: Maybe<Address>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  ownershipPercentage?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PersonAddress = {
  __typename?: 'PersonAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export enum PlaidAccountType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL'
}

export type PlaidAccounts = {
  __typename?: 'PlaidAccounts';
  accounts?: Maybe<Array<PlaidAccountsData>>;
};

export type PlaidAccountsData = {
  __typename?: 'PlaidAccountsData';
  account_id?: Maybe<Scalars['String']['output']>;
  balances?: Maybe<PlaidBalances>;
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  official_name?: Maybe<Scalars['String']['output']>;
  owners?: Maybe<Array<Maybe<PlaidOwners>>>;
  subtype?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  verification_status?: Maybe<Scalars['String']['output']>;
};

export type PlaidAuthData = {
  __typename?: 'PlaidAuthData';
  accounts?: Maybe<Array<PlaidAccountsData>>;
  item?: Maybe<PlaidItemData>;
};

export enum PlaidBalanceErrorEnum {
  /** The Plaid item has had auth revoked and will be deactived */
  AUTH_EXPIRED = 'AUTH_EXPIRED',
  /** The Plaid item does not support returning available balance and is therefore invalid and cannot be used for transfers */
  INVALID_ITEM = 'INVALID_ITEM'
}

export type PlaidBalances = {
  __typename?: 'PlaidBalances';
  /** The amount of funds available to be withdrawn from the account, as determined by the financial institution. */
  available?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Do not use. Value is not being resolved. It is not in use, unclear how this value can be used, and does not have a current purpose in our frontends */
  current?: Maybe<Scalars['Float']['output']>;
  /** Error enum returned if balance indicates an issue with the Plaid item (eg auth expired, invalid due to null balance etc.) */
  errorCode?: Maybe<PlaidBalanceErrorEnum>;
  /** @deprecated Do not use. Value is not being resolved. it is not in use and is not useful in it's current format. If we want to return currency code here, we should use the 'CurrencyCodeEnum' and format the value in camelCase */
  iso_currency_code?: Maybe<Scalars['String']['output']>;
};

export type PlaidFundingCapabilitiesQueryResponse = {
  __typename?: 'PlaidFundingCapabilitiesQueryResponse';
  initialFunding: InitialFundingCapability;
  items: PlaidItemsCapability;
  topUps: TopUpsCapability;
};

export enum PlaidFundingStatusEnum {
  FUNDING_ATTEMPT_INITIATED = 'FUNDING_ATTEMPT_INITIATED',
  FUNDING_BLOCKED = 'FUNDING_BLOCKED',
  MICRODEPOSITS_VERIFICATION_FAILED = 'MICRODEPOSITS_VERIFICATION_FAILED',
  MICRODEPOSITS_VERIFICATION_INITIATED = 'MICRODEPOSITS_VERIFICATION_INITIATED',
  MICRODEPOSITS_VERIFIED = 'MICRODEPOSITS_VERIFIED',
  PLAID_ITEM_DEACTIVATED = 'PLAID_ITEM_DEACTIVATED',
  PLAID_NAME_MISMATCH = 'PLAID_NAME_MISMATCH',
  TRANSACTION_FAILED = 'TRANSACTION_FAILED',
  TRANSACTION_INITIATED = 'TRANSACTION_INITIATED',
  TRANSACTION_NON_SUFFICIENT_BALANCE = 'TRANSACTION_NON_SUFFICIENT_BALANCE',
  TRANSACTION_POSTED = 'TRANSACTION_POSTED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export type PlaidInstitution = {
  __typename?: 'PlaidInstitution';
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type PlaidItem = {
  __typename?: 'PlaidItem';
  /** Plaid specific accountId */
  accountId?: Maybe<Scalars['String']['output']>;
  /** Balance data object, including available balance and any balance errors */
  balance: PlaidBalances;
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  institution?: Maybe<PlaidInstitution>;
  maskedAccountNumber?: Maybe<Scalars['String']['output']>;
  status: PlaidItemStatus;
};

export type PlaidItemData = {
  __typename?: 'PlaidItemData';
  item_id?: Maybe<Scalars['String']['output']>;
};

export type PlaidItemIdAndStatus = {
  __typename?: 'PlaidItemIdAndStatus';
  id: Scalars['ID']['output'];
  status: PlaidItemStatus;
};

export enum PlaidItemStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export type PlaidItemsCapability = {
  __typename?: 'PlaidItemsCapability';
  maximumItemCount: Scalars['Int']['output'];
};

export type PlaidItemsQueryResponse = {
  __typename?: 'PlaidItemsQueryResponse';
  data: Array<PlaidItem>;
};

export type PlaidOwners = {
  __typename?: 'PlaidOwners';
  addresses?: Maybe<Array<Maybe<PlaidOwnersAddresses>>>;
  emails?: Maybe<Array<Maybe<PlaidOwnersEmails>>>;
  names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phone_numbers?: Maybe<Array<Maybe<PlaidOwnersPhoneNumbers>>>;
};

export type PlaidOwnersAddress = {
  __typename?: 'PlaidOwnersAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type PlaidOwnersAddresses = {
  __typename?: 'PlaidOwnersAddresses';
  data?: Maybe<PlaidOwnersAddress>;
  primary: Scalars['Boolean']['output'];
};

export type PlaidOwnersEmails = {
  __typename?: 'PlaidOwnersEmails';
  data: Scalars['String']['output'];
  primary: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type PlaidOwnersPhoneNumbers = {
  __typename?: 'PlaidOwnersPhoneNumbers';
  data: Scalars['String']['output'];
  primary: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export enum PlaidTopUpsErrorCodeEnum {
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  PLAID_ITEM_ERROR = 'PLAID_ITEM_ERROR',
  PLAID_ITEM_EXPIRED = 'PLAID_ITEM_EXPIRED',
  UNKNOWN = 'UNKNOWN',
  VELOCITY_LIMIT_30D = 'VELOCITY_LIMIT_30D'
}

export type PreconfigureTeamMemberInvitationsResponse = MutationResponse & {
  __typename?: 'PreconfigureTeamMemberInvitationsResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type PreconfigureTopUpsSettingsResponse = MutationResponse & {
  __typename?: 'PreconfigureTopUpsSettingsResponse';
  code: Scalars['String']['output'];
  data: PreconfiguredTopUpSettings;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type PreconfiguredTopUpSettings = {
  __typename?: 'PreconfiguredTopUpSettings';
  lowBalanceThreshold: Scalars['Float']['output'];
  plaidItem: PlaidItemIdAndStatus;
  topUpAmount: Scalars['Float']['output'];
};

export type PromoCodeDetails = {
  __typename?: 'PromoCodeDetails';
  promoCode: SubscriptionPromoCode;
};

export type PushProvisionAppleCard = {
  __typename?: 'PushProvisionAppleCard';
  activationData: Scalars['String']['output'];
  encryptedPassData: Scalars['String']['output'];
  ephemeralPublicKey: Scalars['String']['output'];
  walletProvider: WalletProviderEnum;
};

export type PushProvisionCardData = PushProvisionAppleCard | PushProvisionGoogleCard | PushProvisionSamsungCard;

export type PushProvisionCardResponse = MutationResponse & {
  __typename?: 'PushProvisionCardResponse';
  cardData?: Maybe<PushProvisionCardData>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type PushProvisionGoogleCard = {
  __typename?: 'PushProvisionGoogleCard';
  opaquePaymentCard: Scalars['String']['output'];
  walletProvider: WalletProviderEnum;
};

export type PushProvisionSamsungCard = {
  __typename?: 'PushProvisionSamsungCard';
  payload: Scalars['String']['output'];
  walletProvider: WalletProviderEnum;
};

export type Query = {
  __typename?: 'Query';
  acceptedAgreements?: Maybe<Array<Maybe<AcceptedAgreement>>>;
  achCutoffTimes?: Maybe<AchCutoffTimesResponse>;
  agreementsPDF: AgreementsPdf;
  business: Business;
  cardPinChangeForm?: Maybe<CardPinChangeForm>;
  checkDepositTransactionsList?: Maybe<TransactionsListResponse>;
  digitalCardAssetUrl?: Maybe<DigitalCardAssetUrl>;
  getFundingAmounts?: Maybe<GetFundingAmountsQueryResponse>;
  getFundingStatus?: Maybe<GetFundingStatusQueryResponse>;
  getInvoiceAccountsPayable?: Maybe<GetInvoiceAccountsPayableQueryResponse>;
  getInvoiceAttachment?: Maybe<GetInvoiceAttachmentResponse>;
  getPlaidAuthData?: Maybe<GetPlaidAuthDataQueryResponse>;
  getPlaidBalance?: Maybe<GetBalanceQueryResponse>;
  getPlaidIdentity?: Maybe<GetIdentityQueryResponse>;
  getTopUpsSettings?: Maybe<GetTopUpsSettingsQueryResponse>;
  isPromoCodeValid: Scalars['Boolean']['output'];
  kanmonLending: KanmonLending;
  listInvoiceAccountsPayable?: Maybe<ListInvoiceAccountsPayableQueryResponse>;
  /** @deprecated use business.teamMembers */
  listOwners?: Maybe<ListOwnersQueryResponse>;
  me?: Maybe<User>;
  naics?: Maybe<NaicsIndustries>;
  /**
   * ** deprecated ** use termsAndConditionsPDF instead
   * @deprecated use termsAndConditionsPDF instead
   */
  onboardingAgreements?: Maybe<OnboardingAgreements>;
  plaidFundingCapabilities?: Maybe<PlaidFundingCapabilitiesQueryResponse>;
  plaidItems?: Maybe<PlaidItemsQueryResponse>;
  promoCodeDetails: PromoCodeDetails;
  releaseVersion?: Maybe<Scalars['String']['output']>;
  requiredDocuments: RequiredDocumentsResponse;
  revenueHub: RevenueHub;
  routingNumberACHIsValid?: Maybe<Scalars['Boolean']['output']>;
  routingNumberWireIsValid?: Maybe<Scalars['Boolean']['output']>;
  searchBillerDirectory?: Maybe<SearchBillerDirectoryResponse>;
  shareAndEarnMicrosite?: Maybe<ShareAndEarnMicrosite>;
  subAccountRuleTotalPercentAvailable?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<Subscription>;
  subscriptionPlans?: Maybe<SubscriptionPlans>;
  supportHelpCenter: SupportHelpCenterService;
  supportPhoneCall: SupportPhoneCallService;
  termsAndConditionsPDF?: Maybe<TermsAndConditionsPdf>;
  timeSaved?: Maybe<TimeSavedResponse>;
  topUpsAmounts?: Maybe<TopUpsAmountsQueryResponse>;
  transaction?: Maybe<TransactionListItem>;
  transactionFees?: Maybe<TransactionFeesResponse>;
  transactionSearchCSV?: Maybe<TransactionSearchCsvResponse>;
  transactionUpsellContent?: Maybe<TransactionUpsellContent>;
  transactionUpsellContentPerType?: Maybe<TransactionUpsellContentPerType>;
  transactionsAggregate?: Maybe<TransactionsAggregateResponse>;
  transactionsList?: Maybe<TransactionsListResponse>;
  verifyBusinessNameIsValid?: Maybe<Scalars['Boolean']['output']>;
};


export type QueryAcceptedAgreementsArgs = {
  businessId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryAchCutoffTimesArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryAgreementsPdfArgs = {
  type: AgreementType;
};


export type QueryBusinessArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryCardPinChangeFormArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
};


export type QueryCheckDepositTransactionsListArgs = {
  businessId: Scalars['ID']['input'];
  filters?: InputMaybe<TransactionsListFiltersInput>;
  pagination?: InputMaybe<TransactionsPaginationOptionsInput>;
};


export type QueryDigitalCardAssetUrlArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
};


export type QueryGetFundingAmountsArgs = {
  businessId: Scalars['ID']['input'];
  businessType?: InputMaybe<BusinessType>;
};


export type QueryGetFundingStatusArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryGetInvoiceAccountsPayableArgs = {
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
};


export type QueryGetInvoiceAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
};


export type QueryGetPlaidAuthDataArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryGetPlaidBalanceArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryGetPlaidIdentityArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryGetTopUpsSettingsArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
};


export type QueryIsPromoCodeValidArgs = {
  businessId: Scalars['ID']['input'];
  promoCode: Scalars['String']['input'];
};


export type QueryKanmonLendingArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryListInvoiceAccountsPayableArgs = {
  businessId: Scalars['ID']['input'];
  filters?: InputMaybe<InvoiceAccountsPayableListFiltersInput>;
  pagination?: InputMaybe<InvoiceAccountsPayableListPaginationOptionsInput>;
  sorting: InvoiceAccountsPayableListSortingInput;
};


export type QueryListOwnersArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryPlaidFundingCapabilitiesArgs = {
  businessId: Scalars['ID']['input'];
  businessType?: InputMaybe<BusinessType>;
};


export type QueryPlaidItemsArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryPromoCodeDetailsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>;
  promoCode: Scalars['String']['input'];
};


export type QueryRequiredDocumentsArgs = {
  applicationId: Scalars['String']['input'];
  businessId: Scalars['String']['input'];
};


export type QueryRevenueHubArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryRoutingNumberAchIsValidArgs = {
  routingNumber: Scalars['String']['input'];
};


export type QueryRoutingNumberWireIsValidArgs = {
  routingNumber: Scalars['String']['input'];
};


export type QuerySearchBillerDirectoryArgs = {
  billerName: Scalars['String']['input'];
};


export type QuerySubAccountRuleTotalPercentAvailableArgs = {
  businessId: Scalars['ID']['input'];
};


export type QuerySubscriptionArgs = {
  businessId: Scalars['ID']['input'];
};


export type QuerySubscriptionPlansArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryTimeSavedArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryTopUpsAmountsArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryTransactionArgs = {
  businessId: Scalars['ID']['input'];
  transactionId: Scalars['ID']['input'];
};


export type QueryTransactionFeesArgs = {
  amount: Scalars['Float']['input'];
  businessId: Scalars['ID']['input'];
};


export type QueryTransactionSearchCsvArgs = {
  businessId: Scalars['ID']['input'];
  csvFormat?: InputMaybe<CsvFormatEnum>;
  filters?: InputMaybe<TransactionsListFiltersInput>;
};


export type QueryTransactionUpsellContentArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryTransactionUpsellContentPerTypeArgs = {
  businessId: Scalars['ID']['input'];
  transactionType: SubscriptionUpsellTransactionType;
};


export type QueryTransactionsAggregateArgs = {
  businessId: Scalars['ID']['input'];
  filters: TransactionsAggregateFiltersInput;
};


export type QueryTransactionsListArgs = {
  businessId: Scalars['ID']['input'];
  filters?: InputMaybe<TransactionsListFiltersInput>;
  pagination?: InputMaybe<TransactionsPaginationOptionsInput>;
};


export type QueryVerifyBusinessNameIsValidArgs = {
  businessType?: InputMaybe<BusinessType>;
  name: Scalars['String']['input'];
};

export type RegisterPersonData = {
  __typename?: 'RegisterPersonData';
  businessId: Scalars['String']['output'];
  personId: Scalars['String']['output'];
};

export type RegisterPersonResponse = MutationResponse & {
  __typename?: 'RegisterPersonResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user?: Maybe<RegisteredPerson>;
};

export type RegisterUserData = {
  __typename?: 'RegisterUserData';
  businessId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type RegisterUserResponse = MutationResponse & {
  __typename?: 'RegisterUserResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user?: Maybe<RegisterUserData>;
};

export type RegisteredPerson = {
  __typename?: 'RegisteredPerson';
  auth0Id?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type RemovePlaidItemResponse = MutationResponse & {
  __typename?: 'RemovePlaidItemResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum RequiredDocumentEnum {
  DBA_DOC = 'DBA_DOC',
  GOVERNMENT_ID = 'GOVERNMENT_ID',
  INCORPORATION_DOC = 'INCORPORATION_DOC',
  SELFIE = 'SELFIE'
}

export type RequiredDocumentsCollection = {
  __typename?: 'RequiredDocumentsCollection';
  customerId: Scalars['Boolean']['output'];
  dba: Scalars['Boolean']['output'];
  incorporation: Scalars['Boolean']['output'];
};

export type RequiredDocumentsResponse = {
  __typename?: 'RequiredDocumentsResponse';
  applicationId: Scalars['String']['output'];
  businessId: Scalars['String']['output'];
  documents: RequiredDocumentsCollection;
};

export type RetryFundingTransactionMutationResponse = MutationResponse & {
  __typename?: 'RetryFundingTransactionMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RevenueHub = {
  __typename?: 'RevenueHub';
  business: Business;
  connection: RevenueHubConnection;
  connections: Array<RevenueHubConnection>;
};


export type RevenueHubConnectionArgs = {
  id: Scalars['ID']['input'];
};

export type RevenueHubConnection = {
  __typename?: 'RevenueHubConnection';
  business: Business;
  /** Rutter connectionId */
  id: Scalars['ID']['output'];
  /**
   * URL that can be used to re-authenticate a connection. This URL launches the Rutter Link web app .
   * For more info, see: https://docs.rutterapi.com/connections/connection-url
   */
  linkUpdateUrl: Scalars['String']['output'];
  platform: RevenueHubConnectionPlatformEnum;
  revenueDetails?: Maybe<RevenueHubConnectionDetails>;
  status: RevenueHubConnectionStatusEnum;
};


export type RevenueHubConnectionRevenueDetailsArgs = {
  filters: RevenueHubConnectionFiltersInput;
};

export type RevenueHubConnectionDateTimeFilterInput = {
  /** dateTime in ISO 8601 format */
  endDateTime: Scalars['String']['input'];
  /** dateTime in ISO 8601 format */
  startDateTime: Scalars['String']['input'];
};

export type RevenueHubConnectionDeleteMutationResponse = MutationResponse & {
  __typename?: 'RevenueHubConnectionDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RevenueHubConnectionDetails = {
  __typename?: 'RevenueHubConnectionDetails';
  /** Current balance pulled for the platform. Filters have no impact on this value. */
  currentBalance?: Maybe<Scalars['Float']['output']>;
  /** Funds earned per day in the provided time period */
  grossRevenuePerDayTimeSeries: Array<RevenueHubConnectionDetailsRevenuePerDay>;
  /** Total funds earned for the provided time period */
  totalGrossRevenue: Scalars['Float']['output'];
  /** Total funds that have been transferred out for the provided time period */
  totalPaidOutAmount?: Maybe<Scalars['Float']['output']>;
  /** Total amount of returns made by customers for the provided time period */
  totalReturnsAmount: Scalars['Float']['output'];
  /** Amount of sales made for the provided time period */
  transactionsCount: Scalars['Int']['output'];
};

export type RevenueHubConnectionDetailsRevenuePerDay = {
  __typename?: 'RevenueHubConnectionDetailsRevenuePerDay';
  /** Date in ISO 8601 format. eg. 2023-05-01 */
  date: Scalars['String']['output'];
  totalRevenueAmount: Scalars['Float']['output'];
};

export type RevenueHubConnectionFiltersInput = {
  /** Represents the time period that the fetched data will reflect (excluding currentBalance) */
  dateTimeFilter: RevenueHubConnectionDateTimeFilterInput;
};

export enum RevenueHubConnectionPlatformEnum {
  AMAZON = 'AMAZON',
  ETSY = 'ETSY',
  PAYPAL = 'PAYPAL',
  SHOPIFY = 'SHOPIFY',
  SQUARE = 'SQUARE',
  STRIPE = 'STRIPE'
}

export type RevenueHubConnectionSaveInput = {
  businessId: Scalars['ID']['input'];
  platform: RevenueHubConnectionPlatformEnum;
  /**
   * Once the Rutter Link flow is complete, the publicToken, which represents a
   * succesful authentication, will be passed to the Rutter Link onSuccess callback
   */
  publicToken: Scalars['String']['input'];
};

export type RevenueHubConnectionSaveMutationResponse = MutationResponse & {
  __typename?: 'RevenueHubConnectionSaveMutationResponse';
  code: Scalars['String']['output'];
  connection: RevenueHubConnection;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum RevenueHubConnectionStatusEnum {
  /** Connection is active and data can be fetched from Rutter */
  ACTIVE = 'ACTIVE',
  /**
   * Returned when a request has been made for a connection that has been temporarily disabled.
   * See disabled_reason in API docs for possible causes of this status.
   * Docs: https://docs.rutterapi.com/rest/2023-03-14/connections#fetch-a-connection-status
   */
  DISABLED = 'DISABLED',
  /**
   * Returned when a request has been made for a connection that
   * needs re-authentication. The connection link url can be used to
   * re-authenticate the connection.
   */
  NEEDS_REAUTH = 'NEEDS_REAUTH',
  /** Returned when a request has been made for data that is not yet ready. */
  NOT_READY = 'NOT_READY'
}

export type RppsBillerDetails = {
  __typename?: 'RppsBillerDetails';
  billerName: Scalars['String']['output'];
  rppsBillerId: Scalars['String']['output'];
};

export type SameDayAchCapability = {
  __typename?: 'SameDayAchCapability';
  fee: Fee;
  isEligible: Scalars['Boolean']['output'];
  limits: TransferLimits;
};

export type SaveInvoiceAccountsPayableMutationResponse = MutationResponse & {
  __typename?: 'SaveInvoiceAccountsPayableMutationResponse';
  code: Scalars['String']['output'];
  invoice: InvoiceAccountsPayable;
  /** @deprecated use invoice.id instead */
  invoiceId: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum ScheduleFrequencyEnum {
  EVERY_DAY = 'EVERY_DAY',
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_TWO_WEEKS = 'EVERY_TWO_WEEKS',
  EVERY_WEEK = 'EVERY_WEEK',
  ONCE = 'ONCE'
}

export type SearchBillerDirectoryResponse = {
  __typename?: 'SearchBillerDirectoryResponse';
  billers: Array<Biller>;
};

export type ShareAndEarn = {
  __typename?: 'ShareAndEarn';
  /** A user acting as an advocate can have multiple offers */
  advocate?: Maybe<ShareAndEarnAdvocate>;
  businessId: Scalars['ID']['output'];
  copyJson?: Maybe<Scalars['String']['output']>;
  /** as a user can you can only be a referral once */
  referral?: Maybe<ShareAndEarnReferral>;
};


export type ShareAndEarnCopyJsonArgs = {
  section?: InputMaybe<ShareAndEarnCopySectionEnum>;
};

export type ShareAndEarnAdvocate = {
  __typename?: 'ShareAndEarnAdvocate';
  hasUnseenRedeemedOffer: Scalars['Boolean']['output'];
  isBlocked: Scalars['Boolean']['output'];
  isEligible: Scalars['Boolean']['output'];
  offerUrl: Scalars['String']['output'];
  totalRedeemedAmount: Scalars['Float']['output'];
  totalRedeemedOffers: Scalars['Int']['output'];
};

export enum ShareAndEarnCopySectionEnum {
  ADVOCATE_OFFER_REDEEMED_SPLASH_SCREEN = 'ADVOCATE_OFFER_REDEEMED_SPLASH_SCREEN',
  HOME = 'HOME',
  REFERRAL_OFFER_ACCEPTED_SPLASH_SCREEN = 'REFERRAL_OFFER_ACCEPTED_SPLASH_SCREEN',
  REFERRAL_OFFER_REDEEMED_SPLASH_SCREEN = 'REFERRAL_OFFER_REDEEMED_SPLASH_SCREEN',
  SHARE_AND_EARN_HUB = 'SHARE_AND_EARN_HUB'
}

export type ShareAndEarnMicrosite = {
  __typename?: 'ShareAndEarnMicrosite';
  advocate?: Maybe<ShareAndEarnMicrositeAdvocate>;
  copyJson?: Maybe<Scalars['String']['output']>;
};


export type ShareAndEarnMicrositeAdvocateArgs = {
  advocateId: Scalars['ID']['input'];
};

export type ShareAndEarnMicrositeAdvocate = {
  __typename?: 'ShareAndEarnMicrositeAdvocate';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isValid: Scalars['Boolean']['output'];
};

export enum ShareAndEarnParticipantTypeEnum {
  ADVOCATE = 'ADVOCATE',
  REFERRAL = 'REFERRAL'
}

export type ShareAndEarnRedeemedOfferMarkAsSeenMutationResponse = MutationResponse & {
  __typename?: 'ShareAndEarnRedeemedOfferMarkAsSeenMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ShareAndEarnReferral = {
  __typename?: 'ShareAndEarnReferral';
  offerId: Scalars['String']['output'];
  offerStatus: ShareAndEarnReferralOfferStatusEnum;
};

export enum ShareAndEarnReferralOfferStatusEnum {
  ACCEPTED = 'ACCEPTED',
  REDEEMED = 'REDEEMED',
  SEEN_REDEEMED_SPLASH_SCREEN = 'SEEN_REDEEMED_SPLASH_SCREEN'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SortKey {
  CREATEDDATE = 'createdDate',
  DUEDATE = 'dueDate',
  INVOICEDATE = 'invoiceDate',
  UPDATEDDATE = 'updatedDate'
}

export type SubAccount = {
  __typename?: 'SubAccount';
  balance: Scalars['Float']['output'];
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  percentRule?: Maybe<SubAccountRulePercent>;
};

export type SubAccountBatchCreateInput = {
  initialAmount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  percentToTransfer?: InputMaybe<Scalars['Int']['input']>;
};

export type SubAccountBatchCreateResponse = MutationResponse & {
  __typename?: 'SubAccountBatchCreateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountBatchUpdateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  newPercentRule?: InputMaybe<Scalars['Int']['input']>;
};

export type SubAccountBatchUpdateResponse = MutationResponse & {
  __typename?: 'SubAccountBatchUpdateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountCreateResponse = MutationResponse & {
  __typename?: 'SubAccountCreateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subAccount?: Maybe<SubAccount>;
  success: Scalars['Boolean']['output'];
};

export type SubAccountDeleteResponse = MutationResponse & {
  __typename?: 'SubAccountDeleteResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountReconcileDataInput = {
  amount: Scalars['Float']['input'];
  subAccountId: Scalars['ID']['input'];
};

export type SubAccountRulePercent = {
  __typename?: 'SubAccountRulePercent';
  availableUpdateLimit?: Maybe<Scalars['Int']['output']>;
  businessId: Scalars['ID']['output'];
  percent?: Maybe<Scalars['Int']['output']>;
};

export type SubAccountRulePercentCreateData = {
  percent: Scalars['Int']['input'];
};

export type SubAccountRulePercentCreateMutationResponse = {
  __typename?: 'SubAccountRulePercentCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subAccountRulePercent?: Maybe<SubAccountRulePercent>;
  success: Scalars['Boolean']['output'];
};

export type SubAccountRulePercentDeleteMutationResponse = {
  __typename?: 'SubAccountRulePercentDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountRulePercentUpdateData = {
  percent?: InputMaybe<Scalars['Int']['input']>;
};

export type SubAccountRulePercentUpdateMutationResponse = {
  __typename?: 'SubAccountRulePercentUpdateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subAccountRulePercent?: Maybe<SubAccountRulePercent>;
  success: Scalars['Boolean']['output'];
};

export type SubAccountTransferCreateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  destinationSubAccountId: Scalars['ID']['input'];
  sourceSubAccountId: Scalars['ID']['input'];
};

export type SubAccountTransferCreateMutationResponse = MutationResponse & {
  __typename?: 'SubAccountTransferCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountUpdateDataInput = {
  name: Scalars['String']['input'];
  percent?: InputMaybe<Scalars['Int']['input']>;
};

export type SubAccountUpdateResponse = MutationResponse & {
  __typename?: 'SubAccountUpdateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subAccount?: Maybe<SubAccount>;
  success: Scalars['Boolean']['output'];
};

export type SubAccountsReconcileResponse = MutationResponse & {
  __typename?: 'SubAccountsReconcileResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  accountId?: Maybe<Scalars['ID']['output']>;
  annualPercentageYield: Scalars['Float']['output'];
  appliedUnclaimedPromoCode?: Maybe<SubscriptionPromoCode>;
  isLifetime?: Maybe<Scalars['Boolean']['output']>;
  isNsfFreeTrialActive?: Maybe<Scalars['Boolean']['output']>;
  isPromoCodeTrialActive?: Maybe<Scalars['Boolean']['output']>;
  /**
   * ISO Date string
   * @example 2023-10-06
   */
  nextBillingDate?: Maybe<Scalars['String']['output']>;
  paymentPeriod?: Maybe<SubscriptionPlanPricePeriod>;
  plan: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  /**
   * Full DateTime string
   * @example 2023-10-06T13:00:00.000Z
   */
  startDate?: Maybe<Scalars['String']['output']>;
  status: SubscriptionStatus;
};

export type SubscriptionCancelInput = {
  businessId: Scalars['ID']['input'];
};

export type SubscriptionCancelMutationResponse = MutationResponse & {
  __typename?: 'SubscriptionCancelMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription: Subscription;
  success: Scalars['Boolean']['output'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  content: SubscriptionPlanContent;
  isCurrentPlan?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type SubscriptionPlanContent = {
  __typename?: 'SubscriptionPlanContent';
  description?: Maybe<Array<Scalars['String']['output']>>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Array<SubscriptionPlanContentPrice>>;
  retentionFeatures?: Maybe<Array<SubscriptionPlanContentSectionBullet>>;
  sections?: Maybe<Array<SubscriptionPlanContentSection>>;
  shortTitle?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  upgrade?: Maybe<SubscriptionPlanContentUpgrade>;
  upgradeButtonText?: Maybe<Scalars['String']['output']>;
  upgradeNsfTrial?: Maybe<SubscriptionPlanContentUpgrade>;
  upsell?: Maybe<SubscriptionPlanContentUpsell>;
};

export type SubscriptionPlanContentPrice = {
  __typename?: 'SubscriptionPlanContentPrice';
  period?: Maybe<SubscriptionPlanPricePeriod>;
  price?: Maybe<Scalars['Float']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanContentSection = {
  __typename?: 'SubscriptionPlanContentSection';
  bullets?: Maybe<Array<SubscriptionPlanContentSectionBullet>>;
  heading?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanContentSectionBullet = {
  __typename?: 'SubscriptionPlanContentSectionBullet';
  icon?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanContentUpgrade = {
  __typename?: 'SubscriptionPlanContentUpgrade';
  successSubtitle: Scalars['String']['output'];
  successTitle: Scalars['String']['output'];
};

export type SubscriptionPlanContentUpsell = {
  __typename?: 'SubscriptionPlanContentUpsell';
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum SubscriptionPlanPricePeriod {
  MONTHLY = 'MONTHLY'
}

export type SubscriptionPlans = {
  __typename?: 'SubscriptionPlans';
  footer?: Maybe<Array<Scalars['String']['output']>>;
  plans?: Maybe<Array<SubscriptionPlan>>;
};

export type SubscriptionPromoCode = {
  __typename?: 'SubscriptionPromoCode';
  durationInDays?: Maybe<Scalars['Int']['output']>;
  durationText: Scalars['String']['output'];
  expiryDate?: Maybe<Scalars['String']['output']>;
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  isLifetime?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  plan: Scalars['String']['output'];
};

export type SubscriptionReactivateInput = {
  businessId: Scalars['ID']['input'];
};

export type SubscriptionReactivateMutationResponse = MutationResponse & {
  __typename?: 'SubscriptionReactivateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription: Subscription;
  success: Scalars['Boolean']['output'];
};

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED'
}

export type SubscriptionUpgradeInput = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  idempotencyKey: Scalars['String']['input'];
  paymentPeriod: SubscriptionPlanPricePeriod;
  plan: Scalars['String']['input'];
};

export type SubscriptionUpgradeMutationResponse = MutationResponse & {
  __typename?: 'SubscriptionUpgradeMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription: Subscription;
  success: Scalars['Boolean']['output'];
};

export enum SubscriptionUpsellTransactionType {
  ACH = 'ACH',
  ACH_SAMEDAY = 'ACH_SAMEDAY',
  BILL_PAYMENT = 'BILL_PAYMENT',
  OUTGOING_WIRE = 'OUTGOING_WIRE',
  PHYSICAL_CHECK = 'PHYSICAL_CHECK'
}

export type SupportArticle = {
  __typename?: 'SupportArticle';
  body?: Maybe<Scalars['String']['output']>;
  htmlBody?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SupportArticleSearch = {
  __typename?: 'SupportArticleSearch';
  faqs: Array<SupportArticleSearchResult>;
};


export type SupportArticleSearchFaqsArgs = {
  searchTerm: Scalars['String']['input'];
};

export type SupportArticleSearchResult = {
  __typename?: 'SupportArticleSearchResult';
  body?: Maybe<Scalars['String']['output']>;
  htmlBody?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SupportArticleSection = {
  __typename?: 'SupportArticleSection';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SupportHelpCenterService = {
  __typename?: 'SupportHelpCenterService';
  /** View a single article */
  article?: Maybe<SupportArticle>;
  /** List of support FAQ articles */
  faqs: Array<SupportArticle>;
  /** Search for articles */
  search?: Maybe<SupportArticleSearch>;
  /** List of support article sections */
  sections: Array<SupportArticleSection>;
};


export type SupportHelpCenterServiceArticleArgs = {
  articleId: Scalars['ID']['input'];
};


export type SupportHelpCenterServiceFaqsArgs = {
  sectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type SupportPhoneCallBooking = {
  __typename?: 'SupportPhoneCallBooking';
  confirmationEmail?: Maybe<Scalars['String']['output']>;
  /** dateTime formatted as a unix timestamp */
  dateTime?: Maybe<Scalars['String']['output']>;
};

export type SupportPhoneCallBookingMutationResponse = MutationPayload & {
  __typename?: 'SupportPhoneCallBookingMutationResponse';
  supportPhoneCallBooking?: Maybe<SupportPhoneCallBooking>;
  userErrors: Array<UserError>;
};

export type SupportPhoneCallCreateInput = {
  /** dateTime formatted as a unix timestamp */
  dateTime: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  reCAPTCHAToken?: InputMaybe<Scalars['String']['input']>;
  /** Format example: America/New_York */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type SupportPhoneCallService = {
  __typename?: 'SupportPhoneCallService';
  /** list all available slots for a specific day */
  availableSlotsForDay: Array<Scalars['String']['output']>;
  /** list total number of slots available per day for a week from startDate */
  availableSlotsForWeek: Array<SupportPhoneCallSlots>;
};


export type SupportPhoneCallServiceAvailableSlotsForDayArgs = {
  date: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};


export type SupportPhoneCallServiceAvailableSlotsForWeekArgs = {
  startDate?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

export type SupportPhoneCallSlots = {
  __typename?: 'SupportPhoneCallSlots';
  date?: Maybe<Scalars['String']['output']>;
  slots?: Maybe<Scalars['Int']['output']>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  address: PersonAddress;
  businessId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inviteSent?: Maybe<Scalars['Boolean']['output']>;
  isPrimaryOwner?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  onboardingCompleted: Scalars['Boolean']['output'];
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
  shippingAddress?: Maybe<PersonAddress>;
  title: Scalars['String']['output'];
  type: TeamMemberType;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum TeamMemberType {
  CONTROL_PERSON = 'control_person',
  UBO = 'ubo'
}

export type TeamOwner = {
  __typename?: 'TeamOwner';
  address?: Maybe<PersonAddress>;
  businessId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPrimaryOwner?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  onboardingCompleted?: Maybe<Scalars['Boolean']['output']>;
  ownershipPercentage: Scalars['Float']['output'];
  shippingAddress?: Maybe<PersonAddress>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type TermsAndConditionsPdf = {
  __typename?: 'TermsAndConditionsPDF';
  documentReference?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TimeSavedResponse = {
  __typename?: 'TimeSavedResponse';
  pdfGuidelineUrl?: Maybe<Scalars['String']['output']>;
  perMailedCheckTimeSavedSeconds: Scalars['Float']['output'];
  perSameDayAchTimeSavedSeconds: Scalars['Float']['output'];
  perStandardAchTimeSavedSeconds: Scalars['Float']['output'];
  perWireTimeSavedSeconds: Scalars['Float']['output'];
  timeSavedTotalSeconds: Scalars['Float']['output'];
};

export type TopUpAmounts = {
  __typename?: 'TopUpAmounts';
  defaultAmount: Scalars['Float']['output'];
  defaultThresholdAmount: Scalars['Float']['output'];
  maximumAmount: Scalars['Float']['output'];
  minimumAmount: Scalars['Float']['output'];
  minimumThresholdAmount: Scalars['Float']['output'];
};

export type TopUpSettings = {
  __typename?: 'TopUpSettings';
  isTopUpsEnabled: Scalars['Boolean']['output'];
  lowBalanceThreshold: Scalars['Float']['output'];
  plaidItem: PlaidItemIdAndStatus;
  topUpAmount: Scalars['Float']['output'];
};

export type TopUpVelocityLimits = {
  __typename?: 'TopUpVelocityLimits';
  maxTransactionCountPer30DayRolling: Scalars['Int']['output'];
};

export type TopUpsAmountsQueryResponse = {
  __typename?: 'TopUpsAmountsQueryResponse';
  defaultAmount: Scalars['Int']['output'];
  defaultThresholdAmount: Scalars['Int']['output'];
  maximumAmount: Scalars['Int']['output'];
  /** Maximum number of active Plaid items per business */
  maximumItemCount: Scalars['Int']['output'];
  minimumAmount: Scalars['Int']['output'];
};

export type TopUpsCapability = {
  __typename?: 'TopUpsCapability';
  amounts: TopUpAmounts;
  isEligible: Scalars['Boolean']['output'];
  velocityLimits: TopUpVelocityLimits;
};

export type TopUpsError = {
  __typename?: 'TopUpsError';
  code: PlaidTopUpsErrorCodeEnum;
  message: Scalars['String']['output'];
};

export type TransactionContactNotificationOptionsInput = {
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TransactionDirectionEnum {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export type TransactionFeesResponse = {
  __typename?: 'TransactionFeesResponse';
  billPay: Scalars['Float']['output'];
  domesticWire: Scalars['Float']['output'];
  mailedCheck: Scalars['Float']['output'];
  sameDayAch: Scalars['Float']['output'];
};

export type TransactionListItem = {
  __typename?: 'TransactionListItem';
  amount: Scalars['Float']['output'];
  businessId: Scalars['ID']['output'];
  cardLast4?: Maybe<Scalars['String']['output']>;
  /** datetime in ISO 8601 format */
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  holdExpiredAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isBancorpUpgradeDeposit?: Maybe<Scalars['Boolean']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  /** datetime in ISO 8601 format */
  postedAt?: Maybe<Scalars['String']['output']>;
  returnedBy?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TransactionStatusEnum>;
  transactionType: TransactionTypeEnum;
  user?: Maybe<BasicUser>;
};

export type TransactionNoteUpdateResponse = MutationResponse & {
  __typename?: 'TransactionNoteUpdateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type TransactionSearchCsvResponse = {
  __typename?: 'TransactionSearchCSVResponse';
  csvURL: Scalars['String']['output'];
};

export enum TransactionStatusEnum {
  CANCELED = 'CANCELED',
  CANCELLATION_INITIATED = 'CANCELLATION_INITIATED',
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  IN_REVIEW = 'IN_REVIEW',
  POSTED = 'POSTED'
}

export enum TransactionTypeEnum {
  ACH = 'ACH',
  ACH_RETURN = 'ACH_RETURN',
  ATM_WITHDRAWAL = 'ATM_WITHDRAWAL',
  BILL_PAYMENT = 'BILL_PAYMENT',
  BILL_PAYMENT_REFUND = 'BILL_PAYMENT_REFUND',
  CARD_PURCHASE = 'CARD_PURCHASE',
  CHECK_DEPOSIT = 'CHECK_DEPOSIT',
  DOMESTIC_WIRE = 'DOMESTIC_WIRE',
  FEE = 'FEE',
  FEE_REVERSAL = 'FEE_REVERSAL',
  GENERIC = 'GENERIC',
  GREEN_DOT_DEPOSIT = 'GREEN_DOT_DEPOSIT',
  LENDING = 'LENDING',
  MAILED_CHECK = 'MAILED_CHECK',
  MAILED_CHECK_REFUND = 'MAILED_CHECK_REFUND',
  RTP_RECEIVE = 'RTP_RECEIVE',
  SUB_ACCOUNT_TRANSFER = 'SUB_ACCOUNT_TRANSFER',
  WIRE_RETURN = 'WIRE_RETURN',
  WIRE_REVERSAL = 'WIRE_REVERSAL'
}

export type TransactionUpsellContent = {
  __typename?: 'TransactionUpsellContent';
  ach: TransactionUpsellContentPerType;
  billPayment: TransactionUpsellContentPerType;
  businessId: Scalars['ID']['output'];
  outgoingWire: TransactionUpsellContentPerType;
  physicalCheck: TransactionUpsellContentPerType;
  sameDayAch: TransactionUpsellContentPerType;
};

export type TransactionUpsellContentPerType = {
  __typename?: 'TransactionUpsellContentPerType';
  currentPlan: CurrentPlan;
  upsellPlan?: Maybe<UpsellPlan>;
};

export type TransactionsAchCreateInput = {
  amount: Scalars['Float']['input'];
  contactId: Scalars['ID']['input'];
  contactNotificationOptions?: InputMaybe<TransactionContactNotificationOptionsInput>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  sameDay?: InputMaybe<Scalars['Boolean']['input']>;
  subAccountId: Scalars['ID']['input'];
};

export type TransactionsAchCreateMutationResponse = MutationResponse & {
  __typename?: 'TransactionsACHCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type TransactionsAggregateFiltersInput = {
  endDatetime: Scalars['String']['input'];
  startDatetime: Scalars['String']['input'];
  statuses?: InputMaybe<Array<TransactionStatusEnum>>;
};

export type TransactionsAggregateResponse = {
  __typename?: 'TransactionsAggregateResponse';
  sum: TransactionsAggregateSum;
};

export type TransactionsAggregateSum = {
  __typename?: 'TransactionsAggregateSum';
  negative: Scalars['Float']['output'];
  positive: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type TransactionsAmountFilterOptionsInput = {
  exact?: InputMaybe<Scalars['Float']['input']>;
  range?: InputMaybe<FloatRangeInput>;
};

export type TransactionsCapabilitiesResponse = {
  __typename?: 'TransactionsCapabilitiesResponse';
  ach: AchCapability;
  achPull: AchPullCapability;
  billPay: BillPayCapability;
  mailedCheck: MailedCheckCapability;
  outgoingWire: OutgoingWireCapability;
  sameDayAch: SameDayAchCapability;
};

export type TransactionsDatetimeFilterOptionsInput = {
  /** datetime in ISO 8601 format */
  exactDatetime?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<DatetimeRangeInput>;
};

export type TransactionsListFiltersInput = {
  amount?: InputMaybe<TransactionsAmountFilterOptionsInput>;
  /** Filter by the card Ids */
  cardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  datetime?: InputMaybe<TransactionsDatetimeFilterOptionsInput>;
  direction?: InputMaybe<TransactionDirectionEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Limits results to one or more transaction statuses. */
  statuses?: InputMaybe<Array<TransactionStatusEnum>>;
  /** Limits results to a particular sub account */
  subAccountId?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  transactionTypes?: InputMaybe<Array<TransactionTypeEnum>>;
};

export type TransactionsListResponse = {
  __typename?: 'TransactionsListResponse';
  pagination?: Maybe<TransactionsPagination>;
  transactionListItems: Array<TransactionListItem>;
};

export type TransactionsPagination = {
  __typename?: 'TransactionsPagination';
  cursor?: Maybe<Scalars['String']['output']>;
};

export type TransactionsPaginationOptionsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type TransferLimits = {
  __typename?: 'TransferLimits';
  MAX_PER_TRANSACTION: Scalars['Float']['output'];
  MIN_PER_TRANSACTION: Scalars['Float']['output'];
  PER_30_DAYS_ROLLING: Scalars['Float']['output'];
  PER_DAY: Scalars['Float']['output'];
};

export type UpdateInvoiceAccountsPayableMutationResponse = MutationResponse & {
  __typename?: 'UpdateInvoiceAccountsPayableMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateScheduledPaymentInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  idempotencyKey: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScheduledPaymentResponse = MutationResponse & {
  __typename?: 'UpdateScheduledPaymentResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateTopUpsSettingsDataInput = {
  idempotencyKey: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  lowBalanceThreshold?: InputMaybe<Scalars['Float']['input']>;
  topUpAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateTopUpsSettingsResponse = MutationResponse & {
  __typename?: 'UpdateTopUpsSettingsResponse';
  code: Scalars['String']['output'];
  data: TopUpSettings;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateUserMutationResponse = MutationResponse & {
  __typename?: 'UpdateUserMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user?: Maybe<UserData>;
  userErrors?: Maybe<Array<UserError>>;
};

export type UpsellPlan = {
  __typename?: 'UpsellPlan';
  buttonContent?: Maybe<Scalars['String']['output']>;
  discountFeeDetails?: Maybe<DiscountFeeDetails>;
  planName: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPrimaryOwner: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  onboardingCompleted?: Maybe<Scalars['Boolean']['output']>;
  ownerBusinesses: Array<Business>;
  phone?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<Address>;
  signUpDate: Scalars['String']['output'];
  wasExistingRadiusUser?: Maybe<Scalars['Boolean']['output']>;
};

export type UserAddressData = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UserData = {
  __typename?: 'UserData';
  address?: Maybe<Address>;
  dob?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  ownershipPercentage?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<Address>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UserDataInput = {
  address?: InputMaybe<UserAddressData>;
  dob?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ownershipPercentage?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<UserAddressData>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UserError = {
  __typename?: 'UserError';
  code: ErrorCodeEnum;
  /** Key path to the relevant argument, e.g. that failed validation */
  field: Array<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type WalletProviderDetailsInput = {
  cert1?: InputMaybe<Scalars['String']['input']>;
  cert2?: InputMaybe<Scalars['String']['input']>;
  nonce?: InputMaybe<Scalars['String']['input']>;
  nonceSignature?: InputMaybe<Scalars['String']['input']>;
  walletProvider: WalletProviderEnum;
};

export enum WalletProviderEnum {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  SAMSUNG = 'SAMSUNG'
}

export type YearlyAccountStatements = {
  __typename?: 'YearlyAccountStatements';
  monthlyStatements: Array<MonthlyAccountStatements>;
  year?: Maybe<Scalars['String']['output']>;
};

export type GetPlaidAuthDataQueryResponse = {
  __typename?: 'getPlaidAuthDataQueryResponse';
  data: PlaidAuthData;
};

export type ShareAndEarnResendBlockedCustomerEmailMutationResponse = {
  __typename?: 'shareAndEarnResendBlockedCustomerEmailMutationResponse';
  success: Scalars['Boolean']['output'];
};

export type OnboardingMeQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingMeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, isPrimaryOwner: boolean, emailVerified?: boolean | null, ownerBusinesses: Array<{ __typename?: 'Business', id: string, onboarding?: { __typename?: 'BusinessOnboarding', accountStatus?: OnboardingAccountStatus | null, id: string, application?: { __typename?: 'OnboardingApplication', id: string, status?: OnboardingApplicationStatus | null, homeAddress?: { __typename?: 'Location', streetAddressLine1?: string | null, streetAddressLine2?: string | null, city?: string | null, postalCode?: string | null, provinceState?: string | null } | null, businessAddress?: { __typename?: 'Location', streetAddressLine1?: string | null, streetAddressLine2?: string | null, city?: string | null, postalCode?: string | null, provinceState?: string | null } | null } | null } | null }> } | null };

export type PostAuthWrapperQueryVariables = Exact<{ [key: string]: never; }>;


export type PostAuthWrapperQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, emailVerified?: boolean | null, onboardingCompleted?: boolean | null, isPrimaryOwner: boolean, ownerBusinesses: Array<{ __typename?: 'Business', id: string, onboarding?: { __typename?: 'BusinessOnboarding', accountStatus?: OnboardingAccountStatus | null, id: string } | null }> } | null };

export type ApplySignupPromoCodeMutationVariables = Exact<{
  input: ApplySubscriptionPromoCodeInput;
}>;


export type ApplySignupPromoCodeMutation = { __typename?: 'Mutation', applySubscriptionPromoCode: { __typename?: 'ApplySubscriptionPromoCodeMutationResponse', success: boolean } };

export type ResendAuth0VerificationEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ResendAuth0VerificationEmailMutation = { __typename?: 'Mutation', resendVerificationEmail?: { __typename?: 'ConcreteMutationResponse', success: boolean, code: string, message: string } | null };

export type EmailQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email?: string | null } | null };

export type ApplicationSubmitScreenQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type ApplicationSubmitScreenQuery = { __typename?: 'Query', business: { __typename?: 'Business', id: string, onboarding?: { __typename?: 'BusinessOnboarding', accountStatus?: OnboardingAccountStatus | null, id: string, application?: { __typename?: 'OnboardingApplication', id: string, status?: OnboardingApplicationStatus | null } | null } | null } };

export type ApplicationSubmitAndPreconfigureInvitationsMutationVariables = Exact<{
  data: OnboardingApplicationSubmitData;
  businessId: Scalars['ID']['input'];
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ApplicationSubmitAndPreconfigureInvitationsMutation = { __typename?: 'Mutation', onboardingApplicationSubmit: { __typename?: 'OnboardingApplicationSubmitResponse', success: boolean, code: string, message: string, application: { __typename?: 'OnboardingApplication', id: string } }, preconfigureTeamMemberInvitations?: { __typename?: 'PreconfigureTeamMemberInvitationsResponse', code: string, message: string, success: boolean } | null };

export type VerifyBusinessNameIsValidQueryVariables = Exact<{
  name: Scalars['String']['input'];
  businessType?: InputMaybe<BusinessType>;
}>;


export type VerifyBusinessNameIsValidQuery = { __typename?: 'Query', verifyBusinessNameIsValid?: boolean | null };

export type AcceptedAgreementsQueryVariables = Exact<{
  businessId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type AcceptedAgreementsQuery = { __typename?: 'Query', acceptedAgreements?: Array<{ __typename?: 'AcceptedAgreement', agreementType: string, documentReference: string, acceptedAt: string } | null> | null };

export type RedeemReferralOtpMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type RedeemReferralOtpMutation = { __typename?: 'Mutation', redeemReferralOTP?: { __typename?: 'PartnershipReferral', application?: { __typename?: 'PartnershipApplication', id?: string | null, persons?: Array<{ __typename?: 'Person', id?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: string | null, ownershipPercentage?: number | null, address?: { __typename?: 'Address', line1?: string | null, line2?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, country?: string | null } | null } | null> | null, business?: { __typename?: 'ApplicantBusiness', id?: string | null, dba?: string | null, website?: string | null, description?: string | null, ein?: string | null, typeOfProductsOrServices?: string | null, numberOfEmployees?: number | null, legalName?: string | null, businessType?: BusinessType | null, phone?: string | null, yearsAtLocation?: number | null, annualRevenue?: number | null, address?: { __typename?: 'Address', line1?: string | null, line2?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, country?: string | null } | null } | null } | null } | null };

export type AccountUsageSubmitMutationVariables = Exact<{
  data: AccountUsageData;
}>;


export type AccountUsageSubmitMutation = { __typename?: 'Mutation', accountUsageSubmit: { __typename?: 'AccountUsageSubmitResponse', success: boolean, code: string, message: string } };

export type NaicsQueryVariables = Exact<{ [key: string]: never; }>;


export type NaicsQuery = { __typename?: 'Query', naics?: { __typename?: 'NaicsIndustries', groupedIndustries: Array<{ __typename?: 'GroupedIndustries', vertical: string, industries: Array<{ __typename?: 'KYBIndustry', naicsCategory: string, naicsCode: number, subCategory: string, keywords: string, vertical: string }> }>, defaultIndustry: { __typename?: 'KYBIndustry', naicsCategory: string, naicsCode: number, subCategory: string, keywords: string, vertical: string } } | null };

export type CheckOnboardingApplicationEligibilityMutationVariables = Exact<{
  data: PartialOnboardingApplicationDataWithoutSsn;
}>;


export type CheckOnboardingApplicationEligibilityMutation = { __typename?: 'Mutation', checkOnboardingApplicationEligibility: { __typename?: 'OnboardingApplicationEligibilityCheckResponse', code: string, success: boolean, message: string, data: { __typename?: 'OnboardingApplicationEligibility', isEligible: boolean } } };

export type RequiredManualReviewFilesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type RequiredManualReviewFilesQuery = { __typename?: 'Query', business: { __typename?: 'Business', onboarding?: { __typename?: 'BusinessOnboarding', application?: { __typename?: 'OnboardingApplication', requiredDocumentUpload?: { __typename?: 'OnboardingRequiredDocumentUpload', requiredDocuments: Array<RequiredDocumentEnum> } | null } | null } | null } };

export type PerformIntercomApiCommandMutationVariables = Exact<{
  data: PerformIntercomApiCommandData;
}>;


export type PerformIntercomApiCommandMutation = { __typename?: 'Mutation', performIntercomAPICommand: { __typename?: 'PerformIntercomAPICommandResponse', code: string, data: any, message: string, success: boolean } };

export type RequiredDocumentsQueryVariables = Exact<{
  businessId: Scalars['String']['input'];
  applicationId: Scalars['String']['input'];
}>;


export type RequiredDocumentsQuery = { __typename?: 'Query', requiredDocuments: { __typename?: 'RequiredDocumentsResponse', businessId: string, applicationId: string, documents: { __typename?: 'RequiredDocumentsCollection', dba: boolean, incorporation: boolean, customerId: boolean } } };

export type OnboardingApplicationAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingApplicationAddressesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, ownerBusinesses: Array<{ __typename?: 'Business', id: string, onboarding?: { __typename?: 'BusinessOnboarding', businessId: string, id: string, application?: { __typename?: 'OnboardingApplication', id: string, status?: OnboardingApplicationStatus | null, homeAddress?: { __typename?: 'Location', streetAddressLine1?: string | null, streetAddressLine2?: string | null, city?: string | null, postalCode?: string | null, provinceState?: string | null } | null, businessAddress?: { __typename?: 'Location', streetAddressLine1?: string | null, streetAddressLine2?: string | null, city?: string | null, postalCode?: string | null, provinceState?: string | null } | null } | null } | null }> } | null };

export type OnboardingMailingAddressQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingMailingAddressQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, ownerBusinesses: Array<{ __typename?: 'Business', id: string, onboardingMailingAddress?: { __typename?: 'Location', streetAddressLine1?: string | null, streetAddressLine2?: string | null, city?: string | null, provinceState?: string | null, country?: string | null, postalCode?: string | null } | null }> } | null };

export type OnboardingMailingAddressUpdateMutationVariables = Exact<{
  data: OnboardingMailingAddressUpdateData;
}>;


export type OnboardingMailingAddressUpdateMutation = { __typename?: 'Mutation', onboardingMailingAddressUpdate: { __typename?: 'OnboardingMailingAddressUpdateSubmitResponse', success: boolean, code: string, message: string } };

export type DocumentRequestResendEmailMutationVariables = Exact<{
  data: DocumentRequestEmailResendData;
}>;


export type DocumentRequestResendEmailMutation = { __typename?: 'Mutation', documentRequestResendEmail: { __typename?: 'DocumentRequestEmailResendResponse', success: boolean, code: string, message: string } };

export type ApplicationStatusScreenQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type ApplicationStatusScreenQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, phone?: string | null, email?: string | null } | null, business: { __typename?: 'Business', onboarding?: { __typename?: 'BusinessOnboarding', application?: { __typename?: 'OnboardingApplication', status?: OnboardingApplicationStatus | null } | null } | null } };

export type CelebrationScreenQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type CelebrationScreenQuery = { __typename?: 'Query', business: { __typename?: 'Business', onboarding?: { __typename?: 'BusinessOnboarding', accountStatus?: OnboardingAccountStatus | null } | null } };

export type ApplicationStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicationStatusQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, ownerBusinesses: Array<{ __typename?: 'Business', id: string, onboarding?: { __typename?: 'BusinessOnboarding', application?: { __typename?: 'OnboardingApplication', id: string, status?: OnboardingApplicationStatus | null } | null } | null }> } | null };

export type PrimaryOwnerStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type PrimaryOwnerStatusQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, isPrimaryOwner: boolean } | null };

export type ListTeamMembersQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type ListTeamMembersQuery = { __typename?: 'Query', business: { __typename?: 'Business', teamMembers: Array<{ __typename?: 'TeamMember', id: string, businessId: string, firstName: string, lastName: string, email: string, title: string, type: TeamMemberType, ownershipPercentage?: number | null, userId?: string | null, onboardingCompleted: boolean, isPrimaryOwner?: boolean | null, address: { __typename?: 'PersonAddress', line1: string, line2?: string | null, city: string, state: string, postalCode: string, country: string }, shippingAddress?: { __typename?: 'PersonAddress', line1: string, line2?: string | null, city: string, state: string, postalCode: string, country: string } | null }> } };

export type UpdateUserDataMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  userData?: InputMaybe<UserDataInput>;
}>;


export type UpdateUserDataMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'UpdateUserMutationResponse', success: boolean } | null };

export type ConfirmAdditionalOwnersOnboardingStatusMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type ConfirmAdditionalOwnersOnboardingStatusMutation = { __typename?: 'Mutation', confirmAdditionalOwnersOnboardingStatus?: { __typename?: 'ConfirmAdditionalOwnersOnboardingStatusMutationResponse', success: boolean, code: string, message: string, status?: { __typename?: 'AdditionalOwnerOnboardingStatus', onboardingCompleted: boolean } | null } | null };

export type JoinTeamCelebrationScreenQueryVariables = Exact<{ [key: string]: never; }>;


export type JoinTeamCelebrationScreenQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, isPrimaryOwner: boolean, ownerBusinesses: Array<{ __typename?: 'Business', id: string }> } | null };

export type JoinTeamLayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type JoinTeamLayoutQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, isPrimaryOwner: boolean } | null };

export type JoinTeamCelebrationOnboardingCompletedQueryVariables = Exact<{ [key: string]: never; }>;


export type JoinTeamCelebrationOnboardingCompletedQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, onboardingCompleted?: boolean | null } | null };

export type RejectedScreenQueryVariables = Exact<{ [key: string]: never; }>;


export type RejectedScreenQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email?: string | null } | null };

export type RegisterUserMutationVariables = Exact<{
  phone?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser?: { __typename?: 'RegisterUserResponse', code: string, success: boolean, message: string, user?: { __typename?: 'RegisterUserData', id: string, businessId?: string | null, phone?: string | null } | null } | null };

export type EmailVerifiedQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailVerifiedQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, emailVerified?: boolean | null } | null };

export type TermsAndConditionsScreenQueryVariables = Exact<{ [key: string]: never; }>;


export type TermsAndConditionsScreenQuery = { __typename?: 'Query', esignAgreement: { __typename?: 'AgreementsPDF', pdfURL: string }, accountAgreement: { __typename?: 'AgreementsPDF', pdfURL: string }, mobilePaymentServicesAgreement: { __typename?: 'AgreementsPDF', pdfURL: string }, termsAndConditionsPDF?: { __typename?: 'TermsAndConditionsPDF', url?: string | null, documentReference?: string | null } | null };

export type OnboardingAgreementsTermsAndConditionsAcceptMutationVariables = Exact<{
  documentReference: Scalars['String']['input'];
  businessId: Scalars['String']['input'];
}>;


export type OnboardingAgreementsTermsAndConditionsAcceptMutation = { __typename?: 'Mutation', onboardingAgreementsTermsAndConditionsAccept: { __typename?: 'OnboardingAgreementsTermsAndConditionsAcceptMutationResponse', code: string, success: boolean, message: string } };


export const OnboardingMeDocument = gql`
    query onboardingMe {
  me {
    id
    isPrimaryOwner
    emailVerified
    ownerBusinesses {
      id
      onboarding {
        id: businessId
        application {
          id
          status
          homeAddress {
            streetAddressLine1
            streetAddressLine2
            city
            postalCode
            provinceState
          }
          businessAddress {
            streetAddressLine1
            streetAddressLine2
            city
            postalCode
            provinceState
          }
        }
        accountStatus
      }
    }
  }
}
    `;

/**
 * __useOnboardingMeQuery__
 *
 * To run a query within a React component, call `useOnboardingMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingMeQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingMeQuery, OnboardingMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingMeQuery, OnboardingMeQueryVariables>(OnboardingMeDocument, options);
      }
export function useOnboardingMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingMeQuery, OnboardingMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingMeQuery, OnboardingMeQueryVariables>(OnboardingMeDocument, options);
        }
export function useOnboardingMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OnboardingMeQuery, OnboardingMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingMeQuery, OnboardingMeQueryVariables>(OnboardingMeDocument, options);
        }
export type OnboardingMeQueryHookResult = ReturnType<typeof useOnboardingMeQuery>;
export type OnboardingMeLazyQueryHookResult = ReturnType<typeof useOnboardingMeLazyQuery>;
export type OnboardingMeSuspenseQueryHookResult = ReturnType<typeof useOnboardingMeSuspenseQuery>;
export type OnboardingMeQueryResult = Apollo.QueryResult<OnboardingMeQuery, OnboardingMeQueryVariables>;
export const PostAuthWrapperDocument = gql`
    query postAuthWrapper {
  me {
    id
    email
    firstName
    lastName
    emailVerified
    onboardingCompleted
    isPrimaryOwner
    ownerBusinesses {
      id
      onboarding {
        id: businessId
        accountStatus
      }
    }
  }
}
    `;

/**
 * __usePostAuthWrapperQuery__
 *
 * To run a query within a React component, call `usePostAuthWrapperQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostAuthWrapperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostAuthWrapperQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostAuthWrapperQuery(baseOptions?: Apollo.QueryHookOptions<PostAuthWrapperQuery, PostAuthWrapperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostAuthWrapperQuery, PostAuthWrapperQueryVariables>(PostAuthWrapperDocument, options);
      }
export function usePostAuthWrapperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostAuthWrapperQuery, PostAuthWrapperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostAuthWrapperQuery, PostAuthWrapperQueryVariables>(PostAuthWrapperDocument, options);
        }
export function usePostAuthWrapperSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PostAuthWrapperQuery, PostAuthWrapperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostAuthWrapperQuery, PostAuthWrapperQueryVariables>(PostAuthWrapperDocument, options);
        }
export type PostAuthWrapperQueryHookResult = ReturnType<typeof usePostAuthWrapperQuery>;
export type PostAuthWrapperLazyQueryHookResult = ReturnType<typeof usePostAuthWrapperLazyQuery>;
export type PostAuthWrapperSuspenseQueryHookResult = ReturnType<typeof usePostAuthWrapperSuspenseQuery>;
export type PostAuthWrapperQueryResult = Apollo.QueryResult<PostAuthWrapperQuery, PostAuthWrapperQueryVariables>;
export const ApplySignupPromoCodeDocument = gql`
    mutation applySignupPromoCode($input: ApplySubscriptionPromoCodeInput!) {
  applySubscriptionPromoCode(input: $input) {
    success
  }
}
    `;
export type ApplySignupPromoCodeMutationFn = Apollo.MutationFunction<ApplySignupPromoCodeMutation, ApplySignupPromoCodeMutationVariables>;

/**
 * __useApplySignupPromoCodeMutation__
 *
 * To run a mutation, you first call `useApplySignupPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplySignupPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applySignupPromoCodeMutation, { data, loading, error }] = useApplySignupPromoCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplySignupPromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<ApplySignupPromoCodeMutation, ApplySignupPromoCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplySignupPromoCodeMutation, ApplySignupPromoCodeMutationVariables>(ApplySignupPromoCodeDocument, options);
      }
export type ApplySignupPromoCodeMutationHookResult = ReturnType<typeof useApplySignupPromoCodeMutation>;
export type ApplySignupPromoCodeMutationResult = Apollo.MutationResult<ApplySignupPromoCodeMutation>;
export type ApplySignupPromoCodeMutationOptions = Apollo.BaseMutationOptions<ApplySignupPromoCodeMutation, ApplySignupPromoCodeMutationVariables>;
export const ResendAuth0VerificationEmailDocument = gql`
    mutation resendAuth0VerificationEmail($email: String!, $reCaptchaToken: String) {
  resendVerificationEmail(userEmail: $email, reCAPTCHAToken: $reCaptchaToken) {
    success
    code
    message
  }
}
    `;
export type ResendAuth0VerificationEmailMutationFn = Apollo.MutationFunction<ResendAuth0VerificationEmailMutation, ResendAuth0VerificationEmailMutationVariables>;

/**
 * __useResendAuth0VerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendAuth0VerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendAuth0VerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendAuth0VerificationEmailMutation, { data, loading, error }] = useResendAuth0VerificationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      reCaptchaToken: // value for 'reCaptchaToken'
 *   },
 * });
 */
export function useResendAuth0VerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendAuth0VerificationEmailMutation, ResendAuth0VerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendAuth0VerificationEmailMutation, ResendAuth0VerificationEmailMutationVariables>(ResendAuth0VerificationEmailDocument, options);
      }
export type ResendAuth0VerificationEmailMutationHookResult = ReturnType<typeof useResendAuth0VerificationEmailMutation>;
export type ResendAuth0VerificationEmailMutationResult = Apollo.MutationResult<ResendAuth0VerificationEmailMutation>;
export type ResendAuth0VerificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendAuth0VerificationEmailMutation, ResendAuth0VerificationEmailMutationVariables>;
export const EmailDocument = gql`
    query Email {
  me {
    id
    email
  }
}
    `;

/**
 * __useEmailQuery__
 *
 * To run a query within a React component, call `useEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailQuery(baseOptions?: Apollo.QueryHookOptions<EmailQuery, EmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailQuery, EmailQueryVariables>(EmailDocument, options);
      }
export function useEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailQuery, EmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailQuery, EmailQueryVariables>(EmailDocument, options);
        }
export function useEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmailQuery, EmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmailQuery, EmailQueryVariables>(EmailDocument, options);
        }
export type EmailQueryHookResult = ReturnType<typeof useEmailQuery>;
export type EmailLazyQueryHookResult = ReturnType<typeof useEmailLazyQuery>;
export type EmailSuspenseQueryHookResult = ReturnType<typeof useEmailSuspenseQuery>;
export type EmailQueryResult = Apollo.QueryResult<EmailQuery, EmailQueryVariables>;
export const ApplicationSubmitScreenDocument = gql`
    query applicationSubmitScreen($businessId: ID!) {
  business(businessId: $businessId) {
    id
    onboarding {
      id: businessId
      accountStatus
      application {
        id
        status
      }
    }
  }
}
    `;

/**
 * __useApplicationSubmitScreenQuery__
 *
 * To run a query within a React component, call `useApplicationSubmitScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationSubmitScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationSubmitScreenQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useApplicationSubmitScreenQuery(baseOptions: Apollo.QueryHookOptions<ApplicationSubmitScreenQuery, ApplicationSubmitScreenQueryVariables> & ({ variables: ApplicationSubmitScreenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationSubmitScreenQuery, ApplicationSubmitScreenQueryVariables>(ApplicationSubmitScreenDocument, options);
      }
export function useApplicationSubmitScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationSubmitScreenQuery, ApplicationSubmitScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationSubmitScreenQuery, ApplicationSubmitScreenQueryVariables>(ApplicationSubmitScreenDocument, options);
        }
export function useApplicationSubmitScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApplicationSubmitScreenQuery, ApplicationSubmitScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApplicationSubmitScreenQuery, ApplicationSubmitScreenQueryVariables>(ApplicationSubmitScreenDocument, options);
        }
export type ApplicationSubmitScreenQueryHookResult = ReturnType<typeof useApplicationSubmitScreenQuery>;
export type ApplicationSubmitScreenLazyQueryHookResult = ReturnType<typeof useApplicationSubmitScreenLazyQuery>;
export type ApplicationSubmitScreenSuspenseQueryHookResult = ReturnType<typeof useApplicationSubmitScreenSuspenseQuery>;
export type ApplicationSubmitScreenQueryResult = Apollo.QueryResult<ApplicationSubmitScreenQuery, ApplicationSubmitScreenQueryVariables>;
export const ApplicationSubmitAndPreconfigureInvitationsDocument = gql`
    mutation ApplicationSubmitAndPreconfigureInvitations($data: OnboardingApplicationSubmitData!, $businessId: ID!, $emails: [String!]!) {
  onboardingApplicationSubmit(data: $data) {
    success
    code
    message
    application {
      id
    }
  }
  preconfigureTeamMemberInvitations(businessId: $businessId, emails: $emails) {
    code
    message
    success
  }
}
    `;
export type ApplicationSubmitAndPreconfigureInvitationsMutationFn = Apollo.MutationFunction<ApplicationSubmitAndPreconfigureInvitationsMutation, ApplicationSubmitAndPreconfigureInvitationsMutationVariables>;

/**
 * __useApplicationSubmitAndPreconfigureInvitationsMutation__
 *
 * To run a mutation, you first call `useApplicationSubmitAndPreconfigureInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplicationSubmitAndPreconfigureInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applicationSubmitAndPreconfigureInvitationsMutation, { data, loading, error }] = useApplicationSubmitAndPreconfigureInvitationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      businessId: // value for 'businessId'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useApplicationSubmitAndPreconfigureInvitationsMutation(baseOptions?: Apollo.MutationHookOptions<ApplicationSubmitAndPreconfigureInvitationsMutation, ApplicationSubmitAndPreconfigureInvitationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplicationSubmitAndPreconfigureInvitationsMutation, ApplicationSubmitAndPreconfigureInvitationsMutationVariables>(ApplicationSubmitAndPreconfigureInvitationsDocument, options);
      }
export type ApplicationSubmitAndPreconfigureInvitationsMutationHookResult = ReturnType<typeof useApplicationSubmitAndPreconfigureInvitationsMutation>;
export type ApplicationSubmitAndPreconfigureInvitationsMutationResult = Apollo.MutationResult<ApplicationSubmitAndPreconfigureInvitationsMutation>;
export type ApplicationSubmitAndPreconfigureInvitationsMutationOptions = Apollo.BaseMutationOptions<ApplicationSubmitAndPreconfigureInvitationsMutation, ApplicationSubmitAndPreconfigureInvitationsMutationVariables>;
export const VerifyBusinessNameIsValidDocument = gql`
    query verifyBusinessNameIsValid($name: String!, $businessType: BusinessType) {
  verifyBusinessNameIsValid(name: $name, businessType: $businessType)
}
    `;

/**
 * __useVerifyBusinessNameIsValidQuery__
 *
 * To run a query within a React component, call `useVerifyBusinessNameIsValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyBusinessNameIsValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyBusinessNameIsValidQuery({
 *   variables: {
 *      name: // value for 'name'
 *      businessType: // value for 'businessType'
 *   },
 * });
 */
export function useVerifyBusinessNameIsValidQuery(baseOptions: Apollo.QueryHookOptions<VerifyBusinessNameIsValidQuery, VerifyBusinessNameIsValidQueryVariables> & ({ variables: VerifyBusinessNameIsValidQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyBusinessNameIsValidQuery, VerifyBusinessNameIsValidQueryVariables>(VerifyBusinessNameIsValidDocument, options);
      }
export function useVerifyBusinessNameIsValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyBusinessNameIsValidQuery, VerifyBusinessNameIsValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyBusinessNameIsValidQuery, VerifyBusinessNameIsValidQueryVariables>(VerifyBusinessNameIsValidDocument, options);
        }
export function useVerifyBusinessNameIsValidSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VerifyBusinessNameIsValidQuery, VerifyBusinessNameIsValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerifyBusinessNameIsValidQuery, VerifyBusinessNameIsValidQueryVariables>(VerifyBusinessNameIsValidDocument, options);
        }
export type VerifyBusinessNameIsValidQueryHookResult = ReturnType<typeof useVerifyBusinessNameIsValidQuery>;
export type VerifyBusinessNameIsValidLazyQueryHookResult = ReturnType<typeof useVerifyBusinessNameIsValidLazyQuery>;
export type VerifyBusinessNameIsValidSuspenseQueryHookResult = ReturnType<typeof useVerifyBusinessNameIsValidSuspenseQuery>;
export type VerifyBusinessNameIsValidQueryResult = Apollo.QueryResult<VerifyBusinessNameIsValidQuery, VerifyBusinessNameIsValidQueryVariables>;
export const AcceptedAgreementsDocument = gql`
    query acceptedAgreements($businessId: String!, $userId: String!) {
  acceptedAgreements(businessId: $businessId, userId: $userId) {
    agreementType
    documentReference
    acceptedAt
  }
}
    `;

/**
 * __useAcceptedAgreementsQuery__
 *
 * To run a query within a React component, call `useAcceptedAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptedAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceptedAgreementsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAcceptedAgreementsQuery(baseOptions: Apollo.QueryHookOptions<AcceptedAgreementsQuery, AcceptedAgreementsQueryVariables> & ({ variables: AcceptedAgreementsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AcceptedAgreementsQuery, AcceptedAgreementsQueryVariables>(AcceptedAgreementsDocument, options);
      }
export function useAcceptedAgreementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AcceptedAgreementsQuery, AcceptedAgreementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AcceptedAgreementsQuery, AcceptedAgreementsQueryVariables>(AcceptedAgreementsDocument, options);
        }
export function useAcceptedAgreementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AcceptedAgreementsQuery, AcceptedAgreementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AcceptedAgreementsQuery, AcceptedAgreementsQueryVariables>(AcceptedAgreementsDocument, options);
        }
export type AcceptedAgreementsQueryHookResult = ReturnType<typeof useAcceptedAgreementsQuery>;
export type AcceptedAgreementsLazyQueryHookResult = ReturnType<typeof useAcceptedAgreementsLazyQuery>;
export type AcceptedAgreementsSuspenseQueryHookResult = ReturnType<typeof useAcceptedAgreementsSuspenseQuery>;
export type AcceptedAgreementsQueryResult = Apollo.QueryResult<AcceptedAgreementsQuery, AcceptedAgreementsQueryVariables>;
export const RedeemReferralOtpDocument = gql`
    mutation redeemReferralOTP($token: String!) {
  redeemReferralOTP(otp: $token) {
    application {
      id
      persons {
        id
        firstName
        lastName
        title
        email
        phone
        dateOfBirth
        ownershipPercentage
        address {
          line1
          line2
          city
          state
          zipCode
          country
        }
      }
      business {
        id
        dba
        website
        description
        ein
        typeOfProductsOrServices
        numberOfEmployees
        legalName
        businessType
        phone
        yearsAtLocation
        annualRevenue
        address {
          line1
          line2
          city
          state
          zipCode
          country
        }
      }
    }
  }
}
    `;
export type RedeemReferralOtpMutationFn = Apollo.MutationFunction<RedeemReferralOtpMutation, RedeemReferralOtpMutationVariables>;

/**
 * __useRedeemReferralOtpMutation__
 *
 * To run a mutation, you first call `useRedeemReferralOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemReferralOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemReferralOtpMutation, { data, loading, error }] = useRedeemReferralOtpMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRedeemReferralOtpMutation(baseOptions?: Apollo.MutationHookOptions<RedeemReferralOtpMutation, RedeemReferralOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemReferralOtpMutation, RedeemReferralOtpMutationVariables>(RedeemReferralOtpDocument, options);
      }
export type RedeemReferralOtpMutationHookResult = ReturnType<typeof useRedeemReferralOtpMutation>;
export type RedeemReferralOtpMutationResult = Apollo.MutationResult<RedeemReferralOtpMutation>;
export type RedeemReferralOtpMutationOptions = Apollo.BaseMutationOptions<RedeemReferralOtpMutation, RedeemReferralOtpMutationVariables>;
export const AccountUsageSubmitDocument = gql`
    mutation accountUsageSubmit($data: AccountUsageData!) {
  accountUsageSubmit(data: $data) {
    success
    code
    message
  }
}
    `;
export type AccountUsageSubmitMutationFn = Apollo.MutationFunction<AccountUsageSubmitMutation, AccountUsageSubmitMutationVariables>;

/**
 * __useAccountUsageSubmitMutation__
 *
 * To run a mutation, you first call `useAccountUsageSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountUsageSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountUsageSubmitMutation, { data, loading, error }] = useAccountUsageSubmitMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAccountUsageSubmitMutation(baseOptions?: Apollo.MutationHookOptions<AccountUsageSubmitMutation, AccountUsageSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountUsageSubmitMutation, AccountUsageSubmitMutationVariables>(AccountUsageSubmitDocument, options);
      }
export type AccountUsageSubmitMutationHookResult = ReturnType<typeof useAccountUsageSubmitMutation>;
export type AccountUsageSubmitMutationResult = Apollo.MutationResult<AccountUsageSubmitMutation>;
export type AccountUsageSubmitMutationOptions = Apollo.BaseMutationOptions<AccountUsageSubmitMutation, AccountUsageSubmitMutationVariables>;
export const NaicsDocument = gql`
    query naics {
  naics {
    groupedIndustries {
      vertical
      industries {
        naicsCategory
        naicsCode
        subCategory
        keywords
        vertical
      }
    }
    defaultIndustry {
      naicsCategory
      naicsCode
      subCategory
      keywords
      vertical
    }
  }
}
    `;

/**
 * __useNaicsQuery__
 *
 * To run a query within a React component, call `useNaicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNaicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNaicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNaicsQuery(baseOptions?: Apollo.QueryHookOptions<NaicsQuery, NaicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NaicsQuery, NaicsQueryVariables>(NaicsDocument, options);
      }
export function useNaicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NaicsQuery, NaicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NaicsQuery, NaicsQueryVariables>(NaicsDocument, options);
        }
export function useNaicsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NaicsQuery, NaicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NaicsQuery, NaicsQueryVariables>(NaicsDocument, options);
        }
export type NaicsQueryHookResult = ReturnType<typeof useNaicsQuery>;
export type NaicsLazyQueryHookResult = ReturnType<typeof useNaicsLazyQuery>;
export type NaicsSuspenseQueryHookResult = ReturnType<typeof useNaicsSuspenseQuery>;
export type NaicsQueryResult = Apollo.QueryResult<NaicsQuery, NaicsQueryVariables>;
export const CheckOnboardingApplicationEligibilityDocument = gql`
    mutation CheckOnboardingApplicationEligibility($data: PartialOnboardingApplicationDataWithoutSSN!) {
  checkOnboardingApplicationEligibility(data: $data) {
    code
    success
    message
    data {
      isEligible
    }
  }
}
    `;
export type CheckOnboardingApplicationEligibilityMutationFn = Apollo.MutationFunction<CheckOnboardingApplicationEligibilityMutation, CheckOnboardingApplicationEligibilityMutationVariables>;

/**
 * __useCheckOnboardingApplicationEligibilityMutation__
 *
 * To run a mutation, you first call `useCheckOnboardingApplicationEligibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOnboardingApplicationEligibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOnboardingApplicationEligibilityMutation, { data, loading, error }] = useCheckOnboardingApplicationEligibilityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckOnboardingApplicationEligibilityMutation(baseOptions?: Apollo.MutationHookOptions<CheckOnboardingApplicationEligibilityMutation, CheckOnboardingApplicationEligibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckOnboardingApplicationEligibilityMutation, CheckOnboardingApplicationEligibilityMutationVariables>(CheckOnboardingApplicationEligibilityDocument, options);
      }
export type CheckOnboardingApplicationEligibilityMutationHookResult = ReturnType<typeof useCheckOnboardingApplicationEligibilityMutation>;
export type CheckOnboardingApplicationEligibilityMutationResult = Apollo.MutationResult<CheckOnboardingApplicationEligibilityMutation>;
export type CheckOnboardingApplicationEligibilityMutationOptions = Apollo.BaseMutationOptions<CheckOnboardingApplicationEligibilityMutation, CheckOnboardingApplicationEligibilityMutationVariables>;
export const RequiredManualReviewFilesDocument = gql`
    query requiredManualReviewFiles($businessId: ID!) {
  business(businessId: $businessId) {
    onboarding {
      application {
        requiredDocumentUpload {
          requiredDocuments
        }
      }
    }
  }
}
    `;

/**
 * __useRequiredManualReviewFilesQuery__
 *
 * To run a query within a React component, call `useRequiredManualReviewFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequiredManualReviewFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequiredManualReviewFilesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useRequiredManualReviewFilesQuery(baseOptions: Apollo.QueryHookOptions<RequiredManualReviewFilesQuery, RequiredManualReviewFilesQueryVariables> & ({ variables: RequiredManualReviewFilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequiredManualReviewFilesQuery, RequiredManualReviewFilesQueryVariables>(RequiredManualReviewFilesDocument, options);
      }
export function useRequiredManualReviewFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequiredManualReviewFilesQuery, RequiredManualReviewFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequiredManualReviewFilesQuery, RequiredManualReviewFilesQueryVariables>(RequiredManualReviewFilesDocument, options);
        }
export function useRequiredManualReviewFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RequiredManualReviewFilesQuery, RequiredManualReviewFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequiredManualReviewFilesQuery, RequiredManualReviewFilesQueryVariables>(RequiredManualReviewFilesDocument, options);
        }
export type RequiredManualReviewFilesQueryHookResult = ReturnType<typeof useRequiredManualReviewFilesQuery>;
export type RequiredManualReviewFilesLazyQueryHookResult = ReturnType<typeof useRequiredManualReviewFilesLazyQuery>;
export type RequiredManualReviewFilesSuspenseQueryHookResult = ReturnType<typeof useRequiredManualReviewFilesSuspenseQuery>;
export type RequiredManualReviewFilesQueryResult = Apollo.QueryResult<RequiredManualReviewFilesQuery, RequiredManualReviewFilesQueryVariables>;
export const PerformIntercomApiCommandDocument = gql`
    mutation PerformIntercomAPICommand($data: PerformIntercomAPICommandData!) {
  performIntercomAPICommand(data: $data) {
    code
    data
    message
    success
  }
}
    `;
export type PerformIntercomApiCommandMutationFn = Apollo.MutationFunction<PerformIntercomApiCommandMutation, PerformIntercomApiCommandMutationVariables>;

/**
 * __usePerformIntercomApiCommandMutation__
 *
 * To run a mutation, you first call `usePerformIntercomApiCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePerformIntercomApiCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [performIntercomApiCommandMutation, { data, loading, error }] = usePerformIntercomApiCommandMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePerformIntercomApiCommandMutation(baseOptions?: Apollo.MutationHookOptions<PerformIntercomApiCommandMutation, PerformIntercomApiCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PerformIntercomApiCommandMutation, PerformIntercomApiCommandMutationVariables>(PerformIntercomApiCommandDocument, options);
      }
export type PerformIntercomApiCommandMutationHookResult = ReturnType<typeof usePerformIntercomApiCommandMutation>;
export type PerformIntercomApiCommandMutationResult = Apollo.MutationResult<PerformIntercomApiCommandMutation>;
export type PerformIntercomApiCommandMutationOptions = Apollo.BaseMutationOptions<PerformIntercomApiCommandMutation, PerformIntercomApiCommandMutationVariables>;
export const RequiredDocumentsDocument = gql`
    query requiredDocuments($businessId: String!, $applicationId: String!) {
  requiredDocuments(businessId: $businessId, applicationId: $applicationId) {
    businessId
    applicationId
    documents {
      dba
      incorporation
      customerId
    }
  }
}
    `;

/**
 * __useRequiredDocumentsQuery__
 *
 * To run a query within a React component, call `useRequiredDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequiredDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequiredDocumentsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useRequiredDocumentsQuery(baseOptions: Apollo.QueryHookOptions<RequiredDocumentsQuery, RequiredDocumentsQueryVariables> & ({ variables: RequiredDocumentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequiredDocumentsQuery, RequiredDocumentsQueryVariables>(RequiredDocumentsDocument, options);
      }
export function useRequiredDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequiredDocumentsQuery, RequiredDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequiredDocumentsQuery, RequiredDocumentsQueryVariables>(RequiredDocumentsDocument, options);
        }
export function useRequiredDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RequiredDocumentsQuery, RequiredDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequiredDocumentsQuery, RequiredDocumentsQueryVariables>(RequiredDocumentsDocument, options);
        }
export type RequiredDocumentsQueryHookResult = ReturnType<typeof useRequiredDocumentsQuery>;
export type RequiredDocumentsLazyQueryHookResult = ReturnType<typeof useRequiredDocumentsLazyQuery>;
export type RequiredDocumentsSuspenseQueryHookResult = ReturnType<typeof useRequiredDocumentsSuspenseQuery>;
export type RequiredDocumentsQueryResult = Apollo.QueryResult<RequiredDocumentsQuery, RequiredDocumentsQueryVariables>;
export const OnboardingApplicationAddressesDocument = gql`
    query onboardingApplicationAddresses {
  me {
    id
    ownerBusinesses {
      id
      onboarding {
        id: businessId
        businessId
        application {
          id
          status
          homeAddress {
            streetAddressLine1
            streetAddressLine2
            city
            postalCode
            provinceState
          }
          businessAddress {
            streetAddressLine1
            streetAddressLine2
            city
            postalCode
            provinceState
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOnboardingApplicationAddressesQuery__
 *
 * To run a query within a React component, call `useOnboardingApplicationAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingApplicationAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingApplicationAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingApplicationAddressesQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingApplicationAddressesQuery, OnboardingApplicationAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingApplicationAddressesQuery, OnboardingApplicationAddressesQueryVariables>(OnboardingApplicationAddressesDocument, options);
      }
export function useOnboardingApplicationAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingApplicationAddressesQuery, OnboardingApplicationAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingApplicationAddressesQuery, OnboardingApplicationAddressesQueryVariables>(OnboardingApplicationAddressesDocument, options);
        }
export function useOnboardingApplicationAddressesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OnboardingApplicationAddressesQuery, OnboardingApplicationAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingApplicationAddressesQuery, OnboardingApplicationAddressesQueryVariables>(OnboardingApplicationAddressesDocument, options);
        }
export type OnboardingApplicationAddressesQueryHookResult = ReturnType<typeof useOnboardingApplicationAddressesQuery>;
export type OnboardingApplicationAddressesLazyQueryHookResult = ReturnType<typeof useOnboardingApplicationAddressesLazyQuery>;
export type OnboardingApplicationAddressesSuspenseQueryHookResult = ReturnType<typeof useOnboardingApplicationAddressesSuspenseQuery>;
export type OnboardingApplicationAddressesQueryResult = Apollo.QueryResult<OnboardingApplicationAddressesQuery, OnboardingApplicationAddressesQueryVariables>;
export const OnboardingMailingAddressDocument = gql`
    query onboardingMailingAddress {
  me {
    id
    ownerBusinesses {
      id
      onboardingMailingAddress {
        streetAddressLine1
        streetAddressLine2
        city
        provinceState
        country
        postalCode
      }
    }
  }
}
    `;

/**
 * __useOnboardingMailingAddressQuery__
 *
 * To run a query within a React component, call `useOnboardingMailingAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingMailingAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingMailingAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingMailingAddressQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingMailingAddressQuery, OnboardingMailingAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingMailingAddressQuery, OnboardingMailingAddressQueryVariables>(OnboardingMailingAddressDocument, options);
      }
export function useOnboardingMailingAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingMailingAddressQuery, OnboardingMailingAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingMailingAddressQuery, OnboardingMailingAddressQueryVariables>(OnboardingMailingAddressDocument, options);
        }
export function useOnboardingMailingAddressSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OnboardingMailingAddressQuery, OnboardingMailingAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingMailingAddressQuery, OnboardingMailingAddressQueryVariables>(OnboardingMailingAddressDocument, options);
        }
export type OnboardingMailingAddressQueryHookResult = ReturnType<typeof useOnboardingMailingAddressQuery>;
export type OnboardingMailingAddressLazyQueryHookResult = ReturnType<typeof useOnboardingMailingAddressLazyQuery>;
export type OnboardingMailingAddressSuspenseQueryHookResult = ReturnType<typeof useOnboardingMailingAddressSuspenseQuery>;
export type OnboardingMailingAddressQueryResult = Apollo.QueryResult<OnboardingMailingAddressQuery, OnboardingMailingAddressQueryVariables>;
export const OnboardingMailingAddressUpdateDocument = gql`
    mutation onboardingMailingAddressUpdate($data: OnboardingMailingAddressUpdateData!) {
  onboardingMailingAddressUpdate(data: $data) {
    success
    code
    message
  }
}
    `;
export type OnboardingMailingAddressUpdateMutationFn = Apollo.MutationFunction<OnboardingMailingAddressUpdateMutation, OnboardingMailingAddressUpdateMutationVariables>;

/**
 * __useOnboardingMailingAddressUpdateMutation__
 *
 * To run a mutation, you first call `useOnboardingMailingAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingMailingAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingMailingAddressUpdateMutation, { data, loading, error }] = useOnboardingMailingAddressUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOnboardingMailingAddressUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OnboardingMailingAddressUpdateMutation, OnboardingMailingAddressUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardingMailingAddressUpdateMutation, OnboardingMailingAddressUpdateMutationVariables>(OnboardingMailingAddressUpdateDocument, options);
      }
export type OnboardingMailingAddressUpdateMutationHookResult = ReturnType<typeof useOnboardingMailingAddressUpdateMutation>;
export type OnboardingMailingAddressUpdateMutationResult = Apollo.MutationResult<OnboardingMailingAddressUpdateMutation>;
export type OnboardingMailingAddressUpdateMutationOptions = Apollo.BaseMutationOptions<OnboardingMailingAddressUpdateMutation, OnboardingMailingAddressUpdateMutationVariables>;
export const DocumentRequestResendEmailDocument = gql`
    mutation documentRequestResendEmail($data: DocumentRequestEmailResendData!) {
  documentRequestResendEmail(data: $data) {
    success
    code
    message
  }
}
    `;
export type DocumentRequestResendEmailMutationFn = Apollo.MutationFunction<DocumentRequestResendEmailMutation, DocumentRequestResendEmailMutationVariables>;

/**
 * __useDocumentRequestResendEmailMutation__
 *
 * To run a mutation, you first call `useDocumentRequestResendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentRequestResendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentRequestResendEmailMutation, { data, loading, error }] = useDocumentRequestResendEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDocumentRequestResendEmailMutation(baseOptions?: Apollo.MutationHookOptions<DocumentRequestResendEmailMutation, DocumentRequestResendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentRequestResendEmailMutation, DocumentRequestResendEmailMutationVariables>(DocumentRequestResendEmailDocument, options);
      }
export type DocumentRequestResendEmailMutationHookResult = ReturnType<typeof useDocumentRequestResendEmailMutation>;
export type DocumentRequestResendEmailMutationResult = Apollo.MutationResult<DocumentRequestResendEmailMutation>;
export type DocumentRequestResendEmailMutationOptions = Apollo.BaseMutationOptions<DocumentRequestResendEmailMutation, DocumentRequestResendEmailMutationVariables>;
export const ApplicationStatusScreenDocument = gql`
    query applicationStatusScreen($businessId: ID!) {
  me {
    id
    phone
    email
  }
  business(businessId: $businessId) {
    onboarding {
      application {
        status
      }
    }
  }
}
    `;

/**
 * __useApplicationStatusScreenQuery__
 *
 * To run a query within a React component, call `useApplicationStatusScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationStatusScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationStatusScreenQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useApplicationStatusScreenQuery(baseOptions: Apollo.QueryHookOptions<ApplicationStatusScreenQuery, ApplicationStatusScreenQueryVariables> & ({ variables: ApplicationStatusScreenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationStatusScreenQuery, ApplicationStatusScreenQueryVariables>(ApplicationStatusScreenDocument, options);
      }
export function useApplicationStatusScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationStatusScreenQuery, ApplicationStatusScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationStatusScreenQuery, ApplicationStatusScreenQueryVariables>(ApplicationStatusScreenDocument, options);
        }
export function useApplicationStatusScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApplicationStatusScreenQuery, ApplicationStatusScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApplicationStatusScreenQuery, ApplicationStatusScreenQueryVariables>(ApplicationStatusScreenDocument, options);
        }
export type ApplicationStatusScreenQueryHookResult = ReturnType<typeof useApplicationStatusScreenQuery>;
export type ApplicationStatusScreenLazyQueryHookResult = ReturnType<typeof useApplicationStatusScreenLazyQuery>;
export type ApplicationStatusScreenSuspenseQueryHookResult = ReturnType<typeof useApplicationStatusScreenSuspenseQuery>;
export type ApplicationStatusScreenQueryResult = Apollo.QueryResult<ApplicationStatusScreenQuery, ApplicationStatusScreenQueryVariables>;
export const CelebrationScreenDocument = gql`
    query celebrationScreen($businessId: ID!) {
  business(businessId: $businessId) {
    onboarding {
      accountStatus
    }
  }
}
    `;

/**
 * __useCelebrationScreenQuery__
 *
 * To run a query within a React component, call `useCelebrationScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCelebrationScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCelebrationScreenQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCelebrationScreenQuery(baseOptions: Apollo.QueryHookOptions<CelebrationScreenQuery, CelebrationScreenQueryVariables> & ({ variables: CelebrationScreenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CelebrationScreenQuery, CelebrationScreenQueryVariables>(CelebrationScreenDocument, options);
      }
export function useCelebrationScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CelebrationScreenQuery, CelebrationScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CelebrationScreenQuery, CelebrationScreenQueryVariables>(CelebrationScreenDocument, options);
        }
export function useCelebrationScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CelebrationScreenQuery, CelebrationScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CelebrationScreenQuery, CelebrationScreenQueryVariables>(CelebrationScreenDocument, options);
        }
export type CelebrationScreenQueryHookResult = ReturnType<typeof useCelebrationScreenQuery>;
export type CelebrationScreenLazyQueryHookResult = ReturnType<typeof useCelebrationScreenLazyQuery>;
export type CelebrationScreenSuspenseQueryHookResult = ReturnType<typeof useCelebrationScreenSuspenseQuery>;
export type CelebrationScreenQueryResult = Apollo.QueryResult<CelebrationScreenQuery, CelebrationScreenQueryVariables>;
export const ApplicationStatusDocument = gql`
    query applicationStatus {
  me {
    id
    ownerBusinesses {
      id
      onboarding {
        application {
          id
          status
        }
      }
    }
  }
}
    `;

/**
 * __useApplicationStatusQuery__
 *
 * To run a query within a React component, call `useApplicationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationStatusQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationStatusQuery, ApplicationStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationStatusQuery, ApplicationStatusQueryVariables>(ApplicationStatusDocument, options);
      }
export function useApplicationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationStatusQuery, ApplicationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationStatusQuery, ApplicationStatusQueryVariables>(ApplicationStatusDocument, options);
        }
export function useApplicationStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApplicationStatusQuery, ApplicationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApplicationStatusQuery, ApplicationStatusQueryVariables>(ApplicationStatusDocument, options);
        }
export type ApplicationStatusQueryHookResult = ReturnType<typeof useApplicationStatusQuery>;
export type ApplicationStatusLazyQueryHookResult = ReturnType<typeof useApplicationStatusLazyQuery>;
export type ApplicationStatusSuspenseQueryHookResult = ReturnType<typeof useApplicationStatusSuspenseQuery>;
export type ApplicationStatusQueryResult = Apollo.QueryResult<ApplicationStatusQuery, ApplicationStatusQueryVariables>;
export const PrimaryOwnerStatusDocument = gql`
    query primaryOwnerStatus {
  me {
    id
    isPrimaryOwner
  }
}
    `;

/**
 * __usePrimaryOwnerStatusQuery__
 *
 * To run a query within a React component, call `usePrimaryOwnerStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrimaryOwnerStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrimaryOwnerStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrimaryOwnerStatusQuery(baseOptions?: Apollo.QueryHookOptions<PrimaryOwnerStatusQuery, PrimaryOwnerStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrimaryOwnerStatusQuery, PrimaryOwnerStatusQueryVariables>(PrimaryOwnerStatusDocument, options);
      }
export function usePrimaryOwnerStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrimaryOwnerStatusQuery, PrimaryOwnerStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrimaryOwnerStatusQuery, PrimaryOwnerStatusQueryVariables>(PrimaryOwnerStatusDocument, options);
        }
export function usePrimaryOwnerStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PrimaryOwnerStatusQuery, PrimaryOwnerStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PrimaryOwnerStatusQuery, PrimaryOwnerStatusQueryVariables>(PrimaryOwnerStatusDocument, options);
        }
export type PrimaryOwnerStatusQueryHookResult = ReturnType<typeof usePrimaryOwnerStatusQuery>;
export type PrimaryOwnerStatusLazyQueryHookResult = ReturnType<typeof usePrimaryOwnerStatusLazyQuery>;
export type PrimaryOwnerStatusSuspenseQueryHookResult = ReturnType<typeof usePrimaryOwnerStatusSuspenseQuery>;
export type PrimaryOwnerStatusQueryResult = Apollo.QueryResult<PrimaryOwnerStatusQuery, PrimaryOwnerStatusQueryVariables>;
export const ListTeamMembersDocument = gql`
    query listTeamMembers($businessId: ID!) {
  business(businessId: $businessId) {
    teamMembers {
      id
      businessId
      firstName
      lastName
      email
      title
      type
      ownershipPercentage
      address {
        line1
        line2
        city
        state
        postalCode
        country
      }
      shippingAddress {
        line1
        line2
        city
        state
        postalCode
        country
      }
      userId
      onboardingCompleted
      isPrimaryOwner
    }
  }
}
    `;

/**
 * __useListTeamMembersQuery__
 *
 * To run a query within a React component, call `useListTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamMembersQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useListTeamMembersQuery(baseOptions: Apollo.QueryHookOptions<ListTeamMembersQuery, ListTeamMembersQueryVariables> & ({ variables: ListTeamMembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamMembersQuery, ListTeamMembersQueryVariables>(ListTeamMembersDocument, options);
      }
export function useListTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamMembersQuery, ListTeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamMembersQuery, ListTeamMembersQueryVariables>(ListTeamMembersDocument, options);
        }
export function useListTeamMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListTeamMembersQuery, ListTeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListTeamMembersQuery, ListTeamMembersQueryVariables>(ListTeamMembersDocument, options);
        }
export type ListTeamMembersQueryHookResult = ReturnType<typeof useListTeamMembersQuery>;
export type ListTeamMembersLazyQueryHookResult = ReturnType<typeof useListTeamMembersLazyQuery>;
export type ListTeamMembersSuspenseQueryHookResult = ReturnType<typeof useListTeamMembersSuspenseQuery>;
export type ListTeamMembersQueryResult = Apollo.QueryResult<ListTeamMembersQuery, ListTeamMembersQueryVariables>;
export const UpdateUserDataDocument = gql`
    mutation updateUserData($businessId: ID!, $userId: ID!, $userData: UserDataInput) {
  updateUser(businessId: $businessId, userId: $userId, userData: $userData) {
    success
  }
}
    `;
export type UpdateUserDataMutationFn = Apollo.MutationFunction<UpdateUserDataMutation, UpdateUserDataMutationVariables>;

/**
 * __useUpdateUserDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDataMutation, { data, loading, error }] = useUpdateUserDataMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      userId: // value for 'userId'
 *      userData: // value for 'userData'
 *   },
 * });
 */
export function useUpdateUserDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserDataMutation, UpdateUserDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserDataMutation, UpdateUserDataMutationVariables>(UpdateUserDataDocument, options);
      }
export type UpdateUserDataMutationHookResult = ReturnType<typeof useUpdateUserDataMutation>;
export type UpdateUserDataMutationResult = Apollo.MutationResult<UpdateUserDataMutation>;
export type UpdateUserDataMutationOptions = Apollo.BaseMutationOptions<UpdateUserDataMutation, UpdateUserDataMutationVariables>;
export const ConfirmAdditionalOwnersOnboardingStatusDocument = gql`
    mutation confirmAdditionalOwnersOnboardingStatus($businessId: ID!, $userId: ID!) {
  confirmAdditionalOwnersOnboardingStatus(
    businessId: $businessId
    userId: $userId
  ) {
    success
    status {
      onboardingCompleted
    }
    code
    message
  }
}
    `;
export type ConfirmAdditionalOwnersOnboardingStatusMutationFn = Apollo.MutationFunction<ConfirmAdditionalOwnersOnboardingStatusMutation, ConfirmAdditionalOwnersOnboardingStatusMutationVariables>;

/**
 * __useConfirmAdditionalOwnersOnboardingStatusMutation__
 *
 * To run a mutation, you first call `useConfirmAdditionalOwnersOnboardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAdditionalOwnersOnboardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAdditionalOwnersOnboardingStatusMutation, { data, loading, error }] = useConfirmAdditionalOwnersOnboardingStatusMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useConfirmAdditionalOwnersOnboardingStatusMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmAdditionalOwnersOnboardingStatusMutation, ConfirmAdditionalOwnersOnboardingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmAdditionalOwnersOnboardingStatusMutation, ConfirmAdditionalOwnersOnboardingStatusMutationVariables>(ConfirmAdditionalOwnersOnboardingStatusDocument, options);
      }
export type ConfirmAdditionalOwnersOnboardingStatusMutationHookResult = ReturnType<typeof useConfirmAdditionalOwnersOnboardingStatusMutation>;
export type ConfirmAdditionalOwnersOnboardingStatusMutationResult = Apollo.MutationResult<ConfirmAdditionalOwnersOnboardingStatusMutation>;
export type ConfirmAdditionalOwnersOnboardingStatusMutationOptions = Apollo.BaseMutationOptions<ConfirmAdditionalOwnersOnboardingStatusMutation, ConfirmAdditionalOwnersOnboardingStatusMutationVariables>;
export const JoinTeamCelebrationScreenDocument = gql`
    query JoinTeamCelebrationScreen {
  me {
    id
    isPrimaryOwner
    ownerBusinesses {
      id
    }
  }
}
    `;

/**
 * __useJoinTeamCelebrationScreenQuery__
 *
 * To run a query within a React component, call `useJoinTeamCelebrationScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoinTeamCelebrationScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoinTeamCelebrationScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useJoinTeamCelebrationScreenQuery(baseOptions?: Apollo.QueryHookOptions<JoinTeamCelebrationScreenQuery, JoinTeamCelebrationScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JoinTeamCelebrationScreenQuery, JoinTeamCelebrationScreenQueryVariables>(JoinTeamCelebrationScreenDocument, options);
      }
export function useJoinTeamCelebrationScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JoinTeamCelebrationScreenQuery, JoinTeamCelebrationScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JoinTeamCelebrationScreenQuery, JoinTeamCelebrationScreenQueryVariables>(JoinTeamCelebrationScreenDocument, options);
        }
export function useJoinTeamCelebrationScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<JoinTeamCelebrationScreenQuery, JoinTeamCelebrationScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<JoinTeamCelebrationScreenQuery, JoinTeamCelebrationScreenQueryVariables>(JoinTeamCelebrationScreenDocument, options);
        }
export type JoinTeamCelebrationScreenQueryHookResult = ReturnType<typeof useJoinTeamCelebrationScreenQuery>;
export type JoinTeamCelebrationScreenLazyQueryHookResult = ReturnType<typeof useJoinTeamCelebrationScreenLazyQuery>;
export type JoinTeamCelebrationScreenSuspenseQueryHookResult = ReturnType<typeof useJoinTeamCelebrationScreenSuspenseQuery>;
export type JoinTeamCelebrationScreenQueryResult = Apollo.QueryResult<JoinTeamCelebrationScreenQuery, JoinTeamCelebrationScreenQueryVariables>;
export const JoinTeamLayoutDocument = gql`
    query JoinTeamLayout {
  me {
    id
    isPrimaryOwner
  }
}
    `;

/**
 * __useJoinTeamLayoutQuery__
 *
 * To run a query within a React component, call `useJoinTeamLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoinTeamLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoinTeamLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useJoinTeamLayoutQuery(baseOptions?: Apollo.QueryHookOptions<JoinTeamLayoutQuery, JoinTeamLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JoinTeamLayoutQuery, JoinTeamLayoutQueryVariables>(JoinTeamLayoutDocument, options);
      }
export function useJoinTeamLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JoinTeamLayoutQuery, JoinTeamLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JoinTeamLayoutQuery, JoinTeamLayoutQueryVariables>(JoinTeamLayoutDocument, options);
        }
export function useJoinTeamLayoutSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<JoinTeamLayoutQuery, JoinTeamLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<JoinTeamLayoutQuery, JoinTeamLayoutQueryVariables>(JoinTeamLayoutDocument, options);
        }
export type JoinTeamLayoutQueryHookResult = ReturnType<typeof useJoinTeamLayoutQuery>;
export type JoinTeamLayoutLazyQueryHookResult = ReturnType<typeof useJoinTeamLayoutLazyQuery>;
export type JoinTeamLayoutSuspenseQueryHookResult = ReturnType<typeof useJoinTeamLayoutSuspenseQuery>;
export type JoinTeamLayoutQueryResult = Apollo.QueryResult<JoinTeamLayoutQuery, JoinTeamLayoutQueryVariables>;
export const JoinTeamCelebrationOnboardingCompletedDocument = gql`
    query JoinTeamCelebrationOnboardingCompleted {
  me {
    id
    onboardingCompleted
  }
}
    `;

/**
 * __useJoinTeamCelebrationOnboardingCompletedQuery__
 *
 * To run a query within a React component, call `useJoinTeamCelebrationOnboardingCompletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoinTeamCelebrationOnboardingCompletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoinTeamCelebrationOnboardingCompletedQuery({
 *   variables: {
 *   },
 * });
 */
export function useJoinTeamCelebrationOnboardingCompletedQuery(baseOptions?: Apollo.QueryHookOptions<JoinTeamCelebrationOnboardingCompletedQuery, JoinTeamCelebrationOnboardingCompletedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JoinTeamCelebrationOnboardingCompletedQuery, JoinTeamCelebrationOnboardingCompletedQueryVariables>(JoinTeamCelebrationOnboardingCompletedDocument, options);
      }
export function useJoinTeamCelebrationOnboardingCompletedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JoinTeamCelebrationOnboardingCompletedQuery, JoinTeamCelebrationOnboardingCompletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JoinTeamCelebrationOnboardingCompletedQuery, JoinTeamCelebrationOnboardingCompletedQueryVariables>(JoinTeamCelebrationOnboardingCompletedDocument, options);
        }
export function useJoinTeamCelebrationOnboardingCompletedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<JoinTeamCelebrationOnboardingCompletedQuery, JoinTeamCelebrationOnboardingCompletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<JoinTeamCelebrationOnboardingCompletedQuery, JoinTeamCelebrationOnboardingCompletedQueryVariables>(JoinTeamCelebrationOnboardingCompletedDocument, options);
        }
export type JoinTeamCelebrationOnboardingCompletedQueryHookResult = ReturnType<typeof useJoinTeamCelebrationOnboardingCompletedQuery>;
export type JoinTeamCelebrationOnboardingCompletedLazyQueryHookResult = ReturnType<typeof useJoinTeamCelebrationOnboardingCompletedLazyQuery>;
export type JoinTeamCelebrationOnboardingCompletedSuspenseQueryHookResult = ReturnType<typeof useJoinTeamCelebrationOnboardingCompletedSuspenseQuery>;
export type JoinTeamCelebrationOnboardingCompletedQueryResult = Apollo.QueryResult<JoinTeamCelebrationOnboardingCompletedQuery, JoinTeamCelebrationOnboardingCompletedQueryVariables>;
export const RejectedScreenDocument = gql`
    query RejectedScreen {
  me {
    id
    email
  }
}
    `;

/**
 * __useRejectedScreenQuery__
 *
 * To run a query within a React component, call `useRejectedScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRejectedScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRejectedScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useRejectedScreenQuery(baseOptions?: Apollo.QueryHookOptions<RejectedScreenQuery, RejectedScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RejectedScreenQuery, RejectedScreenQueryVariables>(RejectedScreenDocument, options);
      }
export function useRejectedScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RejectedScreenQuery, RejectedScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RejectedScreenQuery, RejectedScreenQueryVariables>(RejectedScreenDocument, options);
        }
export function useRejectedScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RejectedScreenQuery, RejectedScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RejectedScreenQuery, RejectedScreenQueryVariables>(RejectedScreenDocument, options);
        }
export type RejectedScreenQueryHookResult = ReturnType<typeof useRejectedScreenQuery>;
export type RejectedScreenLazyQueryHookResult = ReturnType<typeof useRejectedScreenLazyQuery>;
export type RejectedScreenSuspenseQueryHookResult = ReturnType<typeof useRejectedScreenSuspenseQuery>;
export type RejectedScreenQueryResult = Apollo.QueryResult<RejectedScreenQuery, RejectedScreenQueryVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($phone: String) {
  registerUser(phone: $phone) {
    code
    success
    message
    user {
      id
      businessId
      phone
    }
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const EmailVerifiedDocument = gql`
    query emailVerified {
  me {
    id
    emailVerified
  }
}
    `;

/**
 * __useEmailVerifiedQuery__
 *
 * To run a query within a React component, call `useEmailVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerifiedQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailVerifiedQuery(baseOptions?: Apollo.QueryHookOptions<EmailVerifiedQuery, EmailVerifiedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailVerifiedQuery, EmailVerifiedQueryVariables>(EmailVerifiedDocument, options);
      }
export function useEmailVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailVerifiedQuery, EmailVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailVerifiedQuery, EmailVerifiedQueryVariables>(EmailVerifiedDocument, options);
        }
export function useEmailVerifiedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmailVerifiedQuery, EmailVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmailVerifiedQuery, EmailVerifiedQueryVariables>(EmailVerifiedDocument, options);
        }
export type EmailVerifiedQueryHookResult = ReturnType<typeof useEmailVerifiedQuery>;
export type EmailVerifiedLazyQueryHookResult = ReturnType<typeof useEmailVerifiedLazyQuery>;
export type EmailVerifiedSuspenseQueryHookResult = ReturnType<typeof useEmailVerifiedSuspenseQuery>;
export type EmailVerifiedQueryResult = Apollo.QueryResult<EmailVerifiedQuery, EmailVerifiedQueryVariables>;
export const TermsAndConditionsScreenDocument = gql`
    query termsAndConditionsScreen {
  esignAgreement: agreementsPDF(type: ESIGN_AGREEMENT) {
    pdfURL
  }
  accountAgreement: agreementsPDF(type: ACCOUNT_AGREEMENT) {
    pdfURL
  }
  mobilePaymentServicesAgreement: agreementsPDF(
    type: MOBILE_PAYMENT_SERVICES_AGREEMENT
  ) {
    pdfURL
  }
  termsAndConditionsPDF {
    url
    documentReference
  }
}
    `;

/**
 * __useTermsAndConditionsScreenQuery__
 *
 * To run a query within a React component, call `useTermsAndConditionsScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsAndConditionsScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsAndConditionsScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useTermsAndConditionsScreenQuery(baseOptions?: Apollo.QueryHookOptions<TermsAndConditionsScreenQuery, TermsAndConditionsScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TermsAndConditionsScreenQuery, TermsAndConditionsScreenQueryVariables>(TermsAndConditionsScreenDocument, options);
      }
export function useTermsAndConditionsScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TermsAndConditionsScreenQuery, TermsAndConditionsScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TermsAndConditionsScreenQuery, TermsAndConditionsScreenQueryVariables>(TermsAndConditionsScreenDocument, options);
        }
export function useTermsAndConditionsScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TermsAndConditionsScreenQuery, TermsAndConditionsScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TermsAndConditionsScreenQuery, TermsAndConditionsScreenQueryVariables>(TermsAndConditionsScreenDocument, options);
        }
export type TermsAndConditionsScreenQueryHookResult = ReturnType<typeof useTermsAndConditionsScreenQuery>;
export type TermsAndConditionsScreenLazyQueryHookResult = ReturnType<typeof useTermsAndConditionsScreenLazyQuery>;
export type TermsAndConditionsScreenSuspenseQueryHookResult = ReturnType<typeof useTermsAndConditionsScreenSuspenseQuery>;
export type TermsAndConditionsScreenQueryResult = Apollo.QueryResult<TermsAndConditionsScreenQuery, TermsAndConditionsScreenQueryVariables>;
export const OnboardingAgreementsTermsAndConditionsAcceptDocument = gql`
    mutation onboardingAgreementsTermsAndConditionsAccept($documentReference: String!, $businessId: String!) {
  onboardingAgreementsTermsAndConditionsAccept(
    documentReference: $documentReference
    businessId: $businessId
  ) {
    code
    success
    message
  }
}
    `;
export type OnboardingAgreementsTermsAndConditionsAcceptMutationFn = Apollo.MutationFunction<OnboardingAgreementsTermsAndConditionsAcceptMutation, OnboardingAgreementsTermsAndConditionsAcceptMutationVariables>;

/**
 * __useOnboardingAgreementsTermsAndConditionsAcceptMutation__
 *
 * To run a mutation, you first call `useOnboardingAgreementsTermsAndConditionsAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingAgreementsTermsAndConditionsAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingAgreementsTermsAndConditionsAcceptMutation, { data, loading, error }] = useOnboardingAgreementsTermsAndConditionsAcceptMutation({
 *   variables: {
 *      documentReference: // value for 'documentReference'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useOnboardingAgreementsTermsAndConditionsAcceptMutation(baseOptions?: Apollo.MutationHookOptions<OnboardingAgreementsTermsAndConditionsAcceptMutation, OnboardingAgreementsTermsAndConditionsAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardingAgreementsTermsAndConditionsAcceptMutation, OnboardingAgreementsTermsAndConditionsAcceptMutationVariables>(OnboardingAgreementsTermsAndConditionsAcceptDocument, options);
      }
export type OnboardingAgreementsTermsAndConditionsAcceptMutationHookResult = ReturnType<typeof useOnboardingAgreementsTermsAndConditionsAcceptMutation>;
export type OnboardingAgreementsTermsAndConditionsAcceptMutationResult = Apollo.MutationResult<OnboardingAgreementsTermsAndConditionsAcceptMutation>;
export type OnboardingAgreementsTermsAndConditionsAcceptMutationOptions = Apollo.BaseMutationOptions<OnboardingAgreementsTermsAndConditionsAcceptMutation, OnboardingAgreementsTermsAndConditionsAcceptMutationVariables>;