import { ArrowIcon, Body, BodySizeEnum, Box, PrimaryButton } from '@northone/ui-components'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import { analytics } from '@/core/analytics/events'
import { useUserContext } from '@/core/auth/user-context'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { WAIT_FALSE_SEARCH_PARAM } from '../manual-review-in-app-document-capture'

export const InAppDocumentCaptureThankYou = () => {
  const navigate = useNavigate()
  const businessId = useAppSelector((state) => state.application.businessId)
  const t = useOnboardingTranslations()
  const userData = useUserContext()
  const email = userData?.email

  useEffect(() => {
    if (!businessId) return
    analytics.inAppDocumentUpload.thankYouScreenLoaded({ businessId })
  }, [businessId])

  const onClickReviewDocs = () => {
    analytics.inAppDocumentUpload.thankYouScreenReviewDocumentsClick({ businessId })
    navigate(`${Pathname.DOCUMENT_UPLOAD_FORM}?${WAIT_FALSE_SEARCH_PARAM}`)
  }

  return (
    <BaseContentLayout
      primaryButton={
        <PrimaryButton
          onPress={onClickReviewDocs}
          testID="document-capture-review-uploaded-documents"
          icon={ArrowIcon}
          iconPosition="right"
        >
          {t('inAppDocumentCapture.thankYou.buttons.review')}
        </PrimaryButton>
      }
    >
      <Box
        sx={{
          height: '$full',
          gap: '$8',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          marginVertical: 'auto',
        }}
      >
        <ResponsiveOnboardingHeader>{t('inAppDocumentCapture.thankYou.title')}</ResponsiveOnboardingHeader>
        {email && (
          <Body size={BodySizeEnum.MD}>
            {t('inAppDocumentCapture.thankYou.subTitle1')}
            <Body size={BodySizeEnum.MD} color={'$charcoal7'}>
              {email}
            </Body>
            {t('inAppDocumentCapture.thankYou.subTitle2')}
          </Body>
        )}
      </Box>
    </BaseContentLayout>
  )
}
