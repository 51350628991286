import { applicationActions } from '@northone-onboarding/frontend-new/src/core/redux/application-redux/application-actions'
import { useAppSelector } from '@northone-onboarding/frontend-new/src/core/redux/utils'
import { Tooltip } from '@northone/ui-components'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import WhyDoWeCollectThisInfo from '@/components/WhyDoWeCollectThisInfo'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { OwnerProfileForm } from './OwnerProfileForm'
import { useOwnerProfileAnalytics } from './utils/owner-profile-analytics'
import { useOwnerProfileValidation } from './utils/owner-validation-hooks'

export const OwnershipAddOwner = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const ownerId = params.ownerId
  if (!ownerId) throw new Error('OwnerId not found')

  const { sendAnalyticsEvent } = useOwnerProfileAnalytics(ownerId)
  const t = useOnboardingTranslations()
  const navigate = useNavigate()

  const heading = t('ownership.profile.coOwnerHeading')

  const { hasError, showErrors, setShowErrors } = useOwnerProfileValidation({ ownerId })

  const owner = useAppSelector((state) => state.application.owners)[ownerId]
  const ownerSSN = useAppSelector((state) => state.unpersisted.ssns)[ownerId]
  if (!owner) return null

  const {
    email,
    birthdate,
    jobTitle,
    firstName,
    lastName,
    phoneNumber,
    ownershipPercentage,
    address: { state, streetAddress, city, zipCode },
  } = owner
  const formIsEmpty =
    ![ownerSSN, email, birthdate, jobTitle, firstName, lastName, phoneNumber, state, streetAddress, city, zipCode].some(
      (val) => Boolean(val),
    ) && ownershipPercentage === '0'

  const onContinue = () => {
    setShowErrors(true)
    if (hasError) return
    sendAnalyticsEvent()
    navigate(Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS)
  }
  const onBack = () => {
    if (formIsEmpty) dispatch(applicationActions.deleteOwner(ownerId))
    navigate(Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS)
  }

  const whyCollectOtherAccountHolderInfoContent = t('ownership.profile.tooltipWhyCollectOtherAccountHolderInfoContent')
  const whyDoWeCollectThisInformation = t('tooltip.whyDoWeCollectThisInformation')

  const TooltipWhyDoWeCollect = () => (
    <Tooltip content={whyCollectOtherAccountHolderInfoContent} title={whyDoWeCollectThisInformation}>
      <WhyDoWeCollectThisInfo />
    </Tooltip>
  )

  const primaryButton = <ContinueButton testID="owner-profiles-edit-owner-continue" onPress={onContinue} fullWidth />
  const secondaryButton = <GoBackButton testID="owner-profiles-edit-owner-back" onPress={onBack} fullWidth />

  return (
    <BaseContentLayout
      tooltipSubheading={<TooltipWhyDoWeCollect />}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      headingText={heading}
      titleZIndex={3}
    >
      <OwnerProfileForm ownerId={ownerId} shouldShowValidationErrors={showErrors} />
    </BaseContentLayout>
  )
}
