import { axios } from '@northone/axios'
import * as Sentry from '@sentry/react'
import { Dispatch } from 'react'
import { AnyAction, Middleware, MiddlewareAPI } from 'redux'
import rootPackageJson from '../../../../../package.json'
import { config } from '../../utils/environment'
import { getAuthService } from '../auth/auth-service'
import { IRootState } from '../redux/root-state'

export const REDUX_VERSION_NUMBER = 7

const getTokenOrLogout = async (): Promise<string | null> => {
  const authService = getAuthService()
  if (!authService) {
    throw new Error('Failed to find the auth service')
  }
  return authService.renewTokenOrLogout()
}

const clearState = async (): Promise<void> => {
  try {
    const token = await getTokenOrLogout()
    if (!token) return

    await axios.post(
      config.onboardingBackendUrl,
      {
        onboardingData: JSON.stringify({}),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    return
  } catch (e) {
    Sentry.captureException(e)
  }
}

const saveState = async (store: MiddlewareAPI<any, IRootState>): Promise<void> => {
  try {
    const state = store.getState()
    const token = await getTokenOrLogout()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { unpersisted: _unpersisted, ...restOfState } = state

    const response = await axios.post<{ success: boolean }>(
      config.onboardingBackendUrl,
      {
        onboardingData: JSON.stringify({
          ...restOfState,
          reduxVersion: REDUX_VERSION_NUMBER,
          appVersion: rootPackageJson.version,
        }),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )

    if (!response.data.success) {
      console.debug('Failed to save state to backend... retrying')
      await saveState(store)
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}

const getState = async (): Promise<IRootState & { appVersion: string; reduxVersion: number }> => {
  try {
    const token = await getTokenOrLogout()
    const response = await axios.get(config.onboardingBackendUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    return JSON.parse(response.data.onboardingData)
  } catch (e) {
    Sentry.captureException(e)
    throw e
  }
}

let saveTimer: number | ReturnType<typeof setTimeout>
const DEBOUNCE_TIME_IN_MS = 1000

const saveDebounce = (store: MiddlewareAPI<any, IRootState>): void => {
  if (saveTimer) {
    clearTimeout(+saveTimer)
  }
  saveTimer = setTimeout(() => {
    const state = store.getState()
    if (state.application.userId && state.unpersisted.hasHydratedState) {
      persistState.save(store)
    }
  }, DEBOUNCE_TIME_IN_MS)
}

export const persistDataToBackendMiddleware: Middleware<unknown, IRootState, any> = (store) => {
  return (next) =>
    (action): Dispatch<AnyAction> => {
      saveDebounce(store)
      // @ts-ignore TS2345
      return next(action)
    }
}

export const persistState = {
  get: getState,
  save: saveState,
  clear: clearState,
}
