import { AlertTypeEnum, Body, Box, ICONS, InlineAlert, PrimaryButton } from '@northone/ui-components'
import { useEffect, useState } from 'react'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import StandardDisclaimer from '@/components/StandardDisclaimer'
import {
  useConfirmAdditionalOwnersOnboardingStatusMutation,
  useJoinTeamCelebrationOnboardingCompletedQuery,
  useJoinTeamCelebrationScreenSuspenseQuery,
} from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { ExpectationScreenLayout } from '@/layouts/ExpectationScreenLayout'
import { navigateToWebBanking } from '../finish-up/utils'

export const JoinTeamCelebration = () => {
  const t = useOnboardingTranslations()
  const [shouldShowError, setShouldShowError] = useState(false)

  const title = t('finishUp.additionalOwnerWelcomeToNorthOne.title')
  const note = t('finishUp.additionalOwnerWelcomeToNorthOne.note')
  const getStarted = t('finishUp.additionalOwnerWelcomeToNorthOne.getStarted')
  const errorText = t('error.generic')

  const { data } = useJoinTeamCelebrationScreenSuspenseQuery()

  const userId = data.me?.id
  const businessId = data.me?.ownerBusinesses[0]?.id
  const userIsPrimaryOwner = data.me?.isPrimaryOwner

  const { stopPolling: stopPollingForOnboardingCompletedStatus, loading: isOnboardingStatusQueryLoading } =
    useJoinTeamCelebrationOnboardingCompletedQuery({
      notifyOnNetworkStatusChange: true,
      pollInterval: 2000,
      onCompleted(data) {
        const onboardingCompleted = data.me?.onboardingCompleted
        if (!onboardingCompleted) return
        stopPollingForOnboardingCompletedStatus()
      },
      onError: () => {
        setShouldShowError(true)
      },
    })

  const [confirmAdditionalOwnersOnboardingStatusMutation, { loading }] =
    useConfirmAdditionalOwnersOnboardingStatusMutation({
      onCompleted: (data) => {
        const isOnboardingCompleted = data.confirmAdditionalOwnersOnboardingStatus?.status?.onboardingCompleted

        if (!isOnboardingCompleted) {
          setShouldShowError(true)
          return
        }
      },
      onError: () => {
        throw new Error('Error confirming additional owner onboarding status')
      },
    })

  useEffect(() => {
    setShouldShowError(false)
    if (!userId || !businessId || userIsPrimaryOwner) return
    confirmAdditionalOwnersOnboardingStatusMutation({ variables: { userId, businessId } })
  }, [confirmAdditionalOwnersOnboardingStatusMutation, userId, userIsPrimaryOwner, businessId])

  return (
    <ExpectationScreenLayout disclaimerComponent={<StandardDisclaimer />}>
      <Box sx={{ gap: '$6', justifyContent: 'flex-start' }}>
        {shouldShowError && <InlineAlert type={AlertTypeEnum.Error}>{errorText}</InlineAlert>}

        <ResponsiveOnboardingHeader largerSize="md">{title}</ResponsiveOnboardingHeader>

        <Box sx={{ gap: '$4' }}>
          <Body>{note}</Body>
        </Box>

        <Box sx={{ gap: '$8' }}>
          <PrimaryButton
            testID="join-team-continue"
            onPress={navigateToWebBanking}
            iconPosition="right"
            icon={ICONS.ArrowIcon}
            loading={loading || isOnboardingStatusQueryLoading}
            disabled={loading}
            fullWidth
          >
            {getStarted}
          </PrimaryButton>
        </Box>
      </Box>
    </ExpectationScreenLayout>
  )
}
