import { Auth0Client, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'
import { analytics as segmentAnalytics } from '@northone/segment-js'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'

import { ErrorCodeEnum, useRegisterUserMutation, useResendAuth0VerificationEmailMutation } from '@/generated/graphql'

import { AUTH0_USER_METADATA_URL } from '../../utils'
import { config } from '../../utils/environment'
import { getURLSearchParameter } from '../../utils/url-helpers'
import { analytics } from '../analytics/events'
import { client } from '../apollo/apollo-client'
import { hasGQLErrorType } from '../apollo/utils'
import { getHotjarClient } from '../hotjar'
import { applicationActions } from '../redux/application-redux/application-actions'
import { PRIMARY_OWNER_ID } from '../redux/application-redux/application-state'
import { IAuthService } from './auth-service'

// https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
export enum Auth0Error {
  LOGIN_REQUIRED = 'login_required',
  BLOCKED = 'unauthorized',
}

interface Auth0UserContext {
  email: string
  email_verified: boolean
  [AUTH0_USER_METADATA_URL]?: Auth0UserMetadata
}

interface Auth0UserMetadata {
  papi_otp?: string
  partner_id?: string
  utm_campaign?: string
  mobile_phone?: string
  signup_promo_code?: string
  utm_source?: string
  utm_term?: string
  utm_medium?: string
  utm_content?: string
  utm_creative?: string
}

const BASE_SCOPES = 'openid email profile email address phone'

const auth0 = new Auth0Client({
  domain: config.auth0.domain,
  client_id: config.auth0.clientId,
  redirect_uri: window.location.origin,
  audience: 'northoneCoreApi',
  advancedOptions: {
    defaultScope: BASE_SCOPES,
  },
})
export const getAuth0 = async (): Promise<Auth0Client> => auth0

// Make Auth0 client available on window for logout capabilities in e2e tests
window.auth = auth0

/**
 * helper function to log the user out and clear application state
 */
let loggedOut = false
const logout = async (): Promise<void> => {
  loggedOut = true
  analytics.session.end({ deviceType: isMobile ? 'mobile' : 'desktop' })
  segmentAnalytics().reset()
  client.resetStore().finally(() => {
    auth0.logout({ returnTo: `${window.location.origin}?logout=true` })
  })
}

/**
 * helper function to redirect the user to login, forwarding URL search params
 * @param mode mode paramter to pass to SSO page
 */
const redirectToLogin = async (mode = 'login'): Promise<void> => {
  const signupPromoCode = getURLSearchParameter('pc')
  const urlSearchParams = new URLSearchParams(window.location.search)
  // Prevent getting caught in a logout loop
  urlSearchParams.delete('logout')
  const redirectUri = new URL(window.location.origin)
  redirectUri.search = urlSearchParams.toString()

  return auth0.loginWithRedirect({
    mode,
    redirect_uri: redirectUri.toString(),
    ...urlSearchParams,
    appState: {
      // This ensures that the promo code is returned when auth.handleRedirectCallback is called
      // on redirect back to JoinDot
      // Required for storing promo code on login
      pc: signupPromoCode,
    },
  })
}

/**
 * attempts to get an access token and throws if it fails
 */
const getTokenSilently = async (params: { skipCache?: boolean } = {}): Promise<string | null> => {
  // IMPORTANT: sessions can stay alive if a token is requested during logout
  // see: https://bitbucket.org/northone/northone-web-banking/pull-requests/365/np-5720-return-null-token-after-logout
  if (loggedOut) {
    return null
  }

  // scopes are lost on refresh token grant, so we pass it in our own param.
  // https://github.com/auth0/auth0-spa-js/pull/463
  const options: GetTokenSilentlyOptions = {
    ignoreCache: params.skipCache,
  }

  return await auth0.getTokenSilently(options)
}

/**
 * returns an access token with all included scopes or logs the user out
 */
const renewTokenOrLogout = async (): Promise<string | null> => {
  try {
    const token = await getTokenSilently({ skipCache: true })
    if (!token) {
      logout()
      return null
    }
    return token
  } catch (error) {
    logout()
    return null
  }
}

type Auth0UserData = {
  email: string
  emailVerified: boolean
  metadata?: Auth0UserMetadata
}

const getAuth0UserData = async (): Promise<Auth0UserData> => {
  const user = (await auth0.getUser()) as unknown as Auth0UserContext | undefined
  if (!user) throw new Error('Could not get user from Auth0')

  const metadata = user[AUTH0_USER_METADATA_URL]

  return {
    metadata,
    email: user.email,
    emailVerified: user.email_verified,
  }
}
const useAuth0UserData = (): Auth0UserData | null => {
  const [auth0UserData, setAuth0UserData] = useState<Auth0UserData | null>(null)

  useEffect(() => {
    getAuth0UserData().then(setAuth0UserData)
  }, [])

  return auth0UserData
}

const getUserMetadata = async (): Promise<Record<string, string>> => {
  const userData = await getAuth0UserData()
  return {
    ...userData.metadata,
  }
}

const useRegisterUser = () => {
  const dispatch = useDispatch()
  const auth0UserData = useAuth0UserData()
  const [registerUserLoading, setRegisterUserIsLoading] = useState(false)
  const mobilePhone = auth0UserData?.metadata?.mobile_phone
  const email = auth0UserData?.email
  const hotjar = getHotjarClient()

  // We can call this mutation multiple times and a user will only be registered once
  const [registerUserMutation] = useRegisterUserMutation({
    variables: { phone: mobilePhone },
    onCompleted: (data) => {
      if (!data.registerUser?.success) return

      const phone = data.registerUser.user?.phone
      const businessId = data.registerUser.user?.businessId ?? ''
      const userId = data.registerUser.user?.id ?? ''

      businessId && dispatch(applicationActions.setBusinessId(businessId))
      dispatch(applicationActions.setUserId(userId))
      if (email) {
        dispatch(
          applicationActions.updateOwner({
            updatedOwnerFields: { email },
            ownerId: PRIMARY_OWNER_ID,
          }),
        )
      }

      if (phone) {
        dispatch(
          applicationActions.updateOwner({
            updatedOwnerFields: { phoneNumber: phone },
            ownerId: PRIMARY_OWNER_ID,
          }),
        )
      }

      hotjar.identify(userId, { businessId })
      segmentAnalytics().alias(userId, () => {
        segmentAnalytics().identify(
          userId,
          {
            email,
            phone,
            businessId,
            partnerId: auth0UserData?.metadata?.partner_id,
            partnerName: auth0UserData?.metadata?.utm_campaign,
          },
          {
            campaign: {
              name: auth0UserData?.metadata?.utm_campaign,
              source: auth0UserData?.metadata?.utm_source,
              term: auth0UserData?.metadata?.utm_term,
              medium: auth0UserData?.metadata?.utm_medium,
              content: auth0UserData?.metadata?.utm_content,
              creative: auth0UserData?.metadata?.utm_creative,
            },
          },
        )
      })
    },
    onError: (error) => {
      if (hasGQLErrorType(error, ErrorCodeEnum.AUTH_USER_ALREADY_EXISTS)) {
        setRegisterUserIsLoading(false)
        return
      }
      throw new Error('Error registering user')
    },
  })

  return {
    registerUser: async () => {
      setRegisterUserIsLoading(true)
      await registerUserMutation()
        .catch((err) => {
          throw err
        })
        .finally(() => {
          setRegisterUserIsLoading(false)
        })
    },
    registerUserLoading: registerUserLoading,
  }
}

const useResendVerificationEmail = ({ email }: { email: string }) => {
  const [resendVerificationEmailMutation, { loading, error }] = useResendAuth0VerificationEmailMutation()

  const resendVerificationEmail = async (data: { reCaptchaToken?: string } = {}) => {
    if (!email) return
    await resendVerificationEmailMutation({ variables: { email, reCaptchaToken: data.reCaptchaToken } })
  }

  return { resendVerificationEmail, resendVerificationEmailLoading: loading, resendVerificationEmailError: error }
}

export const Auth0AuthService: IAuthService = {
  getTokenSilently,
  logout,
  redirectToLogin,
  renewTokenOrLogout,
  getUserMetadata,
  useRegisterUser,
  useResendVerificationEmail,
}
