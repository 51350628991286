import { Body, Box, Modal, SecondaryButton, SectionHeader, SectionHeaderSizeEnum } from '@northone/ui-components'
import { useState } from 'react'
import { Pathname as RouterPathname, useNavigate } from 'react-router-dom'
import { persistState } from '@/core/persist-state'
import { Pathname } from '@/routes/constants'
import { useAuthService } from './core/auth/auth-service'
import { useAppSelector } from './core/redux/utils'
import { useRequiredManualReviewFiles } from './routes/document-upload/use-file-upload-navigation'

const NavButton = ({ pathname, onClick }: { pathname: RouterPathname; onClick: () => void }) => {
  const navigate = useNavigate()
  const pathnameDisplay = pathname.replace('/', '').replace('-', ' ').toLocaleUpperCase()
  return (
    <SecondaryButton
      testID={`dev-helper-button-${pathname}`}
      onPress={() => {
        onClick()
        navigate(pathname)
      }}
    >
      {pathnameDisplay}
    </SecondaryButton>
  )
}
export const DevHelpers = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { businessId } = useAppSelector((state) => state.application)
  const requiredManualReviewDocs = useRequiredManualReviewFiles()
  const authService = useAuthService()

  const pathnames = [
    Pathname.WELCOME_GETTING_STARTED,
    Pathname.BUSINESS_DETAILS,
    Pathname.OWNERSHIP_DETAILS,
    Pathname.USAGE_QUESTIONS,
    Pathname.FINISH_UP_WELCOME_SCREEN,
  ]

  return (
    <>
      <div
        data-test-id="devHelpers"
        style={{ position: 'fixed', bottom: 0, right: 0, height: 20, width: 20, cursor: 'pointer', zIndex: 100000 }}
        onClick={() => setIsOpen(true)}
      />
      <Modal isVisible={isOpen}>
        <SectionHeader size={SectionHeaderSizeEnum.SM} headingLevel={3}>
          Dev Helpers
        </SectionHeader>
        <Body>Business ID: {businessId}</Body>
        {requiredManualReviewDocs ? (
          <Body>Required Manual Review Files: {requiredManualReviewDocs.join(', ')}</Body>
        ) : null}
        <Body>Navigate to:</Body>
        <Box sx={{ marginBottom: 10, flexWrap: 'wrap' }}>
          {pathnames.map((pathname) => (
            <NavButton key={pathname} onClick={() => setIsOpen(false)} pathname={pathname} />
          ))}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
          <SecondaryButton testID="logoutDevHelpers" onPress={authService.logout}>
            Logout
          </SecondaryButton>
          <SecondaryButton
            testID="dev-helpers-clear-state"
            onPress={async () => {
              await persistState.clear()
              window.location.reload()
            }}
          >
            Clear state
          </SecondaryButton>

          <SecondaryButton
            testID="dev-helpers-close"
            onPress={() => {
              setIsOpen(false)
            }}
          >
            Close
          </SecondaryButton>
        </Box>
      </Modal>
    </>
  )
}
