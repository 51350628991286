import {
  Box,
  Checkbox,
  DropdownSmall,
  Link,
  NumberInput,
  NumberInputProps,
  PhoneNumberInput,
  PhoneNumberInputProps,
  PrimaryButtonProps,
  SecondaryButtonProps,
  TextInput,
  Tooltip,
} from '@northone/ui-components'
import { isEqual } from 'lodash'
import { ComponentProps, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import AddressAutocompleteInput from '@/components/AddressAutocompleteInput'
import ContinueButton from '@/components/ContinueButton'
import FieldsetContainer from '@/components/FieldsetContainer'
import GoBackButton from '@/components/GoBackButton'
import WhyDoWeCollectThisInfo from '@/components/WhyDoWeCollectThisInfo'
import { analytics } from '@/core/analytics/events'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { getBusinessAddress, getBusinessPhoneNumber } from '@/core/redux/application-redux/application-selectors'
import { IAddressState, initialAddressState } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { americanStates, format } from '@/utils'
import { numberStringToOptionalNumber } from '@/utils/numberStringToOptionalNumber'

import { useBusinessContactInformationValidation } from '../hooks/useBusinessContactInformationValidation'

enum AddressStateField {
  STREET = 'streetAddress',
  SUITE = 'suite',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zipCode',
}

export const BusinessContactInformationForm = () => {
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const [hasClickedEnterAddressManually, setHasClickedEnterAddressManually] = useState(false)
  const [addressAutocompleteErrorText, setAddressAutocompleteErrorText] = useState<string>()
  const { inputProps, buttonProps } = useBusinessContactInformationFieldProps()
  const isSameBusinessAndHomeAddressCheckBoxSelected = useAppSelector(
    (state) => state.application.selectedSameBusinessAndHomeAddressCheckbox,
  )

  const handleSameBusinessAndHomeAddressCheckboxChange = () => {
    dispatch(applicationActions.setSameBusinessAndHomeAddressCheck(!isSameBusinessAndHomeAddressCheckBoxSelected))
  }

  const onAddressSelected = (address: IAddressState) => {
    setAddressAutocompleteErrorText(undefined)
    dispatch(applicationActions.setBusinessAddress(address))
  }
  const hasBusinessAddress = !isEqual(
    useAppSelector((state) => state.application.businessAddress),
    initialAddressState,
  )

  const title = t('applicationForm.businessContactInfo.title')
  const whyDoWeCollectThisInformation = t('tooltip.whyDoWeCollectThisInformation')
  const whyDoWeCollectBusinessContactInfo = t('businessDetails.contactInfo.whyDoWeCollectBusinessInfo')
  const addressAutocompleteLabel = t('businessDetails.contactInfo.addressAutocompleteLabel')
  const addressAutocompletePlaceholder = t('businessDetails.contactInfo.addressAutocompletePlaceholder')
  const addressAutocompleteHelperText = t('inputs.addressAutocompleteHelperText')
  const addressAutocompleteRequiredErrorText = t('inputs.addressAutocompleteRequiredText')
  const enterAddressManuallyText = t('inputs.addressAutocompleteEnterAddressManually')
  const sameBusinessAndHomeAddressCheckboxLabel = t('applicationForm.businessContactInfo.sameAddressCheckboxLabel')

  const onPressEnterManually = () => {
    setAddressAutocompleteErrorText(undefined)
    setHasClickedEnterAddressManually(true)
  }

  const onContinue = () => {
    buttonProps.continue.onPress()
    setAddressAutocompleteErrorText(undefined)
    if (!hasBusinessAddress && !hasClickedEnterAddressManually) {
      setAddressAutocompleteErrorText(addressAutocompleteRequiredErrorText)
    }
  }

  const shouldShowAddressFields = hasClickedEnterAddressManually || hasBusinessAddress

  const TooltipWhyDoWeCollect = () => (
    <Tooltip content={whyDoWeCollectBusinessContactInfo} title={whyDoWeCollectThisInformation}>
      <WhyDoWeCollectThisInfo />
    </Tooltip>
  )

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={<ContinueButton {...buttonProps.continue} onPress={onContinue} />}
      secondaryButton={<GoBackButton {...buttonProps.back} />}
      showProgressAutoSavedNote
    >
      <TooltipWhyDoWeCollect />
      <FieldsetContainer>
        <Box sx={{ zIndex: 1 }}>
          <PhoneNumberInput {...inputProps.phoneNumber} />
        </Box>
      </FieldsetContainer>

      <Box sx={{ zIndex: 2, gap: '$4' }}>
        <AddressAutocompleteInput
          label={addressAutocompleteLabel}
          helperText={addressAutocompleteHelperText}
          errorText={addressAutocompleteErrorText}
          onSelect={onAddressSelected}
          placeholder={addressAutocompletePlaceholder}
        />
        {!shouldShowAddressFields && <Link onPress={onPressEnterManually}>{enterAddressManuallyText}</Link>}
        <Checkbox
          testID="same-address-checkbox"
          value="same-address-checkbox"
          labelText={sameBusinessAndHomeAddressCheckboxLabel}
          isChecked={isSameBusinessAndHomeAddressCheckBoxSelected ?? false}
          onChange={handleSameBusinessAndHomeAddressCheckboxChange}
        ></Checkbox>
      </Box>

      {shouldShowAddressFields && (
        <Box sx={{ zIndex: 1, gap: '$4' }}>
          <TextInput {...inputProps.streetAddress} />

          <TextInput {...inputProps.suiteNumber} />

          <TextInput {...inputProps.city} />

          <Box sx={{ flexDirection: 'row', gap: '$4', zIndex: 2 }}>
            <Box sx={{ flex: 1 }}>
              <DropdownSmall {...inputProps.americanState} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextInput {...inputProps.zipCode} />
            </Box>
          </Box>
        </Box>
      )}
      <NumberInput {...inputProps.yearsAtBusinessAddress} />
    </BaseContentLayout>
  )
}

type TextInputProps = ComponentProps<typeof TextInput>
const useBusinessContactInformationFieldProps = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const businessAddress = useAppSelector(getBusinessAddress)
  const { streetAddress, suite, city, state, zipCode, coordinates, description } = businessAddress
  const businessPhoneNumber = useAppSelector(getBusinessPhoneNumber)
  const yearsAtLocation = useAppSelector((state) => state.application.yearsAtLocation)
  const t = useOnboardingTranslations()
  const isSameBusinessAndHomeAddressCheckBoxChecked = useAppSelector(
    (state) => state.application.selectedSameBusinessAndHomeAddressCheckbox,
  )

  const updateBusinessContactInformation = (number: string, address: IAddressState, yearsAtLocation?: string) => {
    dispatch(applicationActions.setBusinessPhoneNumber(number))
    dispatch(applicationActions.setBusinessAddress(address))
    yearsAtLocation && dispatch(applicationActions.setYearsAtLocation(yearsAtLocation.toString()))
  }

  const updateBusinessAddressField = (val: string, key: Partial<keyof Omit<IAddressState, 'coordinates'>>) => {
    const updatedBusinessAddress = { ...businessAddress, coordinates, description }
    updatedBusinessAddress[key] = val
    dispatch(applicationActions.setBusinessAddress(updatedBusinessAddress))
  }

  const { hasError, setShowErrors, fieldErrors } = useBusinessContactInformationValidation()

  const onSubmitForm = () => {
    setShowErrors(true)
    if (hasError) return

    updateBusinessContactInformation(businessPhoneNumber, businessAddress, yearsAtLocation)
    analytics.application.fillBusinessPhoneNumber({ phoneNumber: businessPhoneNumber })
    analytics.application.fillBusinessAddress({ address: businessAddress })
    analytics.application.fillYearsAtAddress({ years: yearsAtLocation })
    analytics.application.fillSameAddress({ sameAddress: isSameBusinessAndHomeAddressCheckBoxChecked ?? false })
    navigate(Pathname.BUSINESS_DETAILS_EXPECTED_REVENUE)
  }
  const onPressGoBack = () => navigate(Pathname.BUSINESS_DETAILS_DESCRIPTION_AND_INDUSTRY)

  const phoneNumberInputLabel = t('applicationForm.businessContactInfo.phoneInputLabel')
  const streetAddressInputLabel = t('applicationForm.businessContactInfo.addressInputLabel')
  const suiteNumberInputLabel = t('applicationForm.businessContactInfo.suiteNumberInputLabel')
  const cityInputLabel = t('applicationForm.businessContactInfo.addressInputs.city')
  const stateInputLabel = t('applicationForm.businessContactInfo.addressInputs.state')
  const zipCodeInputLabel = t('applicationForm.businessContactInfo.addressInputs.zip')
  const yearAtAddressInputLabel = t('applicationForm.businessContactInfo.businessOperation.label')
  const yearsAtAddressHelper = t('applicationForm.businessContactInfo.businessOperation.helper')

  const phoneNumberInputProps: PhoneNumberInputProps = {
    testID: 'phone',
    onChange: (val: string) => {
      dispatch(applicationActions.setBusinessPhoneNumber(format.phoneNumber(val) ?? ''))
    },
    value: businessPhoneNumber,
    labelText: phoneNumberInputLabel,
    errorText: fieldErrors.businessPhoneNumber,
  }

  const streetAddressInputProps: TextInputProps = {
    testID: 'street-address',
    onChange: (val: string) => {
      updateBusinessAddressField(val, AddressStateField.STREET)
    },
    value: streetAddress,
    labelText: streetAddressInputLabel,
    errorText: fieldErrors.streetAddress,
  }

  const suiteNumberInputProps: TextInputProps = {
    testID: 'suite',
    value: suite,
    onChange: (val: string) => {
      updateBusinessAddressField(val, AddressStateField.SUITE)
    },
    labelText: suiteNumberInputLabel,
    errorText: fieldErrors.suiteNumber,
  }

  const cityInputProps: TextInputProps = {
    testID: 'city',
    value: city,
    onChange: (val: string) => {
      updateBusinessAddressField(val, AddressStateField.CITY)
    },
    labelText: cityInputLabel,
    errorText: fieldErrors.city,
  }
  const americanStateInputProps: ComponentProps<typeof DropdownSmall> = {
    value: state,
    onChange: (val: string) => {
      updateBusinessAddressField(val.toString(), AddressStateField.STATE)
    },
    options: americanStates,
    labelText: stateInputLabel,
    errorText: fieldErrors.americanState,
    placeholderProps: {
      text: '',
    },
  }
  const zipCodeInputProps: TextInputProps = {
    testID: 'zip',
    value: zipCode,
    onChange: (val: string) => {
      updateBusinessAddressField(val, AddressStateField.ZIP)
    },
    labelText: zipCodeInputLabel,
    errorText: fieldErrors.zipCode,
  }
  const yearsAtBusinessAddressInputProps: NumberInputProps = {
    testID: 'years-at-address',
    value: numberStringToOptionalNumber(yearsAtLocation),
    onChange: (val?: number) => dispatch(applicationActions.setYearsAtLocation(val?.toString() ?? '')),
    placeholder: '',
    labelText: yearAtAddressInputLabel,
    helperText: yearsAtAddressHelper,
    errorText: fieldErrors.yearsAtBusinessAddress,
  }
  const continueButtonProps: PrimaryButtonProps = {
    children: 'Continue',
    onPress: onSubmitForm,
    fullWidth: true,
    testID: 'business-contact-information-continue',
  }
  const goBackButtonProps: SecondaryButtonProps = {
    children: 'Go back',
    onPress: onPressGoBack,
    fullWidth: true,
    testID: 'business-contact-information-go-back',
  }

  return {
    inputProps: {
      phoneNumber: phoneNumberInputProps,
      streetAddress: streetAddressInputProps,
      suiteNumber: suiteNumberInputProps,
      city: cityInputProps,
      americanState: americanStateInputProps,
      zipCode: zipCodeInputProps,
      yearsAtBusinessAddress: yearsAtBusinessAddressInputProps,
      description,
      coordinates,
    },
    buttonProps: {
      continue: continueButtonProps,
      back: goBackButtonProps,
    },
  } as const
}
