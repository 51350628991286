/* eslint-disable no-unused-vars */
import { makeVar } from '@apollo/client'
import { createContext, useContext } from 'react'

export type AuthenticationError = 'unauthorized' | 'login_required' | 'timeout'

export interface IAuthService {
  getTokenSilently: (params?: { skipCache?: boolean }) => Promise<string | null>
  logout: () => Promise<void>
  redirectToLogin: (mode?: string) => Promise<void>
  renewTokenOrLogout: () => Promise<string | null>
  getUserMetadata: () => Promise<Record<string | symbol | number, string>>
  useRegisterUser: () => { registerUser: () => Promise<void>; registerUserLoading: boolean }
  useResendVerificationEmail: ({ email }: { email: string }) => {
    resendVerificationEmail: (data: { reCaptchaToken?: string } | undefined) => Promise<void>
    resendVerificationEmailLoading: boolean
    resendVerificationEmailError: Error | undefined
  }
}

export const AuthServiceContext = createContext<IAuthService | null>(null)
export const useAuthService = () => {
  const authService = useContext(AuthServiceContext)
  if (!authService) {
    throw new Error('useAuthService must be used within an AuthProvider')
  }
  return authService
}

/**
 * Apollo client variable to store the auth service so it can be accessed from anywhere in the app,
 * particularly in the Apollo client middleware that injects the token in every GQL request.
 */
const authServiceVar = makeVar<IAuthService | null>(null)
export const getAuthService = () => authServiceVar()
export const setAuthService = (authService: IAuthService) => authServiceVar(authService)
