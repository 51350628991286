import { analytics as segmentAnalytics } from '@northone/segment-js'
import { analytics } from '@/core/analytics/events'
import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { selectOtherOwnerDataByID } from '@/core/redux/application-redux/owners-selectors'
import { useAppSelector } from '@/core/redux/utils'

export const useOwnerProfileAnalytics = (ownerId: string) => {
  const userId = useAppSelector((state) => state.application.userId)
  const isCorpWithMultipleOwners = useAppSelector((state) => state.application.isCorpWithMultipleOwners)
  const owner = useAppSelector(selectOtherOwnerDataByID(ownerId))
  const isPrimaryOwner = ownerId === PRIMARY_OWNER_ID
  const firstName = owner.firstName
  const lastName = owner.lastName
  const jobTitle = owner.jobTitle
  const birthdate = owner.birthdate
  const ownershipPercentage = owner.ownershipPercentage
  const ownerAddress = owner.address

  const sendAnalyticsEvent = () => {
    if (isPrimaryOwner) {
      segmentAnalytics().identify(userId, { firstName, lastName })
      analytics.application.fillOwnerSSN()
    }
    analytics.application.ownerProfileSave({
      isPrimaryOwner,
      multipleOwners: isCorpWithMultipleOwners,
      firstName,
      lastName,
      title: jobTitle,
      dateOfBirth: birthdate,
      address: ownerAddress,
      ...(ownershipPercentage && { percentage: ownershipPercentage }),
    })
  }
  return { sendAnalyticsEvent }
}
