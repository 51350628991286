import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import LoadingScreen from '@/components/LoadingScreen'
import { BaseLayout } from '@/layouts/BaseLayout'
import { store } from '@/main'
import { removeQueryParams } from '@/utils/navigation'

import { useOryFeatureFlag } from '../feature-flags/use-ory-feature-flag'
import { ApplicationActionEnum } from '../redux/application-redux/application-actions'
import { Auth0AuthService, getAuth0 } from './auth0-auth-service'
import { AuthServiceContext, setAuthService } from './auth-service'
import { OryAuthService } from './ory-auth-service'

interface AuthWrapperProps {
  children?: React.ReactNode
}
export const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const navigate = useNavigate()
  const { isReady, oryFeatureFlagEnabled } = useOryFeatureFlag()

  // Use the appropriate auth service based on the Ory feature flag
  const authService = (() => {
    if (!isReady) return undefined
    if (oryFeatureFlagEnabled) return OryAuthService
    return Auth0AuthService
  })()

  useEffect(() => {
    if (!authService) return

    const checkAuthSession = async () => {
      // redirect to login on logout
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.get('logout')) {
        return authService.redirectToLogin()
      }

      if (!oryFeatureFlagEnabled && queryParams.get('code')) {
        // If using Auth0 we must handle redirect callback to get the appstate for deeplinks through auth
        try {
          //redirect path on appState is used to send user to deep link
          //https://community.auth0.com/t/redirect-after-login-page/63011
          const auth0 = await getAuth0()
          const result = await auth0.handleRedirectCallback()
          const signupPromoCode = result?.appState?.pc as string | undefined
          const stateAlreadyHasStoredPromoCode = store.getState()?.application?.signupPromoCode as string | undefined

          if (signupPromoCode && !stateAlreadyHasStoredPromoCode) {
            store.dispatch({ type: ApplicationActionEnum.SET_SIGNUP_PROMO_CODE, payload: signupPromoCode })
          }

          // clear the auth0 redirect params so they don't get used again
          removeQueryParams(['code', 'state'])
        } catch (error) {
          // intercept blocked user callbacks
          console.error('Error handling auth0 redirect callback', error)
          return await authService.redirectToLogin('unauthorized')
        }
      }

      try {
        const token = await authService.getTokenSilently()
        if (token) {
          setIsAuthenticated(true)
          return
        }
        await authService.redirectToLogin('signup')
        return
      } catch (error) {
        console.error('Error checking auth session', error)
        return await authService.redirectToLogin('signup')
      }
    }

    void checkAuthSession()
  }, [authService, isReady, navigate, oryFeatureFlagEnabled])

  if (!authService || !isAuthenticated)
    return (
      <BaseLayout>
        <LoadingScreen />
      </BaseLayout>
    )

  setAuthService(authService)
  return <AuthServiceContext.Provider value={authService}>{children}</AuthServiceContext.Provider>
}
