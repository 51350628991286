import { ReactNode, Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import LoadingScreen from './components/LoadingScreen'
import { analytics } from './core/analytics/events'
import { ApplySignupPromoCode } from './core/auth/apply-signup-promo-code'
import { AuthWrapper } from './core/auth/auth-wrapper'
import { PostAuthWrapper } from './core/auth/post-auth-wrapper'
import { LaunchDarklyWrapper } from './core/feature-flags/launchdarkly-wrapper'
import { PersistGate } from './core/persist-state/persist-gate'
import { useAppSelector } from './core/redux/utils'
import { DevHelpers } from './dev-helpers'
import { StepperScreen } from './layouts/StepperLayout'
import { isProduction } from './utils/environment'
import { useAddBusinessIdFieldToAnalytics } from './utils/hooks'

export const App = ({ disableStepper }: { disableStepper?: boolean } = {}) => {
  const isPrimaryOwner = useAppSelector((state) => state.application.isPrimaryOwner)
  const { pathname } = useLocation()

  useAddBusinessIdFieldToAnalytics()

  useEffect(() => {
    analytics.page()
  }, [pathname])

  const StepperWrapper = ({ children }: { children: ReactNode }) =>
    disableStepper ? children : <StepperScreen isUserPrimaryOwner={isPrimaryOwner ?? true}>{children}</StepperScreen>

  return (
    <AuthWrapper>
      <PostAuthWrapper>
        <ApplySignupPromoCode>
          <PersistGate>
            <LaunchDarklyWrapper>
              <StepperWrapper>
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </StepperWrapper>
            </LaunchDarklyWrapper>
            {!isProduction() && <DevHelpers />}
          </PersistGate>
        </ApplySignupPromoCode>
      </PostAuthWrapper>
    </AuthWrapper>
  )
}

export default App
