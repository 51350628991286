import {
  ActionItem,
  Body,
  BodySizeEnum,
  Box,
  EditIcon,
  ICONS,
  IconWithBackground,
  InlineAlert,
  PlusIcon,
  SectionHeader,
  SectionHeaderSizeEnum,
  SizeEnum,
  Tooltip,
  TrashIcon,
} from '@northone/ui-components'
import { useState } from 'react'
import { Pressable } from 'react-native'
import { useDispatch } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import WhyDoWeCollectThisInfo from '@/components/WhyDoWeCollectThisInfo'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { getRandomId } from '@/core/redux/application-redux/application-reducer'
import { IOwner, PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '../constants'
import { MAXIMUM_OWNERS_ALLOWED } from './constants'
import { OwnerIcon } from './owner-profiles/components/owner-icon'
import { DeleteCoOwnerConfirmationModal } from './owner-profiles/DeleteCoOwnerConfirmationModal'
import { useOwnershipReviewErrors } from './owner-profiles/utils/ownership-review-screen-errors'

export const OwnershipReviewOwners = () => {
  const dispatch = useDispatch()
  const t = useOnboardingTranslations()
  const businessName = useAppSelector((state) => state.application.businessName)
  const headingText = t('ownership.review.header', { businessName })
  const subHeadingText = t('ownership.review.subHeader')
  const navigate = useNavigate()
  const navigateBack = () => navigate(Pathname.OWNERSHIP_DETAILS_PRIMARY_OWNER)
  const [ownerIdForDeletion, setOwnerIdForDeletion] = useState<string | undefined>()

  const owners = useAppSelector((state) => state.application.owners)
  const { PRIMARY_OWNER_ID: primaryOwner, ...coOwnersMap } = owners
  const primaryOwnerFullName = primaryOwner ? getOwnerFullName(primaryOwner) : undefined

  const addOwnerActionText = t('ownership.review.addOwner.header')
  const addOwnerActionDescription = t('ownership.review.addOwner.subHeader')
  const whyDoWeCollectThisInfoFromAllOwners = t('ownership.review.whyCollectInfoFromAllOwners')
  const whyDoWeCollectContactInfo = t('ownership.review.whyCollectInfoAllOwners')
  const maxOwnerMessage = t('ownership.review.maximumOwnersMessage')

  const { ownerActionItemErrorTexts, alertProps } = useOwnershipReviewErrors()
  const continueButtonDisabled = Boolean(alertProps)

  const TooltipWhyDoWeCollect = () => (
    <Tooltip content={whyDoWeCollectContactInfo} title={whyDoWeCollectThisInfoFromAllOwners}>
      <WhyDoWeCollectThisInfo triggerString={whyDoWeCollectThisInfoFromAllOwners} />
    </Tooltip>
  )

  const onPressAddOwner = () => {
    const ownerId = getRandomId()
    dispatch(applicationActions.addNewOwner(ownerId))
    const addOwnerPath = generatePath(Pathname.OWNERSHIP_DETAILS_ADD_OWNER, { ownerId })
    navigate(addOwnerPath)
  }

  return (
    <>
      <DeleteCoOwnerConfirmationModal
        ownerId={ownerIdForDeletion}
        closeModal={() => setOwnerIdForDeletion(undefined)}
        isVisible={Boolean(ownerIdForDeletion)}
      />
      <BaseContentLayout
        headingText={headingText}
        alertComponent={alertProps ? <InlineAlert {...alertProps} /> : undefined}
        primaryButton={
          <ContinueButton
            fullWidth
            testID="ownership-review-continue-button"
            onPress={() => navigate(Pathname.OWNERSHIP_DETAILS_CONTROLLER)}
            disabled={continueButtonDisabled}
          />
        }
        secondaryButton={
          <GoBackButton
            fullWidth
            testID="ownership-review-go-back-button"
            onPress={() => navigate(Pathname.OWNERSHIP_DETAILS_PRIMARY_OWNER)}
          />
        }
      >
        <Box sx={{ gap: '$8' }}>
          {/* Due to an order swap and different font styling for this screen, this isn't part of the base content layout */}
          <Box sx={{ gap: '$4', zIndex: 3 }}>
            <SectionHeader size={SectionHeaderSizeEnum.MD} headingLevel={1} color="$charcoal5">
              {subHeadingText}
            </SectionHeader>
            <TooltipWhyDoWeCollect />
          </Box>
          <Box sx={{ gap: '$3' }}>
            {primaryOwner && primaryOwnerFullName && (
              <ActionItem
                leftItem={<OwnerIcon owner={primaryOwner} color={'$charcoal1'} />}
                rightItem={<EditIcon size={SizeEnum.MD} />}
                testID="ownership-review-primary-owner-edit"
                action={primaryOwnerFullName}
                onPress={navigateBack}
                errorText={ownerActionItemErrorTexts[PRIMARY_OWNER_ID]}
              />
            )}
            {Object.entries(coOwnersMap).map(([ownerId, coOwner]) => {
              const editOwnerPage = generatePath(Pathname.OWNERSHIP_DETAILS_EDIT_OWNER, { ownerId })
              return (
                <ActionItem
                  key={ownerId}
                  leftItem={<OwnerIcon owner={coOwner} color={'$charcoal1'} />}
                  rightItem={<AddOwnerIcons ownerId={ownerId} setOwnerIdForDeletion={setOwnerIdForDeletion} />}
                  testID="ownership-review-primary-owner-edit"
                  action={getOwnerFullName(coOwner)}
                  onPress={() => navigate(editOwnerPage)}
                  errorText={ownerActionItemErrorTexts[ownerId]}
                />
              )
            })}
          </Box>
          {Object.entries(owners).length === MAXIMUM_OWNERS_ALLOWED ? (
            <Body size={BodySizeEnum.MD} color="$charcoal5">
              {maxOwnerMessage}
            </Body>
          ) : (
            <ActionItem
              testID="ownership-review-add-owner"
              leftItem={
                <IconWithBackground icon={ICONS.AddContactIcon} size={SizeEnum.MD} backgroundColor="$receiveHoneydew" />
              }
              rightItem={<PlusIcon size={SizeEnum.MD} />}
              action={addOwnerActionText}
              description={addOwnerActionDescription}
              onPress={onPressAddOwner}
            />
          )}
        </Box>
      </BaseContentLayout>
    </>
  )
}

const AddOwnerIcons = ({
  ownerId,
  setOwnerIdForDeletion,
}: {
  ownerId: string
  setOwnerIdForDeletion: (ownerId: string) => void
}) => (
  <Box sx={{ flexDirection: 'row', gap: '$3' }}>
    <Pressable onPress={() => setOwnerIdForDeletion(ownerId)}>
      <TrashIcon size={SizeEnum.MD} color={'$error4'} />
    </Pressable>
    <EditIcon size={SizeEnum.MD} />
  </Box>
)
const getOwnerFullName = (owner: IOwner) => {
  const firstName = owner.firstName
  const lastName = owner.lastName
  // Handle incomplete profiles
  if (!firstName || !lastName) return 'Co-owner'
  return `${firstName} ${lastName}`
}
