import { To, useNavigate, NavigateOptions } from 'react-router-dom'

/**
 * Uses React Router to navigate to the given path, forwarding the current URL params
 */
export function useNavigateWithURLParams() {
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  return (to: To, options?: NavigateOptions) => {
    const urlParamsString = urlParams.toString()
    if (!urlParamsString) navigate(to, options)
    navigate(`${to}?${urlParamsString}`)
  }
}
