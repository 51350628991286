import {
  AlertTypeEnum,
  Box,
  CardText,
  CardTextSizeEnum,
  ICONS,
  InlineAlert,
  PrimaryButton,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'

import welcomeSignUpGraphicUrl from '@/assets/images/welcome-sign-up-graphic.webp'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { ExpectationScreenLayout } from '@/layouts/ExpectationScreenLayout'

interface IWelcomeFundAccountContentProps {
  hasError: boolean
  loading: boolean
  submit: () => void
}

const FundYourAccountButton = ({ onPress, loading }: { onPress: () => void; loading: boolean }) => {
  const t = useOnboardingTranslations()
  const fundYourAccountButton = t('finishUp.welcomeToNorthOne.fundYourAccount.fundYourAccountButton')

  return (
    <PrimaryButton
      testID="finish-up-welcome-continue-to-banking"
      onPress={onPress}
      loading={loading}
      disabled={loading}
      fullWidth
      icon={ICONS.ArrowIcon}
    >
      {fundYourAccountButton}
    </PrimaryButton>
  )
}

export const WelcomeFundAccountContent = ({ hasError, loading, submit }: IWelcomeFundAccountContentProps) => {
  const t = useOnboardingTranslations()
  const { isMobileSize } = useMobileScreenSize()

  const title = t('finishUp.welcomeToNorthOne.title')
  const fundAndStartUsing = t('finishUp.welcomeToNorthOne.fundYourAccount.fundAndStartUsing')
  const errorText = t('error.generic')

  const onContinueToBanking = () => {
    submit()
    return
  }

  return (
    <ExpectationScreenLayout
      maxContentSize="md"
      primaryButton={isMobileSize && <FundYourAccountButton onPress={onContinueToBanking} loading={loading} />}
    >
      <Box sx={{ gap: '$6', justifyContent: 'flex-start' }}>
        {hasError && <InlineAlert type={AlertTypeEnum.Error}>{errorText}</InlineAlert>}

        <img
          src={welcomeSignUpGraphicUrl}
          style={{ maxHeight: 245, maxWidth: '100%' }}
          alt="North One card and screenshots"
        />
        <Box sx={{ gap: '$4' }}>
          <CardText size={CardTextSizeEnum.XLG} color="$charcoal5">
            {title}
          </CardText>
          <ResponsiveOnboardingHeader largerSize="md">{fundAndStartUsing}</ResponsiveOnboardingHeader>
        </Box>
        {!isMobileSize && <FundYourAccountButton onPress={onContinueToBanking} loading={loading} />}
      </Box>
    </ExpectationScreenLayout>
  )
}
