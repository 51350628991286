import {
  AlertTypeEnum,
  Body,
  Box,
  ButtonGroup,
  Modal,
  PageHeader,
  PageHeaderSizeEnum,
  PrimaryButton,
  SecondaryButton,
  useToast,
} from '@northone/ui-components'
import { useDispatch } from 'react-redux'
import { analytics } from '@/core/analytics/events'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'

export const DeleteCoOwnerConfirmationModal = ({
  isVisible,
  ownerId,
  closeModal,
}: {
  isVisible: boolean
  ownerId?: string
  closeModal: () => void
}) => {
  const t = useOnboardingTranslations()
  const { triggerToast } = useToast()
  const copy = {
    heading: t('ownership.profile.deleteCoOwnerModal.heading'),
    subHeading: t('ownership.profile.deleteCoOwnerModal.subHeading'),
    toast: t('ownership.profile.deleteCoOwnerModal.toast'),
    delete: t('button.delete'),
    cancel: t('button.cancel'),
  }
  const dispatch = useDispatch()

  const onConfirmDeletion = () => {
    if (!ownerId) return closeModal()
    dispatch(applicationActions.deleteOwner(ownerId))
    analytics.application.coOwnerDelete()
    closeModal()
    triggerToast({ children: copy.toast, type: AlertTypeEnum.SuccessToast })
  }

  return (
    <Modal isVisible={isVisible}>
      <Box sx={{ gap: '$6' }}>
        <PageHeader headingLevel={1} size={PageHeaderSizeEnum.MD}>
          {copy.heading}
        </PageHeader>
        <Body>{copy.subHeading}</Body>
        <ButtonGroup>
          <PrimaryButton
            fullWidth
            onPress={onConfirmDeletion}
            testID="owner-profiles.delete-coowner-confirm-modal.primary-button"
          >
            {copy.delete}
          </PrimaryButton>
          <SecondaryButton
            fullWidth
            onPress={closeModal}
            testID="owner-profiles.delete-coowner-confirm-modal.secondary-button"
          >
            {copy.cancel}
          </SecondaryButton>
        </ButtonGroup>
      </Box>
    </Modal>
  )
}
