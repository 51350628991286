import {
  AlertInformationIcon,
  Body,
  BodySizeEnum,
  Box,
  Checkbox,
  NumberInput,
  SizeEnum,
  TextInput,
  Tooltip,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import Fieldset from '@/components/Fieldset'
import GoBackButton from '@/components/GoBackButton'
import WhyDoWeCollectThisInfo from '@/components/WhyDoWeCollectThisInfo'
import { analytics } from '@/core/analytics/events'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { transformBusinessTypeToGQLInput } from '@/core/redux/application-redux/selectors'
import { funnelActions } from '@/core/redux/funnel-redux/actions'
import { unpersistedActions } from '@/core/redux/unpersisted-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import { useVerifyBusinessNameIsValidLazyQuery } from '@/generated/graphql'
import useBusinessType from '@/hooks/useBusinessType'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { removeAllNonNumbers } from '@/utils'
import { getN1AnonymousId } from '@/utils/anonymous-id'
import { pushToDataLayer } from '@/utils/data-layer'
import { numberStringToOptionalNumber } from '@/utils/numberStringToOptionalNumber'
import { useBusinessDetailsValidation } from './hooks/useBusinessDetailsValidation'

export default function BusinessDetailsAboutYourBusiness() {
  const { isMobileSize } = useMobileScreenSize()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const { isSoleProp } = useBusinessType()
  const {
    businessName,
    businessType,
    fullTimeEmployees,
    ein,
    tradeName,
    hasRegisteredBusinessName,
    yearsInOperation,
    userId,
  } = useAppSelector((state) => state.application)
  const [isLoading, setIsLoading] = useState(false)
  const { fieldErrors, hasError, setShouldShowFieldErrors } = useBusinessDetailsValidation()
  const n1AnonymousId = getN1AnonymousId()

  const titleText = t('businessDetails.about.title')
  const tooltipTitleContent = t('businessDetails.about.tooltipWhyCollectBusinessName')
  const tooltipTitle = t('tooltip.whyDoWeCollectThisInformation')

  const tooltipDBAContent = t('businessDetails.about.tooltipDBAContent')
  const tooltipMoreOnDBAs = t('businessDetails.about.tooltipMoreOnDBAs')

  const solePropBusinessNameLabel = hasRegisteredBusinessName
    ? t('businessDetails.about.solepropBusinessNameLabelWithDBA')
    : t('businessDetails.about.solepropBusinessNameLabelWithoutDBA')
  const solepropHasRegisteredBusinessNameLabel = t('businessDetails.about.solepropHasRegisteredBusinessNameLabel')
  const legalBusinessNameLabel = t('businessDetails.about.legalBusinessNameLabel')
  const tradeNameOrDBALabel = t('businessDetails.about.tradeNameOrDBALabel')
  const tradeNameOrDBAHelpText = t('businessDetails.about.tradeNameOrDBAHelpText')
  const einLabel = t('businessDetails.about.einLabel')
  const numberOfFullTimeEmployeesLabel = t('businessDetails.about.numberOfFullTimeEmployeesLabel')
  const yearsInBusinessLabel = t('businessDetails.about.yearsInBusinessLabel')
  const yearsInBusinessHelperText = t('businessDetails.about.yearsInBusinessHelperText')

  const [verifyBusinessNameQuery] = useVerifyBusinessNameIsValidLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const solepropBusinessNameValue = (isSoleProp && hasRegisteredBusinessName ? tradeName : businessName) ?? ''

  const goToNextPage = () => navigate(Pathname.BUSINESS_DETAILS_DESCRIPTION_AND_INDUSTRY)

  const onBack = () => navigate(Pathname.BUSINESS_DETAILS)

  const onContinue = () => {
    setShouldShowFieldErrors(true)
    if (hasError) {
      return
    }

    setIsLoading(true)
    if (!businessType) {
      setIsLoading(false)
      return
    }
    analytics.funnel.bizName({ businessName })
    yearsInOperation && analytics.application.fillBusinessYearsInOperation({ years: yearsInOperation })
    fullTimeEmployees && analytics.application.fillNumberOfEmployees({ employees: fullTimeEmployees })
    tradeName && analytics.application.fillDBAName({ dba: tradeName })
    if (ein) {
      analytics.application.fillEIN({ n1AnonymousId })
      pushToDataLayer({ event: 'Join Form Fill EIN', n1AnonymousId, userCompanyName: businessName, userId })
    }

    if (isSoleProp) {
      analytics.funnel.bizNameRegistered({ hasRegisteredBusinessName: Boolean(hasRegisteredBusinessName) })
      // Business name isn't required for soleprops who don't specify a DBA, so we can skip the biz name verification query
      if (!hasRegisteredBusinessName && !businessName) {
        goToNextPage()
        return
      }
    }

    verifyBusinessNameQuery({
      variables: {
        name: (isSoleProp && hasRegisteredBusinessName ? tradeName : businessName) ?? '',
        businessType: transformBusinessTypeToGQLInput(businessType),
      },
    }).then(({ data }) => {
      if (data?.verifyBusinessNameIsValid) {
        analytics.funnel.bizType({ businessType })
        goToNextPage()
        return
      }

      if (isSoleProp) {
        // Confirm whether the applicant should apply as a corp
        dispatch(unpersistedActions.setConfirmBusinessTypeModalOpen(true))
      } else {
        // Confirm whether the applicant should apply as a soleprop
        dispatch(funnelActions.setFreelancerModalOpen(true))
      }
    })
  }

  const onBusinessNameChanged = (name: string) => {
    if (isSoleProp && hasRegisteredBusinessName) {
      dispatch(applicationActions.setTradeName(name))
      return
    }
    dispatch(applicationActions.setBusinessName(name))
  }
  const onYearsInOperationChanged = (years?: number) => {
    dispatch(applicationActions.setYearsInOperation(years))
  }
  const onHasRegisteredBusinessNameChanged = () => {
    const applicantHasRegisteredBusinessName = !hasRegisteredBusinessName

    dispatch(applicationActions.setHasRegisteredBusinessName(applicantHasRegisteredBusinessName))

    if (applicantHasRegisteredBusinessName) {
      dispatch(applicationActions.setTradeName(businessName))
      dispatch(applicationActions.setBusinessName(''))
    } else {
      dispatch(applicationActions.setBusinessName(tradeName ?? ''))
      dispatch(applicationActions.setTradeName(''))
    }
  }
  const onNumberOfEmployeesChanged = (count?: number) =>
    dispatch(applicationActions.setFullTimeEmployees(count?.toString() ?? ''))
  const onEINChanged = (ein?: string) => dispatch(applicationActions.setEIN(removeAllNonNumbers(ein ?? '')))
  const onTradeNameChanged = (name: string) => dispatch(applicationActions.setTradeName(name))

  const numberOfYearsInBusinessComponent = (
    <NumberInput
      testID="years-in-operation"
      labelText={yearsInBusinessLabel}
      helperText={yearsInBusinessHelperText}
      onChange={onYearsInOperationChanged}
      errorText={fieldErrors?.yearsInOperation}
      value={yearsInOperation}
      placeholder=""
    />
  )

  const TooltipSubheading = () => (
    <Tooltip content={tooltipTitleContent} title={tooltipTitle}>
      <WhyDoWeCollectThisInfo />
    </Tooltip>
  )

  const DBATooltip = () => (
    <Tooltip content={tooltipDBAContent} title={tooltipMoreOnDBAs} alignment={isMobileSize ? 'flex-start' : 'flex-end'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          width: '100%',
          gap: '$6',
          justifyContent: 'space-between',
        }}
      >
        {!isMobileSize && <Body size={BodySizeEnum.XS}>{tradeNameOrDBAHelpText}</Body>}
        <Box
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          <Body size={BodySizeEnum.XS}>{tooltipMoreOnDBAs}</Body>
          <AlertInformationIcon color={'$charcoal5'} size={SizeEnum.SM} />
        </Box>
      </Box>
    </Tooltip>
  )

  return (
    <BaseContentLayout
      headingText={titleText}
      tooltipSubheading={<TooltipSubheading />}
      titleZIndex={3}
      primaryButton={
        <ContinueButton testID="business-details-continue" onPress={onContinue} loading={isLoading} fullWidth />
      }
      secondaryButton={<GoBackButton testID="business-details-back" onPress={onBack} fullWidth />}
    >
      {!isSoleProp ? (
        <>
          <Box sx={{ gap: '$4', zIndex: 1 }}>
            <Fieldset>
              <TextInput
                testID="business-name"
                labelText={legalBusinessNameLabel}
                onChange={onBusinessNameChanged}
                value={businessName}
                errorText={fieldErrors?.businessName}
              />
            </Fieldset>
            <Box sx={{ gap: '$1' }}>
              <Fieldset>
                <TextInput
                  testID="dba"
                  labelText={tradeNameOrDBALabel}
                  helperText={isMobileSize ? tradeNameOrDBAHelpText : ''}
                  onChange={onTradeNameChanged}
                  value={tradeName ?? ''}
                />
              </Fieldset>
              <DBATooltip />
            </Box>
          </Box>

          <Fieldset>
            <TextInput
              testID="ein"
              labelText={einLabel}
              onChange={onEINChanged}
              value={ein}
              errorText={fieldErrors?.ein}
              placeholder=""
            />
          </Fieldset>

          <Fieldset>
            <NumberInput
              testID="number-of-employees"
              labelText={numberOfFullTimeEmployeesLabel}
              onChange={onNumberOfEmployeesChanged}
              errorText={fieldErrors?.fullTimeEmployees}
              value={numberStringToOptionalNumber(fullTimeEmployees)}
              placeholder=""
            />
            {numberOfYearsInBusinessComponent}
          </Fieldset>
        </>
      ) : null}

      {isSoleProp && (
        <>
          <Fieldset>
            <TextInput
              testID="business-name"
              labelText={solePropBusinessNameLabel}
              onChange={onBusinessNameChanged}
              value={solepropBusinessNameValue}
              errorText={fieldErrors?.businessName}
            />
            <Checkbox
              value="hasRegisteredBusinessName"
              labelText={solepropHasRegisteredBusinessNameLabel}
              onChange={onHasRegisteredBusinessNameChanged}
              isChecked={hasRegisteredBusinessName === true}
            />
          </Fieldset>

          {numberOfYearsInBusinessComponent}
        </>
      )}
    </BaseContentLayout>
  )
}
