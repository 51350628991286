import { analytics as segmentAnalytics } from '@northone/segment-js'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import LoadingScreen from '@/components/LoadingScreen'
import { analytics } from '@/core/analytics/events'
import { Intercom } from '@/core/intercom'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { transformApplication } from '@/features/partnerships-api/transformers'
import { useNavigateWithURLParams } from '@/hooks/useNavigateWithURLParams'
import { BaseLayout } from '@/layouts/BaseLayout'
import { Pathname } from '@/routes/constants'
import { navigateToWebBanking } from '@/routes/finish-up/utils'
import { getN1AnonymousId } from '@/utils/anonymous-id'
import { OnboardingAccountStatus, usePostAuthWrapperQuery, useRedeemReferralOtpMutation } from '../../generated/graphql'
import { getHotjarClient } from '../hotjar'
import { useAuthService } from './auth-service'
import { UserContext, UserContextData } from './user-context'

const useRedeemReferralOTP = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [metadata, setMetadata] = useState<Record<string, string>>()
  const authService = useAuthService()

  const partnerId = metadata?.partner_id
  const partnerName = metadata?.utm_campaign
  const otp = metadata?.papi_otp

  const [redeemReferralOtp] = useRedeemReferralOtpMutation({
    onCompleted: (data) => {
      if (!data.redeemReferralOTP?.application) {
        setLoading(false)
        return
      }
      const referralData = transformApplication(data.redeemReferralOTP.application)
      dispatch(applicationActions.setReferralApplicationData(referralData))
      dispatch(applicationActions.setReferralDataWasHydrated(true))
      analytics.partnershipsAPI.papiReferralDataHydrated({ partnerId, partnerName, otp })
    },
    onError: (error) => {
      analytics.partnershipsAPI.papiReferralDataError({ partnerId, partnerName, otp, errorMessage: error.message })
      setLoading(false)
    },
  })

  useEffect(() => {
    authService.getUserMetadata().then(setMetadata)
  }, [authService])

  useEffect(() => {
    const isMetadataReady = !!metadata
    if (!isMetadataReady) return
    if (!otp) return setLoading(false)

    redeemReferralOtp({
      variables: { token: otp },
    })
  }, [redeemReferralOtp, otp, metadata])

  return loading
}

export const PostAuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch()
  const navigateWithUrlParams = useNavigateWithURLParams()
  const [isLoading, setIsLoading] = useState(true)
  const authService = useAuthService()

  const hotjar = getHotjarClient()

  const referralDataLoading = useRedeemReferralOTP()
  const { registerUser, registerUserLoading } = authService.useRegisterUser()
  const n1AnonymousId = getN1AnonymousId()

  useEffect(() => {
    const syncRenewTokenOrLogout = () => {
      void authService.renewTokenOrLogout()
    }
    // verifies the session is still active whenever the window is focused on
    window.addEventListener('focus', syncRenewTokenOrLogout)
    return () => window.removeEventListener('focus', syncRenewTokenOrLogout)
  }, [authService])

  const { data, refetch } = usePostAuthWrapperQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.error(`Error in post-auth-wrapper: ${error.message}`)

      const errorsContain403 = error.graphQLErrors.some((e) => e.message.includes('403') || e.message.includes('429'))
      if (errorsContain403) {
        // retry 403 errors after renewing the token
        authService.renewTokenOrLogout().then(() => refetch())
        return
      }

      throw new Error('Error in post-auth-wrapper')
    },
    onCompleted: (data) => {
      if (!data.me) {
        navigateWithUrlParams(Pathname.WELCOME_EMAIL_VERIFY)
        setIsLoading(false)
        // Register the user and navigate straight to email verify, bypassing onboarding status wrapper (It's slow)
        registerUser()
          .then(() => authService.renewTokenOrLogout())
          .then(() => refetch())
        return
      }

      if (data.me.emailVerified === false) {
        console.log('Email not verified... going to the welcome email verify page')
        navigateWithUrlParams(Pathname.WELCOME_EMAIL_VERIFY)
      }

      const isPrimaryOwner = data.me?.isPrimaryOwner
      const isOnboardingComplete = data.me.onboardingCompleted
      const isAccountOpen = data.me?.ownerBusinesses?.[0]?.onboarding?.accountStatus === OnboardingAccountStatus.OPENED
      const businessId = data.me?.ownerBusinesses?.[0]?.id
      const { id: userId, firstName, lastName } = data.me
      const fullName = `${firstName} ${lastName}`.trim()
      const email = data.me.email

      if (!businessId) throw new Error('businessID is required')
      if (!email) throw new Error('Email not found for user but is required')

      // Route additional owners
      if (isPrimaryOwner === false) {
        if (isOnboardingComplete) {
          navigateToWebBanking()
          return
        }
      } else {
        dispatch(applicationActions.updateOwner({ updatedOwnerFields: { email }, ownerId: PRIMARY_OWNER_ID }))

        // Route primary owners with open accounts
        if (isAccountOpen) {
          navigateToWebBanking()
          return
        }
      }

      dispatch(applicationActions.setUserId(userId))
      dispatch(applicationActions.setBusinessId(businessId))

      Intercom.boot({ email, name: fullName, user_id: userId })
      hotjar.identify(userId, { businessId })
      segmentAnalytics().identify(userId, {
        email,
        businessId,
        n1AnonymousId,
      })
      Sentry.setUser({
        id: userId,
        email,
        username: fullName,
      })

      setIsLoading(false)
    },
  })

  const businessId = data?.me?.ownerBusinesses?.[0]?.id
  const email = data?.me?.email

  if (isLoading || referralDataLoading || registerUserLoading || !data?.me || !businessId || !email) {
    return (
      <BaseLayout>
        <LoadingScreen />
      </BaseLayout>
    )
  }

  const userContextData: UserContextData = {
    userId: data.me.id,
    businessId,
    email,
    emailVerified: Boolean(data.me.emailVerified),
  }

  return <UserContext.Provider value={userContextData}>{children}</UserContext.Provider>
}
