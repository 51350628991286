import { initialize as initializeLD } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { config } from '@/utils/environment'
import { FeatureFlags } from './use-feature-flag'

/**
 * Forces the ory feature flag to be enabled/disabled.
 * When undefined, the feature flag value is determined by LaunchDarkly.
 */
const oryOverride: string | undefined = import.meta.env.REACT_APP_ORY_OVERRIDE

/**
 * Fetches the ory feature flag value using an anonymous context that can be used pre-authentication.
 *
 * We cannot use the useFeatureFlag wrapper here because we do not have access to a userId before authentication.
 * WARNING: This pattern probably should not be copied without careful consideration.
 *
 * This hook can be removed once we have released Ory and no longer need this feature flag.
 */
export const useOryFeatureFlag = () => {
  const [isReady, setIsReady] = useState(false)
  const [oryFeatureFlagEnabled, setOryFeatureFlagEnabled] = useState<boolean>()

  useEffect(() => {
    const ldClient = initializeLD(
      config.launchDarklyClientSideId,
      { kind: 'user', anonymous: true },
      { streaming: false },
    )
    ldClient
      .waitUntilReady()
      .then(() => {
        const allFeatureFlags = ldClient.allFlags() as FeatureFlags
        const oryFeatureFlagValue = allFeatureFlags['ory-authentication-provider']
        setOryFeatureFlagEnabled(!!oryFeatureFlagValue)

        // use the override from the environment variable if it is set
        if (oryOverride !== undefined) {
          const overridenFlagValue = oryOverride.toLowerCase() === 'true' || oryOverride === '1'
          console.debug('Ory feature flag override is set to:', overridenFlagValue ? 'ON' : 'OFF')
          setOryFeatureFlagEnabled(overridenFlagValue)
        }

        setIsReady(true)
      })
      .catch((error) => {
        console.error('Error initializing LaunchDarkly:', error)
      })
  }, [])

  return { isReady, oryFeatureFlagEnabled }
}
