import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import LoadingScreen from '@/components/LoadingScreen'
import { useFeatureFlag } from '@/core/feature-flags/use-feature-flag'
import { useNavigateWithURLParams } from '@/hooks/useNavigateWithURLParams'
import { Pathname } from '@/routes/constants'
import { useRequiredManualReviewFiles } from '@/routes/document-upload/use-file-upload-navigation'
import { TERMS_AND_CONDITIONS_AGREEMENT_TYPE } from '@/utils/constants'
import { applicationActions } from '../../core/redux/application-redux/application-actions'
import { unpersistedActions } from '../../core/redux/unpersisted-redux/actions'
import { useAppSelector } from '../../core/redux/utils'
import { additionalOwnersRouterMachine } from '../../core/state-machines/additionalOwners'
import { onboardingRouterMachine } from '../../core/state-machines/onboarding'
import {
  OnboardingAccountStatus,
  useAcceptedAgreementsQuery,
  useOnboardingMailingAddressQuery,
  useOnboardingMeQuery,
} from '../../generated/graphql'
import { gqlLocationToAddressState, navigateToWebBanking } from '../../routes/finish-up/utils'
import { useListOwnerDetails } from '../../routes/join-team/hooks'
import { initialRouteByMachine } from './route-by-machine'

const isPrimaryOwnerFlow = (isPrimaryOwner: boolean | undefined) => isPrimaryOwner === undefined || isPrimaryOwner

export const OnboardingStatusWrapper = () => {
  const dispatch = useDispatch()
  const navigateWithURLParams = useNavigateWithURLParams()
  const userId = useAppSelector((state) => state.application.userId)
  const businessId = useAppSelector((state) => state.application.businessId)
  const applicationState = useAppSelector((state) => state.application)
  const isPrimaryOwner = useAppSelector((state) => state.application.isPrimaryOwner)
  const hasAcceptedTermsAndConditions = useAppSelector((state) => state.application.hasAcceptedTermsAndConditions)

  const [hasError, setHasError] = useState(false)
  const [hasMailingAddress, setHasMailingAddress] = useState<boolean | undefined>(undefined)
  const isManualReviewDocUploadEnabled = useFeatureFlag('joindot-custom-document-upload-ui-enabled')

  const { loading: loadingMailingAddress } = useOnboardingMailingAddressQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    skip: !applicationState.businessId,
    onError: () => {
      setHasMailingAddress(false)
    },
    onCompleted: (data) => {
      const mailingAddress = data.me?.ownerBusinesses[0]?.onboardingMailingAddress
      mailingAddress ? setHasMailingAddress(true) : setHasMailingAddress(false)
    },
  })

  const { data, loading: loadingMe } = useOnboardingMeQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: hasError,
    onError: () => {
      setHasError(true)
    },
    onCompleted: (data) => {
      const homeAddress = data.me?.ownerBusinesses[0]?.onboarding?.application?.homeAddress
      const businessAddress = data.me?.ownerBusinesses[0]?.onboarding?.application?.businessAddress
      const isPrimaryOwner = data.me?.isPrimaryOwner

      if (homeAddress) {
        dispatch(unpersistedActions.setAddress('HOME', gqlLocationToAddressState(homeAddress)))
      }
      if (businessAddress) {
        dispatch(unpersistedActions.setAddress('BUSINESS', gqlLocationToAddressState(businessAddress)))
      }
      if (isPrimaryOwner !== undefined) {
        dispatch(applicationActions.setIsPrimaryOwner(isPrimaryOwner))
      }
    },
  })
  const isEmailVerified = Boolean(data?.me?.emailVerified)

  const { loading: acceptedAgreementsLoading } = useAcceptedAgreementsQuery({
    fetchPolicy: 'network-only',
    skip: !businessId,
    variables: {
      businessId: businessId,
      userId: userId,
    },
    onCompleted: (data) => {
      const agreements = data.acceptedAgreements
      const hasAccepted =
        agreements?.some((agreement) => agreement?.agreementType === TERMS_AND_CONDITIONS_AGREEMENT_TYPE) ?? false
      dispatch(applicationActions.setTermsAndConditionsAccepted(hasAccepted))
    },
  })

  const { loadingTeamMemberApplicationAddresses, hasTeamMemberShippingAddress, hasCompletedOnboarding } =
    useListOwnerDetails()
  const requiredManualReviewFiles = useRequiredManualReviewFiles()

  useEffect(() => {
    if (
      loadingMe ||
      acceptedAgreementsLoading ||
      loadingMailingAddress ||
      loadingTeamMemberApplicationAddresses ||
      isManualReviewDocUploadEnabled === undefined
    ) {
      return
    }

    if (!isPrimaryOwnerFlow(isPrimaryOwner) && hasTeamMemberShippingAddress === undefined) {
      return
    }

    if (!isEmailVerified) {
      navigateWithURLParams(Pathname.WELCOME_EMAIL_VERIFY)
      return
    }

    const business = data?.me?.ownerBusinesses[0]
    const applicationStatus = business?.onboarding?.application?.status

    const stateMachine = isPrimaryOwnerFlow(isPrimaryOwner) ? onboardingRouterMachine : additionalOwnersRouterMachine
    const initialRoute = initialRouteByMachine({
      stateMachine,
      accountStatus: business?.onboarding?.accountStatus ?? undefined,
      applicationStatus,
      isEmailVerified,
      hasMailingAddress,
      applicationState,
      hasAcceptedTermsAndConditions,
      hasTeamMemberShippingAddress,
      navigate: navigateWithURLParams,
      navigateToWebBanking,
      isOnboardingComplete: hasCompletedOnboarding ?? false,
      isManualReviewDocUploadEnabled,
      requiredManualReviewFiles,
    })

    // New applications and additional owners won't have an initial route
    if (!initialRoute) {
      if (business?.onboarding?.accountStatus === OnboardingAccountStatus.OPENED && !isPrimaryOwner) {
        navigateWithURLParams(Pathname.JOIN_TEAM)
        return
      }
      navigateWithURLParams(Pathname.WELCOME_GETTING_STARTED)
      return
    }

    return initialRoute()
  }, [
    acceptedAgreementsLoading,
    applicationState,
    data,
    hasAcceptedTermsAndConditions,
    hasCompletedOnboarding,
    hasMailingAddress,
    hasTeamMemberShippingAddress,
    navigateWithURLParams,
    isPrimaryOwner,
    loadingMailingAddress,
    loadingMe,
    loadingTeamMemberApplicationAddresses,
    isManualReviewDocUploadEnabled,
    requiredManualReviewFiles,
    isEmailVerified,
  ])

  return <LoadingScreen />
}
